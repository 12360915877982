import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import axiosAuth from "../../../Helpers/axiosAuth";

function SurveyResultsModal({ isOpen, onClose, surveyId }) {
  const [loading, setLoading] = useState(false);
  const [detailedSurveyData, setDetailedSurveyData] = useState(null);
  const [visibleJustifications, setVisibleJustifications] = useState({});
  const [totalResponsesPerSurvey, setTotalResponsesPerSurvey] = useState({});

  const toggleJustification = (index) => {
    setVisibleJustifications({
      ...visibleJustifications,
      [index]: !visibleJustifications[index],
    });
  };
  useEffect(() => {
    getUserResponsePerSurvey();

    if (isOpen && surveyId) {
      setLoading(true);
      axiosAuth
        .post("dashboard/surveys/getSurveyResults", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          surveyId: surveyId,
        })
        .then((response) => {
          setDetailedSurveyData(response.data.survey);
          console.table(response.data.survey);
        })
        .catch((error) => {
          console.error("There was an error fetching the survey data", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isOpen, surveyId]);

  const getUserResponsePerSurvey = () => {
    axiosAuth
      .post("dashboard/surveys/getSurveyResponses", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        surveyId: surveyId,
      })
      .then((response) => {
        setTotalResponsesPerSurvey(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the survey data", error);
      });
  };
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={"md"}>
      {loading && <p>Loading...</p>}
      {detailedSurveyData && (
        <div className="container-modal-surveys">
          <div className="header-modal-surveys">
            <h2>{detailedSurveyData.title}</h2>
            <p>Expira la: {detailedSurveyData.expiry_date}</p>
          </div>
          <p>{detailedSurveyData.description}</p>
          <div className="questions-modal-surveys">
            {detailedSurveyData.questions.map((question) => {
              // Calculate the total number of votes for this question
              const totalVotes = question.choices.reduce(
                (acc, c) => acc + parseInt(c.vote_count, 10),
                0
              );

              return (
                <div key={question.question_id}>
                  <h3>{question.question_text}</h3>

                  {question.choices.map((choice) => {
                    // Calculate the percentage of votes for this choice
                    const votePercentage =
                      totalVotes === 0
                        ? 0
                        : (choice.vote_count / totalVotes) * 100;

                    return (
                      <div
                        className="choice-modal-surveys"
                        key={choice.choice_id}
                      >
                        <p>
                          {choice.choice_text} - {choice.vote_count} voturi
                        </p>

                        {/* Display the LinearProgress based on the calculated percentage */}
                        <LinearProgress
                          variant="determinate"
                          value={votePercentage}
                        />

                        <div className="surveyVotes">
                          {choice.voters.length === 0 ? (
                            <span>Nimeni</span>
                          ) : (
                            choice.voters.map((voter, index) => (
                              <span
                                key={index}
                                onClick={() =>
                                  toggleJustification(
                                    voter.fullname +
                                      "-" +
                                      question.question_id +
                                      "-" +
                                      index
                                  )
                                }
                                className={
                                  visibleJustifications[
                                    voter.fullname +
                                      "-" +
                                      question.question_id +
                                      "-" +
                                      index
                                  ]
                                    ? "activeVote"
                                    : ""
                                }
                              >
                                <span>{voter.fullname}</span>
                                {visibleJustifications[
                                  voter.fullname +
                                    "-" +
                                    question.question_id +
                                    "-" +
                                    index
                                ] && (
                                  <span>
                                    Motiv:{" "}
                                    {voter.justification || "Nu este furnizat"}
                                  </span>
                                )}
                                {index < choice.voters.length - 1}
                              </span>
                            ))
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {totalResponsesPerSurvey && (
            <div className="totalUsersResponse">
              <span>
                Total voturi per sondaj:
                {totalResponsesPerSurvey.user_count_who_answered_all_questions}
              </span>
            </div>
          )}
        </div>
      )}

      <div className="buttons-modal-surveys">
        <Button onClick={onClose}>Închide</Button>
      </div>
    </Dialog>
  );
}

export default SurveyResultsModal;
