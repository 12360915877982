import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./EditorDraftJs.css";

const RichTextEditor = ({
  showToolbar,
  onContentChange,
  resetEditor,
  defaultValue,
}) => {
  const [editorState, setEditorState] = useState();

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    onContentChange(htmlContent);
  };

  useEffect(() => {
    if (resetEditor) {
      setEditorState(EditorState.createEmpty());
    }
  }, [resetEditor]);

  useEffect(() => {
    if (defaultValue) {
      setEditorState(() => {
        const blocksFromHtml = htmlToDraft(defaultValue);
        if (blocksFromHtml) {
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
      });
    }
  }, [defaultValue]);

  return (
    <div className={showToolbar ? "toolbar-visible" : "toolbar-hidden"}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
      />
    </div>
  );
};

export default RichTextEditor;
