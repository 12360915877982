import React from "react";
import ChoiceForm from "./ChoiceForm";
import { TextField, Select, Button, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
function QuestionForm({ setQuestions, questions }) {
  const updateQuestionType = (newType, qIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].questionType = newType;
    setQuestions(updatedQuestions);
  };

  const updateJustificationRequired = (newValue, qIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].justificationRequired = newValue;
    setQuestions(updatedQuestions);
  };

  const removeQuestion = (qIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(qIndex, 1);
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        questionText: "",
        questionType: "Single choice",
        choices: [""],
        justificationRequired: false, // Default value
      },
    ]);
  };

  return (
    <div>
      <h2 className="choicesForm">Add Question</h2>
      <Button onClick={addQuestion}>Add Question</Button>

      {questions.map((question, qIndex) => (
        <div key={qIndex}>
          <div className="questionForm">
            <TextField
              sx={{ marginTop: "0.5rem" }}
              type="text"
              label="Intrebare"
              value={question.questionText}
              onChange={(e) => {
                const updatedQuestions = [...questions];
                updatedQuestions[qIndex].questionText = e.target.value;
                setQuestions(updatedQuestions);
              }}
            />

            <Select
              sx={{ marginTop: "0.5rem" }}
              value={question.questionType || "Single choice"}
              onChange={(e) => updateQuestionType(e.target.value, qIndex)}
            >
              <MenuItem value="Single choice">Single Choice</MenuItem>
              <MenuItem value="Multiple Choice">Multiple Choice</MenuItem>
            </Select>

            <Select
              sx={{ marginTop: "0.5rem" }}
              value={question.justificationRequired || false}
              onChange={(e) =>
                updateJustificationRequired(e.target.value, qIndex)
              }
            >
              <MenuItem value={true}>Justification Required</MenuItem>
              <MenuItem value={false}>Justification Not Required</MenuItem>
            </Select>

            <Button onClick={() => removeQuestion(qIndex)}>
              <DeleteIcon sx={{ color: "#f00" }} />
            </Button>
          </div>
          <ChoiceForm
            question={question}
            setQuestions={setQuestions}
            questions={questions}
            qIndex={qIndex}
          />
        </div>
      ))}
    </div>
  );
}

export default QuestionForm;
