import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import { useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContentText from "@mui/material/DialogContentText";

import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import pathAPI from "../../pathAPI";
import axiosAuth from "../../Helpers/axiosAuth";

const ListItemContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  width: "100%",
});

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
});
const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
export default function FeedbacksTeamsEditor(props) {
  const [expanded, setExpanded] = React.useState([]);
  const [teams, setTeams] = React.useState(null);
  const [editTeamId, setEditTeamId] = React.useState(null); // new state variable
  const [open, setOpen] = React.useState(false);
  const [selectedTeamName, setSelectedTeamName] = React.useState(false);
  const { emails } = props;
  const [memberEmail, setMemberEmail] = React.useState("");
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [teamId, setSelectedTeamId] = React.useState(false);
  const [successMessageUpdate, setSuccessMessageUpdate] = React.useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteMemberFromTeam, setDeleteMemberFromTeam] = React.useState(false);

  const inputRef = useRef();

  const handleExpandClick = (index) => {
    setIsLoading(true);

    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      // Close any previously expanded cards
      newExpanded.forEach((_, i) => {
        if (i !== index) {
          newExpanded[i] = false;
        }
      });
      // Open the clicked card
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };
  const handleNameChange = (event) => {
    setSelectedTeamName(event.target.value);
  };

  const getTeams = () => {
    axiosAuth
      .post("dashboard/feedback/getTeams", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        setTeams(res.data);
        setExpanded(new Array(res.data.length).fill(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getTeams();
  }, [props.teamInserted]);

  const getTeamMembers = (id) => {
    axiosAuth
      .post("dashboard/feedback/getTeamMembers", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        teamId: id,
      })
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
        const updatedTeams = [...teams];
        const teamIndex = updatedTeams.findIndex((team) => team.id === id);
        updatedTeams[teamIndex].members = res.data;
        setTeams(updatedTeams);
      })
      .catch((err) => console.error(err));
  };
  const handleDeleteMember = (userId, teamId) => {
    axiosAuth
      .post("dashboard/feedback/removeTeamMember", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        teamId: teamId,
        userId: userId,
      })
      .then(() => {
        getTeamMembers(teamId);
      })
      .catch((err) => console.error(err));
  };
  const handleEditClick = (id, teamName) => {
    setEditTeamId(id);
    setSelectedTeamName(teamName);
    setSelectedTeamId(id);
    setOpen(true);
  };

  const handleEditClose = () => {
    setEditTeamId(null);
    setSelectedTeamName(null);
    setOpen(false);
    setTeamMembers("");
    setSelectedTeamName("");
    setSuccessMessageUpdate("");
  };
  const handleMemberNameChange = (event, newValue) => {
    if (newValue) {
      setMemberEmail(newValue.email);
    } else {
      setMemberEmail("");
    }
  };

  const handleAddMember = () => {
    if (memberEmail.trim() !== "") {
      setTeamMembers([...teamMembers, { email: memberEmail }]);
      setMemberEmail("");
    }
  };
  const handleRemoveMember = (indexToRemove) => {
    setTeamMembers((prevMembers) =>
      prevMembers.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Create an array of members with name and email properties
    const memberObjects = teamMembers
      ? teamMembers.map((member) => ({
          email: member.email,
        }))
      : [];

    // Call the server-side insertTeam function
    const team = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      teamName: selectedTeamName,
      teamMembers: memberObjects,
      teamId: editTeamId,
    };
    axiosAuth
      .post("dashboard/feedback/updateTeam", team, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data === "Team updated successfully") {
          setSelectedTeamName("");
          setTeamMembers([]);
          getTeams();
          setSuccessMessageUpdate("Team Updated successfully");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteTeam = (teamId) => {
    const param = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      teamId: teamId,
    };
    axiosAuth
      .post("dashboard/feedback/deleteTeam", param)
      .then((res) => {
        if (res.data === "Team deleted successfully") {
          setSelectedTeamName("");
          setTeamMembers([]);
          getTeams();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <React.Fragment>
        {teams !== null && (
          <Grid container spacing={2}>
            {teams.map((team, index) => (
              <>
                <Grid item xs={12} sm={12} md={12} lg={4} key={team.id}>
                  <Card sx={{ marginTop: "10px", minWidth: 205 }}>
                    <CardHeader
                      sx={{ backgroundColor: "#0075ba" }}
                      avatar={
                        <Avatar
                          sx={{
                            backgroundColor: "#72bfed",
                            width: "3rem",
                            height: "3rem",
                          }}
                          aria-label="recipe"
                        >
                          {team.name.slice(0, 2)}
                        </Avatar>
                      }
                      action={
                        <IconButton
                          sx={{ color: "#fff" }}
                          aria-label="settings"
                          onClick={() => handleEditClick(team.id, team.name)}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                      title={team.name}
                      titleTypographyProps={{
                        color: "#fff",
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    />

                    <CardActions disableSpacing>
                      <Typography>Afiseaza membrii echipei</Typography>

                      <ExpandMore
                        expand={expanded[index]}
                        onClick={() => {
                          handleExpandClick(index);
                          getTeamMembers(team.id);
                        }}
                        aria-expanded={expanded[index]}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>

                    <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <CircularProgress style={{ color: "success" }} />
                        </div>
                      ) : (
                        <CardContent>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {Array.isArray(team.members) &&
                              team.members.map((member) => (
                                <div
                                  key={member.id}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography style={{ flexGrow: 1 }}>
                                    {member.email}
                                  </Typography>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      setDeleteMemberFromTeam(true, member)
                                    }
                                  >
                                    <DeleteIcon style={{ color: "red" }} />
                                  </IconButton>

                                  <Dialog
                                    open={deleteMemberFromTeam}
                                    onClose={() =>
                                      setDeleteMemberFromTeam(false)
                                    }
                                  >
                                    <DialogTitle>
                                      Eliminare membru din echipa
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>
                                        Esti sigur/a ca vrei sa scoti pe{" "}
                                        <strong>{member.email}</strong> din
                                        echipa?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={() =>
                                          setDeleteMemberFromTeam(false)
                                        }
                                      >
                                        Nu
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          setDeleteDialogOpen(false);
                                          handleDeleteMember(
                                            member.user_id,
                                            team.id
                                          );
                                          setOpen(false);
                                        }}
                                        color="secondary"
                                        autoFocus
                                      >
                                        Da
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              ))}
                          </div>
                        </CardContent>
                      )}
                    </Collapse>
                  </Card>
                </Grid>
                <></>
              </>
            ))}
          </Grid>
        )}

        <Dialog open={open} maxWidth="sm" fullWidth>
          <DialogTitle>Edit {selectedTeamName} team</DialogTitle>
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={handleEditClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <Form onSubmit={handleSubmit}>
              <FormControl>
                <InputLabel htmlFor="team-name">Nume Echipa</InputLabel>
                <Input
                  id="team-name"
                  value={selectedTeamName}
                  onChange={handleNameChange}
                  placeholder="Enter team name"
                  required
                  ref={inputRef}
                />
              </FormControl>
              <FormControl>
                <ListItemContainer>
                  <Autocomplete
                    key="autocomplete"
                    sx={{ width: 300 }}
                    size="small"
                    id="member-name"
                    options={emails}
                    getOptionLabel={(option) => option.email}
                    onChange={handleMemberNameChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nume Membru"
                        variant="outlined"
                      />
                    )}
                  />
                  <Button
                    key="add-button"
                    variant="contained"
                    color="primary"
                    onClick={handleAddMember}
                    disabled={memberEmail.trim() === ""}
                  >
                    Add
                  </Button>
                </ListItemContainer>
              </FormControl>
              {teamMembers.length > 0 && (
                <List>
                  {teamMembers.map((member, index) => (
                    <ListItem key={index}>
                      <ListItemText secondary={member.email} />
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleRemoveMember(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              )}

              <Button type="submit" variant="contained" color="primary">
                Actualizeaza Echipa
              </Button>
              <Button
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteDialogOpen(true)}
              >
                Sterge Echipa
              </Button>
            </Form>
            {successMessageUpdate && (
              <Typography sx={{ color: "green" }} variant="body1">
                {successMessageUpdate}
              </Typography>
            )}
          </DialogContent>
        </Dialog>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Sterge Echipa</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Esti sigur/a ca vrei sa stergi echipa ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>Nu</Button>
            <Button
              onClick={() => {
                setDeleteDialogOpen(false);
                deleteTeam(teamId);
                setOpen(false);
              }}
              color="secondary"
              autoFocus
            >
              Da
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
}
