import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  TableFooter,
  Divider,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";

import "./Ticketing.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Loader from "../../Helpers/Loader";
import GlobalAlert from "../../Helpers/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosAuth from "../../Helpers/axiosAuth";
const OpenTicket = () => {
  const [notif_Type, setNotif_Type] = useState([]);
  const [source, setSource] = useState([]);
  const [status, setStatus] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [teams, setTeams] = useState([]);
  const [urgency, setUrgency] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [subjectSelected, setSubjectSelected] = useState(null);
  const [loader, setLoader] = useState(false);
  const [alertNok, setAlertNok] = useState(false);
  const [alertOk, setAlertOk] = useState(false);

  const initialFormData = {
    sourceInsert: null,
    slaInsert: null,
    statusInsert: null,
    subjectsInsert: null,
    teamsInsert: null,
    urgencyInsert: null,
    notif_TypeInsert: null,
    sourceUpdate: null,
    slaUpdate: null,
    statusUpdate: null,
    subjectsUpdate: null,
    teamsUpdate: null,
    urgencyUpdate: null,
    notif_TypeUpdate: null,
    userInsert: null,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getAllUsers = () => {
    setLoader(true);
    axiosAuth
      .post("/dashboard/ticketing/getUsersFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setAllUsers(response.data);
        setLoader(false);
      })
      .catch(function () {
        console.log("Request failed.");
        setLoader(false);
      });
  };

  const getUsersFromTeam = (id) => {
    setLoader(true);
    axiosAuth
      .post("/dashboard/ticketing/getUsers/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id: id,
      })
      .then(function (response) {
        setLoader(false);
        setUsers(response.data);
      })
      .catch(function () {
        setLoader(false);
        console.log("Request failed.");
      });
  };

  const dialogContent = (id, name, tableName) => {
    if (id && name) {
      setSelectedOption({ id: id, name: name, tableName: tableName });
    } else {
      setSelectedOption(null);
    }
    setFormData(initialFormData);
    if (tableName === "teams") {
      getUsersFromTeam(id);
      getSubjects(id);
    }
  };

  const insertNewValue = (tableName) => {
    if (formData[tableName + "Insert"]) {
      setLoader(true);
      axiosAuth
        .post("/dashboard/ticketing/basicInsert/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          tableName: tableName.toUpperCase(),
          valueName: formData[tableName + "Insert"],
        })
        .then(function () {
          getControlDetails();
          setFormData({
            ...formData,
            sourceInsert: null,
          });
          setLoader(false);
          setAlertOk(true);
        })
        .catch(function () {
          setLoader(false);
          setAlertNok(true);
        });
    }
  };

  const updateValue = (tableName) => {
    if (formData[tableName + "Update"]) {
      setLoader(true);
      setSelectedOption(null);
      axiosAuth
        .post("/dashboard/ticketing/basicUpdate/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          tableName: tableName.toUpperCase(),
          valueId: selectedOption.id,
          valueName: formData[tableName + "Update"],
        })
        .then(function () {
          getControlDetails();
          setLoader(false);
          setAlertOk(true);
        })
        .catch(function () {
          setLoader(false);
          setAlertNok(true);
        });
    }
  };

  const deleteValue = () => {
    setSelectedOption(null);
    setConfirmDialog(null);
    setLoader(true);
    axiosAuth
      .post("/dashboard/ticketing/basicDelete/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableName: selectedOption.tableName.toUpperCase(),
        valueId: selectedOption.id,
      })
      .then(function () {
        getControlDetails();
        setLoader(false);
        setAlertOk(true);
      })
      .catch(function () {
        setLoader(false);
        setAlertNok(true);
      });
  };

  const getControlDetails = () => {
    setLoader(true);
    axiosAuth
      .post("/dashboard/ticketing/getAllFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "NOTIF_TYPE,SLA,SOURCE,STATUS,TEAMS,URGENCY",
      })
      .then(function (response) {
        setNotif_Type(response.data.NOTIF_TYPE);
        setSource(response.data.SOURCE);
        setStatus(response.data.STATUS);
        setTeams(response.data.TEAMS);
        setUrgency(response.data.URGENCY);
        setLoader(false);
      })
      .catch(function () {
        setLoader(false);
        console.log("Request failed.");
      });
  };

  const deleteUser = (idUser, idTeam) => {
    setLoader(true);
    axiosAuth
      .post("/dashboard/ticketing/deleteUserFromTeam/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        idUser: idUser,
        idTeam: idTeam,
      })
      .then(function () {
        getUsersFromTeam(idTeam);
        setLoader(false);
        setAlertOk(true);
      })
      .catch(function () {
        setLoader(false);
        setAlertNok(true);
      });
  };

  const addUser = (idUser, idTeam) => {
    setLoader(true);
    axiosAuth
      .post("/dashboard/ticketing/addUserIntoTeam/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        idUser: idUser,
        idTeam: idTeam,
      })
      .then(function () {
        getUsersFromTeam(idTeam);
        setUserSelected(null);
        setLoader(false);
        setAlertOk(true);
      })
      .catch(function () {
        setLoader(false);
        setAlertNok(true);
      });
  };

  const addSubject = (idTeam) => {
    if (subjectSelected && subjectSelected !== "") {
      setLoader(true);
      axiosAuth
        .post("/dashboard/ticketing/addSubject/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          subject: subjectSelected,
          idTeam: idTeam,
        })
        .then(function () {
          getSubjects(idTeam);
          setSubjectSelected("");
          setLoader(false);
          setAlertOk(true);
        })
        .catch(function () {
          setLoader(false);
          setAlertNok(true);
        });
    }
  };

  const getSubjects = (id) => {
    setLoader(true);
    let params = new FormData();
    params.append("team", id);
    axiosAuth
      .post("/dashboard/ticketing/getSubjects/", params)
      .then(function (response) {
        setSubjects(response.data);
        setLoader(false);
        // setFormData(initialFormData);
        // alert('Tichetul ' + response.data + ' a fost deschis cu succes!');
      })
      .catch(function () {
        setLoader(false);
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    getControlDetails();
    getAllUsers();
  }, []);

  const updateSubjectVisibility = (subjectId, visibility, teamId) => {
    setLoader(true);
    axiosAuth
      .post("/dashboard/ticketing/updateSubjectVisibility/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        idSubject: subjectId,
        visibility: visibility,
      })
      .then(function () {
        getSubjects(teamId);
        setSubjectSelected("");
        setLoader(false);
        setAlertOk(true);
      })
      .catch(function () {
        setLoader(false);
        setAlertNok(true);
      });
  };
  return (
    <>
      <div className="boxContent gridIsh">
        <TableContainer component={Paper}>
          <Table className="optionControlTable">
            <TableHead>
              <TableRow>
                <TableCell>Sursă</TableCell>
                <TableCell>Editează</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {source !== null &&
                source.map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell>{row.NAME}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          dialogContent(row.ID, row.NAME, "source")
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <TextField
                    size="small"
                    name="sourceInsert"
                    label="Valoare nouă"
                    value={formData.sourceInsert ?? ""}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => insertNewValue("source")}
                    variant="contained"
                  >
                    Adaugă
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <TableContainer component={Paper}>
          <Table className="optionControlTable">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Editează</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status !== null &&
                status.map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell>{row.NAME}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          dialogContent(row.ID, row.NAME, "status")
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <TextField
                    size="small"
                    name="statusInsert"
                    label="Valoare nouă"
                    value={formData.statusInsert ?? ""}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => insertNewValue("status")}
                    variant="contained"
                  >
                    Adaugă
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {/* <TableContainer component={Paper}>
                        <Table className="optionControlTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Subiecte</TableCell>
                                    <TableCell>Editează</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subjects !== null && (
                                    subjects.map((row) => (
                                        <TableRow key={row.ID}>
                                            <TableCell>{row.NAME}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => dialogContent(row.ID, row.NAME, "subjects")}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            name="subjectsInsert"
                                            label="Valoare nouă"
                                            value={formData.subjectsInsert ?? ''}
                                            onChange={handleInputChange}
                                        /></TableCell>
                                    <TableCell>
                                        <Button onClick={() => insertNewValue("subjects")} variant="contained">Adaugă</Button>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer> */}

        <TableContainer component={Paper}>
          <Table className="optionControlTable">
            <TableHead>
              <TableRow>
                <TableCell>Urgență</TableCell>
                <TableCell>Editează</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {urgency !== null &&
                urgency.map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell>{row.NAME}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          dialogContent(row.ID, row.NAME, "urgency")
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <TextField
                    size="small"
                    name="urgencyInsert"
                    label="Valoare nouă"
                    value={formData.urgencyInsert ?? ""}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => insertNewValue("urgency")}
                    variant="contained"
                  >
                    Adaugă
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table className="optionControlTable">
            <TableHead>
              <TableRow>
                <TableCell>Tip Notificare</TableCell>
                <TableCell>Editează</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notif_Type !== null &&
                notif_Type.map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell>{row.NAME}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          dialogContent(row.ID, row.NAME, "notif_Type")
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <TextField
                    size="small"
                    name="notif_TypeInsert"
                    label="Valoare nouă"
                    value={formData.notif_TypeInsert ?? ""}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => insertNewValue("notif_Type")}
                    variant="contained"
                  >
                    Adaugă
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table className="optionControlTable">
            <TableHead>
              <TableRow>
                <TableCell>Departamente</TableCell>
                <TableCell>Editează</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams !== null &&
                teams.map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell>{row.NAME}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => dialogContent(row.ID, row.NAME, "teams")}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <TextField
                    size="small"
                    name="teamsInsert"
                    label="Valoare nouă"
                    value={formData.teamsInsert ?? ""}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => insertNewValue("teams")}
                    variant="contained"
                  >
                    Adaugă
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        className="ticket-dialog"
        open={selectedOption !== null}
        onClose={() => dialogContent()}
      >
        {selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">{selectedOption.name}</p>
              <p className="ticket-number">{selectedOption.id}</p>
            </div>
            <div className="ticket-details">
              <TextField
                size="small"
                name={selectedOption.tableName + "Update"}
                className="input-dialog-control"
                label="Valoare nouă"
                value={
                  formData[selectedOption.tableName + "Update"] ??
                  selectedOption.name
                }
                onChange={handleInputChange}
              />
            </div>
            <div className="ticket-dialog-control">
              <Button
                onClick={() => updateValue(selectedOption.tableName)}
                variant="contained"
              >
                Salvează
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setConfirmDialog(selectedOption.name)}
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setSelectedOption(null)}
              >
                Anulează
              </Button>
            </div>
            <Divider />
            {selectedOption.tableName === "teams" ? (
              <div className="gridIsh">
                <div className="usersDetails">
                  <Typography>Utilizatori</Typography>
                  <TableContainer component={Paper}>
                    <Table className="optionControlTable">
                      <TableHead>
                        <TableRow>
                          <TableCell>Membru</TableCell>
                          <TableCell>Sterge</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users !== null &&
                          users.map((row) => (
                            <TableRow key={row.ID}>
                              <TableCell>{row.EMAIL}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() =>
                                    deleteUser(row.ID, selectedOption.id)
                                  }
                                >
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell>
                            <Autocomplete
                              size="small"
                              name="userInsert"
                              id="userInsert"
                              options={allUsers}
                              value={userSelected}
                              getOptionLabel={(option) => option.email}
                              renderInput={(params) => (
                                <TextField {...params} label="Valoare noua" />
                              )}
                              onChange={(event, newValue) =>
                                setUserSelected(newValue)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() =>
                                addUser(userSelected.id, selectedOption.id)
                              }
                              variant="contained"
                            >
                              Adaugă
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
                <div className="usersDetails">
                  <Typography>Subiecte</Typography>
                  <TableContainer component={Paper}>
                    <Table className="optionControlTable">
                      <TableHead>
                        <TableRow>
                          <TableCell>Subiect</TableCell>
                          <TableCell>Editeaza</TableCell>
                          <TableCell>Vizibilitate</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subjects !== null &&
                          subjects.map((row) => (
                            <TableRow key={row.ID}>
                              <TableCell>{row.NAME}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() =>
                                    dialogContent(row.ID, row.NAME, "subjects")
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>
                                {row?.visibility === "1" ? (
                                  <IconButton
                                    onClick={() =>
                                      updateSubjectVisibility(
                                        row.ID,
                                        "0",
                                        row.TEAM_ID
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      updateSubjectVisibility(
                                        row.ID,
                                        "1",
                                        row.TEAM_ID
                                      )
                                    }
                                  >
                                    <VisibilityOffIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell>
                            <TextField
                              size="small"
                              placeholder="Valoare noua"
                              onChange={(e) =>
                                setSubjectSelected(e.target.value)
                              }
                              value={subjectSelected}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => addSubject(selectedOption.id)}
                              variant="contained"
                            >
                              Adaugă
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={confirmDialog !== null}
        onClose={() => setConfirmDialog(null)}
      >
        {confirmDialog !== null && selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere valoare</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi valoarea: {confirmDialog}</p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteValue()}
              >
                Șterge
              </Button>
              <Button variant="outlined" onClick={() => setConfirmDialog(null)}>
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      <Loader loading={loader} />
      <GlobalAlert
        openAlert={alertNok}
        setOpenAlert={setAlertNok}
        color={"error"}
        message={"Actiunea nu a reusit!"}
      />
      <GlobalAlert
        openAlert={alertOk}
        setOpenAlert={setAlertOk}
        color={"success"}
        message={"Actiune reusita!"}
      />
    </>
  );
};

export default OpenTicket;
