import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function GlobalAlert({
  openAlert,
  setOpenAlert,
  color,
  message,
}) {
  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <Snackbar
      open={openAlert}
      autoHideDuration={6000}
      action
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={color} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
