import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Button } from "@mui/material";
import dayjs from "dayjs";
import ExportToCsv from "../Penalizari/ExportToCsv";
import axiosAuth from "../../Helpers/axiosAuth";

const SalariiAgenti = () => {
  const [dateSalarii, setDateSalarii] = useState([]);
  const [datePicked, setDatePicked] = useState(dayjs().format("YYYY-MM-DD"));
  const [parametrii, setParametrii] = useState({
    comision: null,
    taxe: null,
    cursValutar: null,
    topBonus: null,
  });

  const getSalarii = () => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");
    axiosAuth
      .post("/dashboard/salarizare/getSalarii/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
        comision: parametrii.comision,
        taxe: parametrii.taxe,
        cursValutar: parametrii.cursValutar,
        topBonus: parametrii.topBonus,
      })
      .then(function (response) {
        setDateSalarii(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const viewSalarii = () => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");
    axiosAuth
      .post("/dashboard/salarizare/viewSalarii/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
      })
      .then(function (response) {
        setDateSalarii(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const getParametrii = () => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");
    axiosAuth
      .post("/dashboard/salarizare/getParametrii/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
      })
      .then(function (response) {
        const res = response.data[0];
        setParametrii({
          comision: res.comision,
          taxe: res.taxe,
          cursValutar: res.cursValutar,
          topBonus: res.topBonus,
        });
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setParametrii({
      ...parametrii,
      [name]: value,
    });
  };

  useEffect(() => {
    getParametrii();
  }, [dateSalarii, datePicked]);

  useEffect(() => {
    viewSalarii();
  }, [datePicked]);

  return (
    <>
      <div className="containerInputs">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Luna / anul"
            views={["year", "month"]}
            value={datePicked}
            onChange={(newValue) => {
              setDatePicked(newValue);
            }}
            format="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField size="small" {...params} required />
            )}
          />
        </LocalizationProvider>

        <TextField
          size="small"
          name="comision"
          label="Comision"
          value={parametrii.comision ?? ""}
          onChange={handleInputChange}
          required
        />
        <TextField
          size="small"
          name="taxe"
          label="Taxe"
          value={parametrii.taxe ?? ""}
          onChange={handleInputChange}
          required
        />
        <TextField
          size="small"
          name="cursValutar"
          label="Curs Valutar"
          value={parametrii.cursValutar ?? ""}
          onChange={handleInputChange}
          required
        />
        <TextField
          size="small"
          name="topBonus"
          label="Top Bonus"
          value={parametrii.topBonus ?? ""}
          onChange={handleInputChange}
          required
        />
        <Button variant="contained" onClick={getSalarii}>
          Generează
        </Button>
        <ExportToCsv
          data={dateSalarii.map((row) => ({
            Nume: row.fullname,
            "Profit individual": row.profitIndividual,
            ProfitColaborare: row.profitColaborare,
            "Total general": row.totalGeneral,
            Prag: row.prag,
            Baza: row.baza,
            "Comision net": row.comisionNet,
            "Top bonus": row.topBonus,
            "Total net": row.totalNet,
          }))}
          headers={[
            "Nume",
            "Profit individual",
            "ProfitColaborare",
            "Total general",
            "Prag",
            "Baza",
            "Comision net",
            "Top bonus",
            "Total net",
          ]}
          filename={"SalariiAgenti" + datePicked}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="Praguri salarizare">
          <TableHead>
            <TableRow>
              <TableCell>Nume</TableCell>
              <TableCell>Profit individual</TableCell>
              <TableCell>ProfitColaborare</TableCell>
              <TableCell>Total general</TableCell>
              <TableCell>Prag</TableCell>
              <TableCell>Baza</TableCell>
              <TableCell>Comision net</TableCell>
              <TableCell>Top bonus</TableCell>
              <TableCell>Total net</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dateSalarii.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.fullname}</TableCell>
                <TableCell>{row.profitIndividual}</TableCell>
                <TableCell>{row.profitColaborare}</TableCell>
                <TableCell>{row.totalGeneral}</TableCell>
                <TableCell>{row.prag}</TableCell>
                <TableCell>{row.baza}</TableCell>
                <TableCell>{row.comisionNet}</TableCell>
                <TableCell>{row.topBonus}</TableCell>
                <TableCell>{row.totalNet}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="infoTable">
        <span>Rezultate: {dateSalarii.length}</span>
      </div>
    </>
  );
};
export default SalariiAgenti;
