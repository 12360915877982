import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from "react";
import axiosAuth from "../../../Helpers/axiosAuth";

const COLUMN_MAPPING = {
    type: "Tip",
    marca: "Marca",
    model: "Model",
    serie: "Serie",
    data_achizitie: "Data Achizitie",
    pret_achizitie_net: "Pret Achizitie Net",
    furnizor: "Furnizor",
    setup: 'Setup',
};

const typeMapping = {
    1: "Monitor",
    2: "Unitate",
    3: "Laptop",
    4: "Tastatura",
    5: "Mouse",
    6: "Telefon",
};

const defectColumnMapping = {
    type: "Tip",
    marca: "Marca",
    model: "Model",
    serie: "Serie",
    pret_achizitie_net: "Pret Achizitie Net",
    furnizor: "Furnizor",
    defectiune: "Defectiune",
};

const formatDate = (date) => {
    return date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '';
};

export default function ListaEchipamente() {
    const [echipamente, setEchipamente] = useState([]);
    const [echipamenteDefecte, setEchipamenteDefecte] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDefect, setOpenDefect] = useState(false);
    const [currentEchipament, setCurrentEchipament] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [defectValues, setDefectValues] = useState({});

    const fetchEchipamente = useCallback(() => {
        axiosAuth
            .post("dashboard/evidenta_echipamente/getAllEchipamenteWithSetup", {
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
            })
            .then((response) => {
                if (response.data.success === "true") {
                    const echipamenteData = response.data.echipamente.map((item) => ({
                        id: item.id,
                        ...item,
                        data_achizitie: formatDate(item.data_achizitie),  // Format the date here
                    }));
                    setEchipamente(echipamenteData);
                } else {
                    console.error("Failed to fetch echipamente.");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching echipamente:", error);
                setLoading(false);
            });
    }, []);

    const fetchEchipamenteDefecte = useCallback(() => {
        axiosAuth
            .post("dashboard/evidenta_echipamente/getAllEchipamenteDefecte", {
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
            })
            .then((response) => {
                if (response.data.success === "true") {
                    const echipamenteDefecteData = response.data.echipamente.map((item) => ({
                        id: item.id,
                        ...item,
                        data_achizitie: formatDate(item.data_achizitie),  // Format the date here
                    }));
                    setEchipamenteDefecte(echipamenteDefecteData);
                } else {
                    console.error("Failed to fetch echipamente defecte.");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching echipamente defecte:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchEchipamente();
        fetchEchipamenteDefecte();
    }, [fetchEchipamente, fetchEchipamenteDefecte]);

    const handleOpenEdit = (echipament) => {
        setCurrentEchipament(echipament);
        setEditValues({
            ...echipament,
            data_achizitie: echipament.data_achizitie,
        });
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleEditChange = (e) => {
        setEditValues({
            ...editValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleDateChange = (date) => {
        setEditValues({
            ...editValues,
            data_achizitie: formatDate(date),
        });
    };

    const handleSave = () => {
        const { id, ...updatedValues } = editValues;
        axiosAuth
            .post(`dashboard/evidenta_echipamente/updateEchipament`, {
                id,
                values: updatedValues,
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
            })
            .then((response) => {
                if (response.data.success === "true") {
                    setEchipamente((prevEchipamente) =>
                        prevEchipamente.map((echipament) =>
                            echipament.id === id ? { ...echipament, ...updatedValues } : echipament
                        )
                    );
                    handleCloseEdit();
                } else {
                    console.error("Failed to update echipament.");
                }
            })
            .catch((error) => {
                console.error("Error updating echipament:", error);
            });
    };

    const handleOpenDefect = () => {
        setDefectValues({
            ...editValues,
            defectiune: '',
        });
        setOpenEdit(false);
        setOpenDefect(true);
    };

    const handleCloseDefect = () => {
        setOpenDefect(false);
    };

    const handleDefectChange = (e) => {
        setDefectValues({
            ...defectValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleSaveDefect = () => {
        const { id, ...defectDetails } = defectValues;
        axiosAuth
            .post(`dashboard/evidenta_echipamente/addEchipamentDefect`, {
                id,
                values: defectDetails,
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
            })
            .then((response) => {
                if (response.data.success === "true") {
                    setEchipamente((prevEchipamente) =>
                        prevEchipamente.filter((echipament) => echipament.id !== id)
                    );
                    setEchipamenteDefecte((prevEchipamenteDefecte) => [
                        ...prevEchipamenteDefecte,
                        { id, ...defectDetails },
                    ]);
                    handleCloseDefect();
                } else {
                    console.error("Failed to add echipament defect.");
                }
            })
            .catch((error) => {
                console.error("Error adding echipament defect:", error);
            });
    };

    const columns = [
        ...Object.keys(COLUMN_MAPPING).map((field) => ({
            field: field,
            headerName: COLUMN_MAPPING[field],
            flex: 1,
            valueGetter: (params) => {
                if (field === "type") {
                    return typeMapping[params.row[field]] || "Unknown";
                }
                if (field === "data_achizitie") {
                    return formatDate(params.row[field]);
                }
                return params.row[field];
            },
        })),
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    <Button onClick={() => handleOpenEdit(params.row)}>Edit</Button>
                </>
            ),
        },
    ];

    const defectColumns = [
        ...Object.keys(defectColumnMapping).map((field) => ({
            field: field,
            headerName: defectColumnMapping[field],
            flex: 1,
            valueGetter: (params) => {
                if (field === "type") {
                    return typeMapping[params.row[field]] || "Unknown";
                }
                if (field === "data_achizitie") {
                    return formatDate(params.row[field]);
                }
                return params.row[field];
            },
        })),
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ width: "100%" }}>
                <div style={{ marginBottom: '20px' }}>
                    {echipamente.length > 0 ? (
                        <DataGrid
                            rows={echipamente}
                            columns={columns}
                            loading={loading}
                            autoHeight
                        />
                    ) : (
                        <p>No equipment data available.</p>
                    )}
                </div>
                <h3 style={{ marginTop: '20px' }}>Echipamente Defecte</h3>
                <div style={{ marginTop: '10px' }}>
                    {echipamenteDefecte.length > 0 ? (
                        <DataGrid
                            rows={echipamenteDefecte}
                            columns={defectColumns}
                            loading={loading}
                            autoHeight
                        />
                    ) : (
                        <p>No defective equipment data available.</p>
                    )}
                </div>
                <Dialog open={openEdit} onClose={handleCloseEdit}>
                    <DialogTitle>Edit Echipament</DialogTitle>
                    <DialogContent>
                        {currentEchipament &&
                            [...Object.keys(COLUMN_MAPPING), 'extra_details'].map((field) => (
                                field === "type" ? (
                                    <FormControl fullWidth margin="dense" key={field}>
                                        <InputLabel>{COLUMN_MAPPING[field]}</InputLabel>
                                        <Select
                                            name={field}
                                            value={editValues[field] || ""}
                                            onChange={handleEditChange}
                                        >
                                            {Object.entries(typeMapping).map(([key, value]) => (
                                                <MenuItem key={key} value={key}>{value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : field === "data_achizitie" ? (
                                    <TextField
                                        key={field}
                                        label={COLUMN_MAPPING[field]}
                                        type="datetime-local"
                                        fullWidth
                                        name={field}
                                        value={editValues[field] || ""}
                                        onChange={handleEditChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="dense"
                                    />
                                ) : (
                                    <TextField
                                        key={field}
                                        margin="dense"
                                        label={COLUMN_MAPPING[field] || "Detalii Extra"}
                                        type="text"
                                        fullWidth
                                        name={field}
                                        value={editValues[field] || ""}
                                        onChange={handleEditChange}
                                    />
                                )
                            ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEdit}>Cancel</Button>
                        <Button onClick={handleSave}>Save</Button>
                        <Button onClick={handleOpenDefect}>Adaugă la defecte</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openDefect} onClose={handleCloseDefect}>
                    <DialogTitle>Adaugă la defecte</DialogTitle>
                    <DialogContent>
                        {currentEchipament &&
                            Object.keys(COLUMN_MAPPING).map((field) => (
                                field === "type" ? (
                                    <FormControl fullWidth margin="dense" key={field}>
                                        <InputLabel>{COLUMN_MAPPING[field]}</InputLabel>
                                        <Select
                                            name={field}
                                            value={defectValues[field] || ""}
                                            onChange={handleDefectChange}
                                        >
                                            {Object.entries(typeMapping).map(([key, value]) => (
                                                <MenuItem key={key} value={key}>{value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <TextField
                                        key={field}
                                        margin="dense"
                                        label={COLUMN_MAPPING[field]}
                                        type="text"
                                        fullWidth
                                        name={field}
                                        value={defectValues[field] || ""}
                                        onChange={handleDefectChange}
                                        disabled={field !== "defectiune"}
                                    />
                                )
                            ))}
                        <TextField
                            margin="dense"
                            label="Defectiune"
                            type="text"
                            fullWidth
                            name="defectiune"
                            value={defectValues.defectiune || ""}
                            onChange={handleDefectChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDefect}>Cancel</Button>
                        <Button onClick={handleSaveDefect}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </LocalizationProvider>
    );
}