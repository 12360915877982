import React, { useState, useEffect } from "react";
import { TextField, Button, Divider, Dialog } from "@mui/material";
import GlobalAlert from "../../../Helpers/Alert";
import QuestionForm from "./QuestionForm";
import { Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailDND from "./EmailDND";
import axiosAuth from "../../../Helpers/axiosAuth";
const initialFormData = {
  id: "",
  title: "",
  description: "",
  questions: [
    {
      question_id: "",
      question_text: "",
      question_type: "Single choice",
      choices: [],
      user_response: true,
      chosenResponse: [],
    },
  ],
};

const OptiuniSurvey = (props) => {
  const { data, onCloseDialog, getAllData } = props;

  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState(initialFormData);

  const [confirmDialog, setConfirmDialog] = useState(null);
  const [confirmDeleteQuestion, setConfirmDeleteQuestion] = useState(null);
  const [emailDialog, setEmailDialog] = useState(null);

  const [alertNok, setAlertNok] = useState(false);
  const [errorMessage, setErrorMessages] = useState("");
  const [succesMessage, setSuccesMessage] = useState("");

  const getSurveyDetails = (surveyNumber) => {
    axiosAuth
      .post("dashboard/surveys/getSurveyDetails", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        surveyId: surveyNumber,
      })
      .then((res) => {
        setSelectedOption(res.data[0]);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (data?.survey_number !== null) {
      getSurveyDetails(data.survey_number);
    }
  }, [data]);

  useEffect(() => {
    if (selectedOption !== null) {
      setFormData({
        id: selectedOption.survey_id || "",
        title: selectedOption.title || "",
        description: selectedOption.description || "",
        questions: selectedOption.questions || [],
      });
    }
  }, [selectedOption]);

  const handleInputChange = (event, name) => {
    const { value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleQuestionChange = (event, questionIndex, name) => {
    const { value } = event.target;

    const updatedQuestions = [...formData.questions];
    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      [name]: value,
    };

    setFormData({
      ...formData,
      questions: updatedQuestions,
    });
  };

  const handleChoiceChange = (event, questionIndex, choiceIndex, name) => {
    const { value } = event.target;

    const updatedQuestions = [...formData.questions];
    updatedQuestions[questionIndex].choices[choiceIndex] = {
      ...updatedQuestions[questionIndex].choices[choiceIndex],
      [name]: value,
    };

    setFormData({
      ...formData,
      questions: updatedQuestions,
    });
  };

  const dialogContent = () => {
    setSelectedOption(null);
    onCloseDialog(null);
    setQuestions([]);
  };

  const handleSalveaza = () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    // Update survey details
    const insertData = {
      token,
      email,
      surveyId: formData.id,
      questions: questions, // Use the updated questions state
    };

    axiosAuth
      .post("dashboard/surveys/insertQuestionForSurvey", insertData)
      .then(() => {
        setSuccesMessage("Survey questions updated successfully");
        setAlertNok(true);
        getSurveyDetails(data?.survey_number);
        setQuestions([]);
      })
      .catch((error) => {
        setErrorMessages("Failed to update survey questions");
        setAlertNok(true);
        console.error(error);
      });
    axiosAuth
      .post("dashboard/surveys/updateSurvey", {
        token,
        email,
        surveyId: selectedOption.survey_id,
        title: formData.title,
        description: formData.description,
      })
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });

    // Update survey questions
    const updateData = {
      token,
      email,
      surveyId: formData.id,
      questions: formData.questions,
    };

    axiosAuth
      .post("dashboard/surveys/updateSurveyQuestions", updateData)
      .then(() => {
        setSuccesMessage("Survey questions updated successfully");
        setAlertNok(true);
      })
      .catch((error) => {
        setErrorMessages("Failed to update survey questions");
        setAlertNok(true);
        console.error(error);
      });

    // Update survey choices

    const updateDataChoice = {
      token,
      email,
      surveyId: formData.id,
      questions: formData.questions.map((question) => ({
        question_id: question.question_id,
        choices: question.choices,
      })),
    };

    axiosAuth
      .post("dashboard/surveys/updateSurveyChoices", updateDataChoice)
      .then(() => {
        setSuccesMessage("Survey choices updated successfully");
        setAlertNok(true);
      })
      .catch((error) => {
        setErrorMessages("Failed to update survey choices");
        setAlertNok(true);
        console.error(error);
      });
  };

  const deleteValue = () => {
    setSelectedOption(null);
    setConfirmDialog(null);

    axiosAuth
      .post("dashboard/surveys/deleteSurvey", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        valueId: data.id,
      })
      .then(function () {
        getAllData();
        setAlertNok(true);
      })
      .catch(function () {
        setAlertNok(true);
      });
  };

  const deleteSelectedQuestion = (questionId) => {
    axiosAuth
      .post("dashboard/surveys/deleteQuestion", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        question_id: questionId,
      })
      .then(function () {
        setConfirmDeleteQuestion(null);
        getSurveyDetails(data.survey_number);

        getAllData();
        setAlertNok(true);
      })
      .catch(function () {
        setAlertNok(true);
      });
  };

  const handleSendEmail2 = () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    const questionIds = formData.questions.map(
      (question) => question.question_id
    );
    const payload = {
      token,
      email,
      surveyNumber: data.survey_number,
      questionsIds: questionIds,
    };

    setEmailDialog(payload);
  };
  const [questions, setQuestions] = useState([]);

  return (
    <>
      <Dialog
        open={selectedOption !== null}
        fullWidth={true}
        maxWidth={"md"}
        onClose={dialogContent}
      >
        {selectedOption !== null && (
          <div className="container-modal-surveys">
            <div className="header-modal-surveys">
              <h2>{selectedOption.title}</h2>
            </div>
            <div className="questions-modal-surveys">
              <TextField
                size="small"
                name="title"
                label="Titlu"
                value={formData.title}
                onChange={(event) => handleInputChange(event, "title")}
              />
              <TextField
                size="small"
                name="description"
                label="Descriere"
                value={formData.description}
                onChange={(event) => handleInputChange(event, "description")}
              />
              {formData.questions.map((question, questionIndex) => (
                <div key={questionIndex}>
                  <div className="questionAndType">
                    <TextField
                      size="small"
                      name={`questions[${questionIndex}].question_text`}
                      label={`Intrebarea ${questionIndex + 1}`}
                      value={question.question_text}
                      onChange={(event) =>
                        handleQuestionChange(
                          event,
                          questionIndex,
                          "question_text"
                        )
                      }
                    />
                    <div className="questionType">
                      <Select
                        size="small"
                        name={`questions[${questionIndex}].question_type`}
                        label="Tip Intrebare"
                        value={question.question_type}
                        onChange={(event) =>
                          handleQuestionChange(
                            event,
                            questionIndex,
                            "question_type"
                          )
                        }
                      >
                        <MenuItem value="Single choice">Single choice</MenuItem>
                        <MenuItem value="Multiple choice">
                          Multiple choice
                        </MenuItem>
                      </Select>
                    </div>
                    <div>
                      <Button
                        onClick={() =>
                          setConfirmDeleteQuestion(question.question_id)
                        }
                        variant="none"
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </div>
                  <div className="inputChoices">
                    {question.choices.map((choice, choiceIndex) => (
                      <TextField
                        key={choiceIndex}
                        size="small"
                        name={`questions[${questionIndex}].choices[${choiceIndex}].choice_text`}
                        label={`Choice ${choiceIndex + 1}`}
                        value={choice.choice_text}
                        onChange={(event) =>
                          handleChoiceChange(
                            event,
                            questionIndex,
                            choiceIndex,
                            "choice_text"
                          )
                        }
                      />
                    ))}
                  </div>
                  <div className="divider"></div>
                </div>
              ))}
              {questions && (
                <QuestionForm
                  setQuestions={setQuestions}
                  questions={questions}
                />
              )}
            </div>

            <div className="ticket-dialog-control">
              <Button
                onClick={handleSalveaza}
                variant="contained"
                // disabled={!isModified}
              >
                Salvează
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setConfirmDialog(selectedOption.facturi)}
              >
                Șterge
              </Button>
              <Button variant="outlined" onClick={dialogContent}>
                Anulează
              </Button>
            </div>
            {/* <Button onClick={() => handleSendEmail()}>Send Email</Button> */}
            <Button onClick={() => handleSendEmail2()}>Send Email</Button>

            <Divider />
          </div>
        )}
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={confirmDialog !== null}
        onClose={() => setConfirmDialog(null)}
      >
        {confirmDialog !== null && selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere valoare</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi chestionarul</p>
            </div>
            <div className="ticket-dialog-control">
              <Button variant="outlined" color="error" onClick={deleteValue}>
                Șterge
              </Button>
              <Button variant="outlined" onClick={() => setConfirmDialog(null)}>
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={confirmDeleteQuestion !== null}
        onClose={() => setConfirmDeleteQuestion(null)}
      >
        {confirmDeleteQuestion && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere valoare</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi intrebarea</p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteSelectedQuestion(confirmDeleteQuestion)}
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setConfirmDeleteQuestion(null)}
              >
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        className="emailDNDModal"
        open={emailDialog !== null}
        onClose={() => setEmailDialog(null)}
      >
        {emailDialog && (
          <div className="emailDNDModal">
            <EmailDND payload={emailDialog} />
          </div>
        )}
      </Dialog>
      <GlobalAlert
        openAlert={alertNok}
        setOpenAlert={setAlertNok}
        color={succesMessage ? "success" : "error"}
        message={errorMessage || succesMessage}
      />
    </>
  );
};

export default OptiuniSurvey;
