import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axiosAuth from "../../Helpers/axiosAuth";

const Praguri = () => {
  const [praguri, setPraguri] = useState([]);

  const getData = () => {
    axiosAuth
      .post("/dashboard/salarizare/getAllFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "SALARIZARE_PRAGURI",
      })
      .then(function (response) {
        setPraguri(response.data.SALARIZARE_PRAGURI);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="Praguri salarizare">
          <TableHead>
            <TableRow>
              <TableCell>Prag</TableCell>
              <TableCell>Baza</TableCell>
              <TableCell>Comision</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {praguri.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.prag}</TableCell>
                <TableCell>{row.baza}</TableCell>
                <TableCell>{row.comision}</TableCell>
                <TableCell>{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default Praguri;
