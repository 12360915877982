import "./Profile.css";
import pathAPI from "../../pathAPI";
import React, { useState, useEffect } from "react";
import axiosAuth from "../../Helpers/axiosAuth";

export default function Profile() {
  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const uploadAvatar = async () => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("token", localStorage.getItem("token"));
    formData.append("email", localStorage.getItem("email"));

    try {
      const response = await axiosAuth.post(
        "/dashboard/profile/uploadAvatar/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setAvatar(response.data.avatar);
        window.localStorage.setItem("avatar", response.data.avatar);
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    if (file) {
      uploadAvatar();
    }
  }, [file]);

  useEffect(() => {
    var avatar = localStorage.getItem("avatar");
    if (avatar !== "null") {
      setAvatar(avatar);
    }
  }, []);

  return (
    <div className="box">
      <div className="boxHeader">
        <h2>Profil</h2>
      </div>
      <div className="boxContent">
        <p>Avatar</p>
        <div className="uploadAvatar">
          <div className="uploadAvatarBox">
            <label for="avatar">
              <span className="hoverSpanAvatar">
                Schimba<br></br>imaginea
              </span>
              {avatar ? (
                <img
                  alt=""
                  src={pathAPI + "uploads/profile/avatar/" + avatar}
                ></img>
              ) : (
                <span className="infoSpanAvatar">
                  Schimba<br></br>imaginea
                </span>
              )}
            </label>
          </div>
          <input id="avatar" type="file" onChange={handleFileChange} />
        </div>
      </div>
    </div>
  );
}
