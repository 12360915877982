import React, { useState } from "react";
import pathAPI from "../../pathAPI";
import "./FisiereUtilizatori.css"; //
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  TextField,
} from "@mui/material";
import SelectAndRemove from "../../Components/SharedComponents/SelectAndRemove";
import GlobalAlert from "../../Helpers/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import axiosAuth from "../../Helpers/axiosAuth";
const FisiereUtilizatori = () => {
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [functiiAngajati, setfunctiiAngajati] = useState([]);
  const [functionIds, setFunctionIds] = useState([]);
  const [alertSucces, setAlertSucces] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedFileForDeletion, setSelectedFileForDeletion] =
    React.useState(false);
  const [specificFileName, setSpecificFileName] = React.useState("");
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFunctionChange = (e) => {
    setFunctionIds(e.target.value || []);
  };

  const uploadFile = async () => {
    if (!file) {
      return;
    }
    if (!specificFileName.trim()) {
      return;
    }
    const employeeId = localStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("employeeID", employeeId);
    formData.append("name", specificFileName);

    formData.append("functionIds", JSON.stringify(functionIds));
    formData.append("file", file);

    try {
      const response = await axiosAuth.post(
        "dashboard/fisiereUtilizatori/uploadFisiereAngajati/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSpecificFileName("");
        setFunctionIds([]);
        setFile(null);
        getAngajatiFiles();
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const getAngajatiFiles = () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const postData = {
      token: token,
      email: email,
    };
    axiosAuth
      .post(
        pathAPI + "/dashboard/fisiereUtilizatori/getFisiereAngajati/",
        postData
      )
      .then((response) => {
        console.log("saluiuuut", response.data);
        setUploadedFiles(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  React.useEffect(() => {
    axiosAuth
      .post("dashboard/users/getFunctiiAngajati/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((response) => {
        setfunctiiAngajati(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
    getAngajatiFiles();
  }, []);

  const handleRemoveCC = (tagToDelete, selectedData) => {
    axiosAuth
      .post("/dashboard/fisiereUtilizatori/deleteVisibility/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        function_id: tagToDelete,
        file_id: selectedData.file_id,
      })
      .then(function () {
        getAngajatiFiles();
        setAlertSucces(true);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };
  const handleAddCC = (newValues, data) => {
    axiosAuth
      .post("/dashboard/fisiereUtilizatori/insertVisibility/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        function_id: newValues[newValues.length - 1].id,
        file_id: data.file_id,
      })
      .then(function () {
        getAngajatiFiles();
        setAlertSucces(true);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };
  const openModal = (value) => {
    setSelectedFileForDeletion(value);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFileForDeletion(null);
  };
  const handleDelete = () => {
    handleDeleteFile(selectedFileForDeletion);
    closeModal();
  };
  const handleDeleteFile = (file_id) => {
    console.log("Delete file", file_id);
    axiosAuth
      .post("/dashboard/fisiereUtilizatori/deleteFile/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        file_id: file_id,
      })
      .then(function () {
        getAngajatiFiles();
        setAlertSucces(true);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };
  const handleFileNameChange = (event) => {
    setSpecificFileName(event.target.value);
  };
  const isUploadDisabled = !file || !specificFileName.trim();
  return (
    <div className="box">
      <div className="boxHeader">
        <h2>Incarcare fisiere pentru distribuire</h2>
      </div>
      <div className="uploadZone">
        <FormControl>
          <InputLabel>Vizibilitate:</InputLabel>
          <Select
            multiple
            value={functionIds}
            onChange={handleFunctionChange}
            label="Select Functions"
          >
            {functiiAngajati.map((func) => (
              <MenuItem key={func.id} value={func.id}>
                {func.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <label className="pt-2">
          Alege Fisierul:
          <input type="file" onChange={handleFileChange} />
        </label>
        <TextField
          size="small"
          label="Denumire pentru fisier"
          name="specificFileName"
          value={specificFileName}
          onChange={handleFileNameChange}
        />{" "}
        <Button
          onClick={uploadFile}
          variant="contained"
          disabled={isUploadDisabled}
        >
          Adaugă
        </Button>
      </div>

      <div className="file-cards-container">
        {uploadedFiles.map((uploadedFile) => (
          <div className="file-card" key={uploadedFile.file_id}>
            <div className="fileCardHeader">
              <div className="toolTipIcon">
                <Tooltip title="Vizualizeaza fisierul">
                  <button
                    className="viewFileIcon"
                    onClick={(e) => {
                      window.open(
                        pathAPI +
                          "uploads/FisiereAngajati/" +
                          uploadedFile.file_name
                      );
                      e.stopPropagation();
                    }}
                  >
                    <VisibilityIcon></VisibilityIcon>
                  </button>
                </Tooltip>
              </div>
              <div className="delete-button-container">
                <Tooltip title="Sterge fisierul">
                  <Button
                    className="delete-button"
                    onClick={() => openModal(uploadedFile.file_id)}
                  >
                    X
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-2">
                <strong>Denumirea fisierului: </strong>

                <p className="textFileName"> {uploadedFile.name}</p>
              </div>

              <div className="flex gap-2">
                <strong>Numele fisierului: </strong>

                <p className="textFileName"> {uploadedFile.file_name}</p>
              </div>

              <div className="flex gap-2">
                <strong>Data incărcării:</strong>
                <p> {uploadedFile.upload_date}</p>
              </div>
              <div>
                <strong>Vizibilitate: </strong>

                <SelectAndRemove
                  options={functiiAngajati}
                  selectedOptions={uploadedFile.visibility_for}
                  selectedId={uploadedFile.file_id}
                  selectedData={uploadedFile}
                  onAdd={handleAddCC}
                  onDelete={handleRemoveCC}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <GlobalAlert
        openAlert={alertSucces}
        setOpenAlert={setAlertSucces}
        color={"success"}
        message={"Actiune reusita."}
      />
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Confirma stergerea</DialogTitle>
        <DialogContent>Esti sigur/a ca vrei sa faci asta ?</DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FisiereUtilizatori;
