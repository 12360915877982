import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosAuth from "../Helpers/axiosAuth.jsx";

const Session = (roles) => {
  const [auth, setAuth] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    const sendData = {
      email: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
      roles: roles,
    };
    axiosAuth.post("dashboard/app/session", sendData).then((result) => {
      if (roles !== undefined) {
        if (result.data.user.status === 1 && result.data.passAccess) {
          setAuth(true);
        } else {
          if (result.data.user.status === 1) {
            navigate(`/`);
          } else {
            if (window.location.pathname === "/reset-password") {
              console.log("");
            } else {
              navigate(`/login`);
            }
          }
        }
        var activeRolesParse = result.data.activeRoles.map(function (x) {
          return parseInt(x, 10);
        });
        window.localStorage.setItem(
          "activeRoles",
          JSON.stringify(activeRolesParse)
        );
      }
    });
  }, []);
  return auth;
};

export default Session;
