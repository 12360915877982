export const GET_TEAM_BY_ID_SUCCESS = "GET_TEAM_BY_ID_SUCCESS";
export const GET_TEAM_BY_ID_FAILURE = "GET_TEAM_BY_ID_FAILURE";

export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_FAILURE = "DELETE_TEAM_MEMBER_FAILURE";

export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE";

export const ASSIGN_USER_TO_TEAM_SUCCESS = "ASSIGN_USER_TO_TEAM_SUCCESS";
export const ASSIGN_USER_TO_TEAM_FAILURE = "ASSIGN_USER_TO_TEAM_FAILURE";

export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE";

export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const GET_ALL_TEAMS_FAILURE = "GET_ALL_TEAMS_FAILURE";

export const GET_ALL_USERS_SUCCESS = {
  type: "GET_ALL_USERS_SUCCESS",
  meta: {
    timestamp: Date.now(),
  },
};
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE";

export const TEAMS_U_T_S = "UPDATE_TEAM_SUCCESS";
export const TEAMS_U_T_F = "UPDATE_TEAM_FAILURE";

export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";

export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const GET_NEWSLETTER_DATA_SUCCESS = "GET_NEWSLETTER_DATA_SUCCESS";
export const GET_NEWSLETTER_DATA_FAILURE = "GET_NEWSLETTER_DATA_FAILURE";
export const INSERT_NEWSLETTER_DATA_SUCCESS = "INSERT_NEWSLETTER_DATA_SUCCESS";
export const INSERT_NEWSLETTER_DATA_FAILURE = "INSERT_NEWSLETTER_DATA_FAILURE";

export const SEND_NEWSLETTER_EMAILS_SUCCESS = "SEND_NEWSLETTER_EMAILS_SUCCESS";
export const SEND_NEWSLETTER_EMAILS_FAILURE = "SEND_NEWSLETTER_EMAILS_FAILURE";

export const INSERT_CLIENTS_EMAILS_SUCCESS = "INSERT_CLIENTS_EMAILS_SUCCESS";
export const INSERT_CLIENTS_EMAILS_FAILURE = "INSERT_CLIENTS_EMAILS_FAILURE";

export const GET_TRANSPORTATORI_SUCCES = "GET_TRANSPORTATORI_SUCCES";
export const GET_TRANSPORTATORI_FAILURE = "GET_TRANSPORTATORI_FAILURE";
export const UPDATE_TRANSPORTATOR_SUCCESS = "UPDATE_TRANSPORTATOR_SUCCESS";
export const UPDATE_TRANSPORTATOR_FAILURE = "UPDATE_TRANSPORTATOR_FAILURE";
export const DELETE_TRANSPORTATOR_SUCCESS = "DELETE_TRANSPORTATOR_SUCCESS";
export const DELETE_TRANSPORTATOR_FAILURE = "DELETE_TRANSPORTATOR_FAILURE";
export const GET_COUNTRY_CODES_SUCCESS = "GET_COUNTRY_CODES_SUCCESS";
export const GET_COUNTRY_CODES_FAILURE = "GET_COUNTRY_CODES_FAILURE";

export const GET_TESA_SUCCES = "GET_TESA_SUCCES";
export const GET_TESA_FAILURE = "GET_TESA_FAILURE";
export const UPDATE_TESA_FAILURE = "UPDATE_TESA_FAILURE";
export const UPDATE_TESA_SUCCESS = "UPDATE_TESA_SUCCESS";
export const DELETE_TESA_SUCCESS = "DELETE_TESA_SUCCESS";
export const DELETE_TESA_FAILURE = "DELETE_TESA_FAILURE";
export const INSERT_TESA_SUCCES = "INSERT_TESA_SUCCES";
export const INSERT_TESA_FAILURE = "INSERT_TESA_FAILURE";

export const GET_TESA_NEW_SUCCES = "GET_TESA_NEW_SUCCES";
export const GET_TESA_NEW_FAILURE = "GET_TESA_NEW_SFAILURE";
export const UPDATE_TESA_NEW_FAILURE = "UPDATE_TESA_NEW_FAILURE";
export const UPDATE_TESA_NEW_SUCCESS = "UPDATE_TESA_NEW_SUCCESS";
export const DELETE_TESA_NEW_SUCCESS = "DELETE_TESA_NEW_SUCCESS";
export const DELETE_TESA_NEW_FAILURE = "DELETE_TESA_NEW_FAILURE";
export const INSERT_TESA_NEW_SUCCES = "INSERT_TESA_NEW_SUCCES";
export const INSERT_TESA_NEW_FAILURE = "INSERT_TESA_NEW_FAILURE";

export const GET_TESA_DATA_SUCCESS = "GET_TESA_DATA_SUCCESS";
export const GET_TESA_DATA_FAILURE = "GET_TESA_DATA_FAILURE";
export const GET_TESA_HISTORY_SUCCESS = "GET_TESA_HISTORY_SUCCESS";
export const GET_TESA_HISTORY_FAILURE = "GET_TESA_HISTORY_FAILURE";

export const GET_ALL_DEPARTMENTS_SUCCESS = "GET_ALL_DEPARTMENTS_SUCCESS";
export const GET_ALL_DEPARTMENTS_FAILURE = "GET_ALL_DEPARTMENTS_FAILURE";

export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILURE = "CREATE_DEPARTMENT_FAILURE";

export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

export const GET_TEAM_BY_DEPARTMENT_SUCCESS = "GET_TEAM_BY_DEPARTMENT_SUCCESS";
export const GET_TEAM_BY_DEPARTMENT_FAILURE = "GET_TEAM_BY_DEPARTMENT_FAILURE";
