import { DataGrid, GridToolbar, roRO } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import PaginaCurs from "./PaginaCurs/PaginaCurs";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import axiosAuth from "../../../Helpers/axiosAuth";

export default function Cursuri() {
  const [nr_pagina, setNr_pagina] = useState(null);
  const [cursuri, setCursuri] = useState([]);
  const [selectedCurs, setSelectedCurs] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCursuri = () => {
    setLoading(true);
    axiosAuth
      .post("dashboard/cursuri/getCursuri", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        const responseCursuri = response.data.data.map((item, index) => ({
          id: index + 1,
          ...item,
        }));
        setCursuri(responseCursuri);
        setLoading(false);
        // console.log("responseCursuri", responseCursuri)
      })
      .catch(function (error) {
        console.error("Request failed:", error);
        setLoading(false);
      });
  };

  function isOverflown(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }
  const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };

    const handleMouseLeave = () => {
      setShowFullCell(false);
    };

    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }

      function handleKeyDown(nativeEvent) {
        if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
          setShowFullCell(false);
        }
      }

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);

    return (
      <Box
        className="custom-box"
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: "center",
          lineHeight: "24px",
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
        }}
      >
        <Box
          ref={cellDiv}
          sx={{
            height: "100%",
            width,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        <Box
          ref={cellValue}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </Box>
    );
  });
  // const cursuriColumns = [
  //     ...Object.keys(cursuriColumns_mapping).map((field) => ({
  //         field: field,
  //         headerName: cursuriColumns_mapping[field],
  //         flex: 1,
  //     }))
  // ];

  const cursuriColumns = [
    {
      field: "nume_curs",
      headerName: "Nume Curs",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "lansare",
      headerName: "Lansare",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "ultima_actualizare",
      headerName: "Ultima actualizare",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "timp_petrecutGLOBAL",
      headerName: "Timp petrecut",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "timp_necesarGLOBAL",
      headerName: "Timp necesar",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "cox",
      headerName: "Progres",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "50%",
            border: "1px solid #fff",
            padding: "1px",
            borderRadius: "4px",
          }}
        >
          <span
            style={{
              width:
                parseInt(
                  (100 * parseInt(params.row.timp_petrecutGLOBAL)) /
                    parseInt(params.row.timp_necesarGLOBAL)
                ) + "%",
              background: "green",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "3px",
            }}
          >
            {parseInt(
              (100 * parseInt(params.row.timp_petrecutGLOBAL)) /
                parseInt(params.row.timp_necesarGLOBAL)
            )}
            %
          </span>

          {/* <span
                        className={
                            "circleSpan " +
                            (params?.row?.Type == 0 ? "agentCircle" : "tesaCircle")
                        }
                    >
                        <input
                            type="checkbox"
                            checked={checkboxState[params.row.name] || ""}
                            onChange={() => handleCheckboxChange(params.row.name)}
                        />
                        <span></span>
                        {params.value}
                    </span> */}
        </div>
      ),
    },
  ];

  const handleTicketClick = (params) => {
    const { row } = params;
    setSelectedCurs(row);
  };

  const resetSelectedCurs = () => {
    setSelectedCurs(null);
  };

  useEffect(() => {
    getCursuri();
  }, []);

  return (
    <>
      <DataGrid
        onCellClick={handleTicketClick}
        localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
        rows={cursuri}
        columns={cursuriColumns}
        loading={loading}
        components={{
          Toolbar: GridToolbar,
        }}
        initialState={{
          ...cursuri.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        autoHeight
      />
      <PaginaCurs
        nr_paginaPROPS={nr_pagina}
        selectedCursPROPS={selectedCurs}
        onClose={resetSelectedCurs}
      />
    </>
  );
}
