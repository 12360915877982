import Register from "./Pages/Register.js";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./Pages/Login.js";
import MainDash from "./Pages/MainDash/MainDash.jsx";
import UsersMain from "./Pages/Users/Main.jsx";
import Sidebar from "./Components/Sidebar/Sidebar.jsx";
import { UilBars } from "@iconscout/react-unicons";
import React, { useState } from "react";
import MainFeedbacks from "./Pages/Feedback/MainFeedbacks";
import Ticketing from "./Pages/Ticketing/Ticketing.jsx";
import Csv2 from "./Pages/compareFiles/csv2.js";
import MainGps from "./Pages/GPS/MainGPS.js";
import MainPenalizari from "./Pages/Penalizari/MainPenalizari.js";
import Salarizare from "./Pages/Salarizare/Salarizare.jsx";
import { useEffect } from "react";
import ResetPassword from "./Pages/ResetPassword/ResetPassword.js";
import Survey from "./Pages/Surveys/Survey.jsx";
import Salarizare2 from "./Pages/SalarizareBeta/Salarizare2.jsx";
import Juridic from "./Pages/Juridic/Juridic.jsx";
import FisiereUtilizatori from "./Pages/FisiereUltilizatori/FisiereUtilizatori.jsx";
import Cursuri from "./Pages/Cursuri/Cursuri.jsx";
import Profile from "./Pages/Profile/Profile.jsx";
import store from "./store.js";
import { Provider } from "react-redux";
import NewslettersMain from "./Pages/newsletters_comenzi/newsletters_main.jsx";
import SalarizareNew from "./Pages/SalarizareNew/SalarizareNew.jsx";
import pathAPI from "./pathAPI.js";

function App() {
  const [currentModule, setCurrentModule] = useState("Dashboard");
  const [expanded, setExpaned] = useState("");
  const [auth, setAuth] = useState(false);
  const [themeType, setThemeType] = useState("darkMode");
  const [themeStorage, setThemeStorage] = useState(false);
  const [user, setUser] = useState("");
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    var user = localStorage.getItem("name");
    var avatar = localStorage.getItem("avatar");
    setUser(user);
    if (avatar !== "null") {
      setAvatar(avatar);
    }
  }, []);

  const handleLinkClick = (module) => {
    setCurrentModule(module);
    if (window.innerWidth <= 768) {
      setExpaned("");
    }
  };

  const isAuth = (value) => {
    setAuth(value);
  };

  const handleOpenMenu = (value) => {
    setExpaned(value);
  };

  const logOut = () => {
    localStorage.removeItem("email");
    localStorage.clear();
    window.location.reload(false);
  };

  const toggleMenuDesktop = () => {
    if (expanded === "expanded") {
      setExpaned("");
    } else {
      setExpaned("expanded");
    }
  };

  const sendThemeMode = (value) => {
    if (value) {
      setThemeType("darkMode");
      window.localStorage.setItem("themeMode", "darkMode");
    } else {
      setThemeType("lightMode");
      window.localStorage.setItem("themeMode", "lightMode");
    }
  };

  useEffect(() => {
    setThemeStorage(localStorage.getItem("themeMode"));
    if (themeStorage) {
      setThemeType(themeStorage);
    } else {
      setThemeType("darkMode");
    }
  }, [themeStorage]);

  useEffect(() => {
    const bodyClasses = document.body.classList;
    const classesToRemove = Array.from(bodyClasses);
    classesToRemove.forEach((className) => {
      bodyClasses.remove(className);
    });
    document.body.classList.add(themeType); // Add your desired class name here
    if (window.innerWidth <= 768) {
      document.body.classList.add("isMobile");
    }
  }, [themeType]);

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <div className={"main " + expanded}>
            <Sidebar
              handleLinkClick={handleLinkClick}
              handleOpenMenu={handleOpenMenu}
              isAuth={isAuth}
              sendThemeMode={sendThemeMode}
            />
            <div className="content ">
              {auth ? (
                <div className="header2 ">
                  <div className="toggleSideBar">
                    <span
                      id="toggleSideBar"
                      onClick={() => toggleMenuDesktop()}
                    >
                      <UilBars />
                    </span>
                  </div>
                  <div className="moduleTitle">
                    <h1>{currentModule}</h1>
                  </div>

                  <div className="profileInfo ">
                    <div className="profileBox">
                      <p>{user}</p>
                      <span>
                        {avatar ? (
                          <img
                            alt=""
                            src={pathAPI + "uploads/profile/avatar/" + avatar}
                          ></img>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                    <div className="profileDropDown">
                      <ul>
                        <li>
                          <Link to="/profile">Profil</Link>
                        </li>
                        <li>
                          <a href="#">Notificari</a>
                        </li>
                        <li>
                          <a onClick={logOut}>Logout</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="container2">
                <Routes>
                  <Route path="/" element={<MainDash />} />
                  <Route path="/academie" element={<Cursuri />} />
                  <Route path="/users" element={<UsersMain />} />
                  <Route path="/newsletters" element={<NewslettersMain />} />

                  <Route path="/profile" element={<Profile />} />

                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/feedback" element={<MainFeedbacks />} />
                  <Route path="/csv" element={<Csv2 />} />
                  <Route path="/fisiere" element={<FisiereUtilizatori />} />

                  <Route path="/gpsVehicles" element={<MainGps />} />
                  <Route path="/penalizari" element={<MainPenalizari />} />
                  <Route path="/ticketing" element={<Ticketing />} />
                  <Route path="/juridic" element={<Juridic />} />
                  <Route path="/salarizare" element={<Salarizare />} />
                  <Route path="/salarizareBeta" element={<Salarizare2 />} />
                  <Route path="/salarizareNew" element={<SalarizareNew />} />

                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/survey" element={<Survey />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
