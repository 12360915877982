// CreateSurveyForm.jsx
import React, { useState } from "react";
import QuestionForm from "./QuestionForm";
import { TextField, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as Yup from "yup";
import axiosAuth from "../../../Helpers/axiosAuth";

function CreateSurveyForm() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const surveySchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    expiry_date: Yup.date().nullable(),
    is_active: Yup.boolean().required("Visibility is required"),
  });
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      await surveySchema.validate({
        title,
        description,
        expiry_date: expiryDate,
        is_active: visibility,
      });

      const surveyData = {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        title,
        description,
        expiry_date: expiryDate ? expiryDate.toISOString() : null,
        is_active: visibility,
      };
      const surveyResponse = await axiosAuth.post(
        "dashboard/surveys/insertSurvey",
        surveyData
      );
      const surveyId = surveyResponse.data.id;

      for (const question of questions) {
        const questionData = {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          question_text: question.questionText,
          surveyId,
          question_type: question.questionType,
          justificationRequired: question.justificationRequired,
        };
        const questionResponse = await axiosAuth.post(
          "dashboard/surveys/insertQuestion",
          questionData
        );
        const questionId = questionResponse.data.id;

        for (const choice of question.choices) {
          const choiceData = {
            token: localStorage.getItem("token"),
            email: localStorage.getItem("email"),
            choice_text: choice,
            questionId,
          };
          await axiosAuth.post("dashboard/surveys/insertChoice", choiceData);
        }
      }

      setTitle("");
      setDescription("");
      setQuestions([]);
      alert("Survey created successfully");
    } catch (error) {
      setError("An error occurred while creating the survey.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="createSurvey">
        <h1>Create New Survey</h1>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <TextField
          type="text"
          placeholder="Title"
          value={title}
          label={"Title"}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          type="text"
          placeholder="Description"
          value={description}
          label={"Description"}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className="surveyDetails">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Data de expirare"
              format="DD-MM-YYYY-HH:mm:ss"
              value={expiryDate}
              onChange={(newValue) => setExpiryDate(newValue)}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  required
                  sx={{ width: "240px" }}
                />
              )}
            />
          </LocalizationProvider>
          <div className="visibilitySwitch">
            <FormControlLabel
              required
              control={
                <Switch
                  width="80px"
                  checked={visibility}
                  onChange={(e) => setVisibility(e.target.checked)}
                  className="customSwitch"
                />
              }
            />
            <label className="switchLabel">
              {visibility ? "Activ" : "Inactiv"}
            </label>
          </div>
        </div>
        <QuestionForm setQuestions={setQuestions} questions={questions} />
      </div>
      <Button variant="contained" onClick={handleSubmit}>
        Save Survey
      </Button>
    </>
  );
}

export default CreateSurveyForm;
