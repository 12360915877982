import {
  GET_TESA_SUCCES,
  GET_TESA_FAILURE,
  UPDATE_TESA_FAILURE,
  UPDATE_TESA_SUCCESS,
  DELETE_TESA_SUCCESS,
  DELETE_TESA_FAILURE,
  INSERT_TESA_SUCCES,
  INSERT_TESA_FAILURE,
} from "../actionTypes";
import pathAPI from "../../pathAPI";
import axiosInstance from "../../Helpers/axiosInstance";
import dayjs from "dayjs";
import axiosAuth from "../../Helpers/axiosAuth";

export const getTesaSucces = (tesa) => ({
  type: GET_TESA_SUCCES,
  payload: tesa,
});

export const getTesaFailure = (error) => ({
  type: GET_TESA_FAILURE,
  payload: error,
});

export const updateTesaSuccess = (updatedTesa) => ({
  type: UPDATE_TESA_SUCCESS,
  payload: updatedTesa,
});

export const updateTesaFailure = (error) => ({
  type: UPDATE_TESA_FAILURE,
  payload: error,
});
export const deleteTesaSuccess = () => ({
  type: DELETE_TESA_SUCCESS,
});

export const deleteTesaFailure = (error) => ({
  type: DELETE_TESA_FAILURE,
  payload: error,
});
export const insertTesaSucces = (tesa) => ({
  type: INSERT_TESA_SUCCES,
  payload: tesa,
});

export const insertTesaFailure = (error) => ({
  type: INSERT_TESA_FAILURE,
  payload: error,
});
export const updateTesa = (updatedData) => {
  return async (dispatch) => {
    console.log(updatedData);
    try {
      await axiosInstance.post(`dashboard/salarizare/updateTesaBeta`, {
        id: updatedData.id,
        nume_prenume: updatedData.nume_prenume,
        iban: updatedData.iban,
        data_angajare: updatedData.data_angajare,
        salariu_net: updatedData.salariu_net,
        yearMonth: updatedData.yearMonth,
      });
      dispatch(updateTesaSuccess());
    } catch (error) {
      dispatch(updateTesaFailure(error.message));
    }
  };
};
export const getTesa = (yearmonth) => {
  const adjustedDate = dayjs(yearmonth).startOf("month").format("YYYY-MM-DD");
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/salarizare/getTesaBeta`,
        {
          yearMonth: adjustedDate,
        }
      );
      console.log(response);
      dispatch(getTesaSucces(response.data));
    } catch (error) {
      dispatch(getTesaFailure(error.message));
    }
  };
};

export const deleteTesa = (transportatorId) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`dashboard/salarizare/deleteTesa/`, {
        id: transportatorId,
      });

      dispatch(deleteTesaSuccess());
    } catch (error) {
      dispatch(deleteTesaFailure(error.message));
    }
  };
};
export const insertTesa = (formData) => {
  return async (dispatch) => {
    try {
      const response = await axiosAuth.post(
        `dashboard/salarizare/insertTesa`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      dispatch(insertTesaSucces());
    } catch (error) {
      dispatch(insertTesaFailure(error.message));
    }
  };
};
