import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete as DeleteIcon } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import FeedbacksTeamsEditor from "./FeedbacksTeamsEditor";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import axiosAuth from "../../Helpers/axiosAuth";

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
});

const ListItemContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  width: "100%",
});

function CreateTeam() {
  const [teamName, setTeamName] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [memberEmail, setMemberEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [open, setOpen] = useState(false);
  const [teamInserted, setTeamInserted] = useState(false);
  const handleNameChange = (event) => {
    setTeamName(event.target.value);
  };

  const handleMemberNameChange = (event, newValue) => {
    if (newValue) {
      setMemberEmail(newValue.email);
    } else {
      setMemberEmail("");
    }
  };
  const handleEditClose = () => {
    setOpen(false);
    setTeamName("");
    setTeamMembers([]);
  };
  const handleAddMember = () => {
    if (memberEmail.trim() !== "") {
      setTeamMembers([...teamMembers, { email: memberEmail }]);
      setMemberEmail("");
    }
  };

  const handleRemoveMember = (indexToRemove) => {
    setTeamMembers((prevMembers) =>
      prevMembers.filter((_, index) => index !== indexToRemove)
    );
  };

  const getMembersEmails = () => {
    axiosAuth
      .post("dashboard/feedback/getDistinctMembers", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        const emailsArray = res.data.map((obj) => ({
          email: obj.email,
        }));
        setEmails(emailsArray);
      })
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    getMembersEmails();
  }, []);

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create an array of members with name and email properties
    const memberObjects = teamMembers.map((member) => ({
      email: member.email,
    }));

    // Call the server-side insertTeam function
    const team = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      teamName: teamName,
      teamMembers: memberObjects,
    };
    axiosAuth
      .post("dashboard/feedback/createTeam", team, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data === "Team inserted successfully") {
          // Team was successfully created, reset the form
          setTeamInserted(true);
          setTeamName("");
          setTeamMembers([]);
          setError("");
          setSuccessMessage("Team inserted successfully");
        } else {
          setError(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
        setError("An error occurred while creating the team.");
      });
  };
  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Create Team
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={handleEditClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Form onSubmit={handleSubmit}>
            <FormControl>
              <InputLabel htmlFor="team-name">Team Name</InputLabel>
              <Input
                id="team-name"
                value={teamName}
                onChange={handleNameChange}
                placeholder="Enter team name"
                required
              />
            </FormControl>
            <FormControl>
              <ListItemContainer>
                <Autocomplete
                  sx={{ width: 300 }}
                  size="small"
                  id="member-name"
                  options={emails}
                  getOptionLabel={(option) => option.email}
                  onChange={handleMemberNameChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Member Name"
                      variant="outlined"
                    />
                  )}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddMember}
                  disabled={memberEmail.trim() === ""}
                >
                  Add
                </Button>
              </ListItemContainer>
            </FormControl>
            {teamMembers.length > 0 && (
              <List>
                {teamMembers.map((member, index) => (
                  <ListItem key={index}>
                    <ListItemText secondary={member.email} />
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveMember(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            )}
            <Button
              type="submit"
              disabled={teamMembers.length === 0}
              variant="contained"
              color="primary"
            >
              Create Team
            </Button>
          </Form>
          {error && (
            <Typography color="error" variant="subtitle1">
              {error}
            </Typography>
          )}
          {successMessage && (
            <Typography variant="body1" color="primary">
              {successMessage}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
      <FeedbacksTeamsEditor emails={emails} teamInserted={teamInserted} />
    </div>
  );
}

export default CreateTeam;
