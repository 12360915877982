import React, { useEffect, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Button } from "@mui/material";
import dayjs from "dayjs";
import MuiXDataGrid from "../../Components/Table/DataGrid";
import axiosAuth from "../../Helpers/axiosAuth";
const SalariiAgenti2 = () => {
  const [totalNetSum, setTotalNetSum] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [dateSalarii, setDateSalarii] = useState([]);
  const [datePicked, setDatePicked] = useState(dayjs().format("YYYY-MM-DD"));
  const [parametrii, setParametrii] = useState({
    comision: null,
    taxe: null,
    cursValutar: null,
    topBonus: null,
  });

  const getSalarii = () => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");
    axiosAuth
      .post("/dashboard/salarizare/getSalariiBeta/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
        comision: parametrii.comision,
        taxe: parametrii.taxe,
        cursValutar: parametrii.cursValutar,
        topBonus: parametrii.topBonus,
      })
      .then(function (response) {
        setDateSalarii(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
    axiosAuth
      .post("/dashboard/salarizare/saveCentralizareSalariiBeta/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
      })
      .then(function () {})
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const viewSalarii = () => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");
    axiosAuth
      .post("/dashboard/salarizare/viewSalariiBeta/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
      })
      .then(function (response) {
        setDateSalarii(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const getParametrii = () => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");
    axiosAuth
      .post("/dashboard/salarizare/getParametriiBeta/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
      })
      .then(function (response) {
        const res = response.data[0];
        setParametrii({
          comision: res.comision,
          taxe: res.taxe,
          cursValutar: res.cursValutar,
          topBonus: res.topBonus,
        });
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setParametrii({
      ...parametrii,
      [name]: value,
    });
  };

  useEffect(() => {
    getParametrii();
  }, [dateSalarii, datePicked]);

  useEffect(() => {
    viewSalarii();
  }, [datePicked]);

  useEffect(() => {
    let sum = 0;
    dateSalarii.forEach((row) => {
      sum += parseFloat(row.totalNet) || 0;
    });
    setTotalNetSum(sum);

    let sumTotalGeneral = 0;
    dateSalarii.forEach((row) => {
      sumTotalGeneral += parseFloat(row.totalGeneral) || 0;
    });
    setTotalGeneral(sumTotalGeneral);
  }, [dateSalarii]);

  return (
    <>
      <div className="containerInputs">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Luna / anul"
            views={["year", "month"]}
            value={datePicked}
            onChange={(newValue) => {
              setDatePicked(newValue);
            }}
            format="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField size="small" {...params} required />
            )}
          />
        </LocalizationProvider>

        <TextField
          size="small"
          name="comision"
          label="Comision"
          value={parametrii.comision ?? ""}
          onChange={handleInputChange}
          required
        />
        <TextField
          size="small"
          name="taxe"
          label="Taxe"
          value={parametrii.taxe ?? ""}
          onChange={handleInputChange}
          required
        />
        <TextField
          size="small"
          name="cursValutar"
          label="Curs Valutar"
          value={parametrii.cursValutar ?? ""}
          onChange={handleInputChange}
          required
        />
        <TextField
          size="small"
          name="topBonus"
          label="Top Bonus"
          value={parametrii.topBonus ?? ""}
          onChange={handleInputChange}
          required
        />
        <Button variant="contained" onClick={getSalarii}>
          Generează
        </Button>
      </div>
      <MuiXDataGrid
        columns={[
          {
            field: "fullname",
            headerName: "Nume",
            flex: 2,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "profitIndividual",
            headerName: "Profit individual",
            flex: 1,
          },
          {
            field: "profitColaborare",
            headerName: "ProfitColaborare",
            flex: 1,
          },
          { field: "totalGeneral", headerName: "Total general", flex: 1 },
          {
            field: "prag",
            headerName: "Prag",
            flex: 1,
          },
          {
            field: "baza",
            headerName: "Baza",
            flex: 1,
          },
          {
            field: "comisionNet",
            headerName: "Comision net",
            flex: 1,
          },
          { field: "topBonus", headerName: "Top bonus", flex: 1 },
          { field: "totalNet", headerName: "Total net", flex: 1 },
        ]}
        rows={[
          ...dateSalarii,
          {
            id: "totals",
            fullname: "Totaluri",
            totalGeneral: totalGeneral,
            totalNet: totalNetSum,
          },
        ]}
      />

      <div className="infoTable">
        <span>Rezultate: {dateSalarii.length}</span>
      </div>
    </>
  );
};
export default SalariiAgenti2;
