import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import "./CreateRequest.css";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./CreateRequest.css";

const DropZone = ({
  file,
  setFile,
  filesSelected,
  validateFile,
  fileInputRef,
}) => {
  const [className, setClassName] = useState("drop-container-dark");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dragOver = (e) => {
    e.preventDefault();
    setClassName("drop-container-dark");
  };
  const dragEnter = (e) => {
    e.preventDefault();
    setClassName("drop-container-dark-on-drag-over");
  };
  const dragLeave = (e) => {
    e.preventDefault();
    setClassName("drop-container-dark");
  };
  const fileInputClicked = (e) => {
    fileInputRef.current.click();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (validateFile(files)) {
      // setAlertMessage('File was added !')
      // setAlertTypeDropzone('info');
      // setOpenAlertDropzone(true);
      setFile((file) => [...file, files]);
    }
    // else{
    //     setAlertMessage('The file does not meet the requested formats !')
    //     setAlertTypeDropzone('warning');
    //     setOpenAlertDropzone(true);
    // }
  };

  const removeFile = (item) => {
    var array = [...file];
    var index = array.indexOf(item);
    if (index !== -1) {
      array.splice(index, 1);
      if (array.length > 0) {
        setFile(array);
      } else {
        setFile([]);
        handleClose();
      }
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex" }}
        onClick={fileInputClicked}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <div className="drop-message-normal">
          <div>
            <UploadIcon fontSize="medium" />
          </div>
          <span style={{ fontSize: "small" }}>
            Drag & Drop files here or click to upload !
          </span>
          {/* <InfoOutlinedIcon fontSize='small' style={{position: 'absolute', top: 10, left: 10}} onMouseEnter={(event) => {setEventPopover(event); setPopoverText('Accepted formats: .png, .jpg, bmp, .xlsx, .pdf')}}/> */}
          {file.length > 0 ? (
            <VisibilityIcon
              style={{ position: "absolute", bottom: 10, right: 20 }}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
            /> //onMouseLeave={handlePopoverClose}  />
          ) : null}
        </div>
        <input
          ref={fileInputRef}
          className="file-input"
          type="file"
          id="reset"
          multiple
          onChange={() => filesSelected(fileInputRef, file, setFile)}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div style={{ backgroundColor: "rgba(29, 222, 203, 0.1)", borders: 1 }}>
          <Typography sx={{ p: 1, textAlign: "center", fontSize: 12 }}>
            <b>Added files</b>{" "}
          </Typography>
          <CloseOutlinedIcon
            fontSize="small"
            sx={{
              position: "absolute",
              right: 5,
              top: 5,
              color: "#000",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </div>
        {file.length !== 0 ? (
          <TableContainer
            component={Paper}
            style={{ backgroundColor: "rgba(29, 222, 203, 0.1)", borders: 1 }}
          >
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontSize: 11 }}>
                    <b>File name</b>
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: 11 }}>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {file.map((f) => {
                  return (
                    <TableRow key={f[0].name}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontSize: 11 }}
                      >
                        {f[0].name}
                      </TableCell>
                      <TableCell align="center">
                        <DeleteForeverRoundedIcon
                          fontSize="small"
                          style={{ color: "#000000", cursor: "pointer" }}
                          onClick={() => removeFile(f)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Popover>
    </div>
  );
};

export default DropZone;
