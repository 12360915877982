import React from "react";
import { TextField, Button, Autocomplete } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";

const FilterModal = ({
  open,
  onClose,
  onApplyFilter,
  onClearFilters,
  agentiData,
  clientiData,
}) => {
  const [filterAgent, setFilterAgent] = React.useState(null);
  const [pickedAgent, setPickedAgent] = React.useState(null);

  const [filterClient, setFilterClient] = React.useState(null);
  const [filterDosar, setFilterDosar] = React.useState("");
  const [datePicked, setDatePicked] = React.useState(null);
  const [datePickedTo, setDatePickedTo] = React.useState(null);

  const handleApplyFilter = () => {
    const filteredAgent = pickedAgent || null;
    const filteredClient = filterClient || null;
    const filteredDosar = filterDosar.trim() !== "" ? filterDosar.trim() : null;

    onApplyFilter(
      filteredAgent,
      filteredClient,
      filteredDosar,
      datePicked,
      datePickedTo
    );
    onClose();
  };

  const handleClearFilters = () => {
    setFilterAgent(null);
    setFilterClient(null);
    setDatePicked(null);
    setDatePickedTo(null);
    setFilterDosar("");

    onClearFilters();
    onClose();
  };
  // const handleAgentChange = (event, value) => {
  //   setFilterAgent(value.name);
  // };
  const handleRomoveDosarFilter = () => {
    setFilterDosar("");
  };
  const handleClientChange = (event, value) => {
    setFilterClient(value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter</DialogTitle>
      <DialogContent
        style={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
        <Autocomplete
          size="small"
          options={_.uniqBy(agentiData, "name")}
          groupBy={(option) => option.type}
          getOptionLabel={(option) => option.name}
          value={filterAgent}
          onChange={(event, value) => {
            console.log(value.name);
            setFilterAgent(value);
            setPickedAgent(value.name);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Agent"
              variant="outlined"
              required
            />
          )}
        />

        <Autocomplete
          size="small"
          options={clientiData.map((clientiData) => clientiData.NAME)}
          value={filterClient}
          onChange={handleClientChange}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Client"
              variant="outlined"
              required
            />
          )}
        />

        <TextField
          label="Dosar"
          value={filterDosar}
          onChange={(e) => setFilterDosar(e.target.value)}
          fullWidth
          size="small"
          InputProps={{
            endAdornment: filterDosar && (
              <InputAdornment position="end">
                <IconButton onClick={handleRomoveDosarFilter}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date From"
            views={["year", "month", "day"]}
            value={datePicked}
            onChange={(newValue) => {
              setDatePicked(newValue);
            }}
            format="DD-MM-YYYY"
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date To"
            views={["year", "month", "day"]}
            value={datePickedTo}
            onChange={(newValue) => {
              setDatePickedTo(newValue);
            }}
            format="DD-MM-YYYY"
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>

        <Button onClick={handleApplyFilter}>Apply Filter</Button>
        {(filterAgent ||
          filterClient ||
          filterDosar ||
          datePicked ||
          datePickedTo) && (
          <Button onClick={handleClearFilters}>Clear Filters</Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FilterModal;
