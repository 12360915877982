import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar, roRO } from "@mui/x-data-grid";
import axios from "axios";

import {
  getNewsletterData,
  sendNewsletterEmails,
} from "../../../Actions/Newsletters/Newsletters";
import { getTransportatori } from "../../../Actions/Transportatori/Transportatori";
import Loader from "../../../Helpers/Loader";
import axiosInstance from "../../../Helpers/axiosInstance";
import pathAPI from "../../../pathAPI";

const Newsletters = () => {
  const data = useSelector((state) => state.newsletter.data);
  const dispatch = useDispatch();
  const [sendingEmails, setSendingEmails] = useState(false);

  useEffect(() => {
    dispatch(getNewsletterData());
    dispatch(getTransportatori());
  }, [dispatch]);

  const COLUMN_MAPPING = {
    tip_transport: "Tip transport",
    numar_dosar: "Numar dosar",
    specificatie_marfa: "Specificatie Marfa",
    localitate_plecare: "Localitate Plecare",
    localitate_sosire: "Localitate Sosire ",
    tara_incarcare: "Tara Incarcare",
    tara_descarcare: "Tara Descarcare",
    ambalaj: "Ambalaj",
    min_data_incarcare: "Min Data Incarcare",
    max_data_descarcare: "Max Data Descarcare",
    user_fullname: "User",
    price: "Pret",
  };

  const columns = Object.keys(COLUMN_MAPPING).map((field) => ({
    field: field,
    headerName: COLUMN_MAPPING[field],
    flex: 1,
    editable: field === "price",
  }));
  const handleSendEmails = () => {
    setSendingEmails(true);
    dispatch(sendNewsletterEmails())
      .then(() => {
        setSendingEmails(false);
      })
      .catch(() => {
        setSendingEmails(false);
      });
  };

  const processRowUpdate = async (newRow) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/newsletter_comenzi/updatePrice`,
        newRow
      );
      console.log("Update response:", response.data);
      return newRow;
    } catch (error) {
      console.error("Update failed:", error);
      throw new Error("Update failed!");
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleSendEmails}>
        Send Emails
      </Button>

      {data ? (
        <DataGrid
          localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
          rows={data}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          editMode="row"
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) =>
            console.error("Row update error:", error)
          }
          initialState={{
            pagination: { pageSize: 5 },
          }}
          pageSize={5}
          pageSizeOptions={[5, 10, 25]}
          autoHeight
        />
      ) : (
        <div>No data available</div>
      )}
      <Loader loading={sendingEmails} />
    </div>
  );
};

export default Newsletters;
