import React, { useState } from "react";
import pathAPI from "../../pathAPI";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Loader from "../../Helpers/Loader";

import { Button, TableFooter } from "@mui/material";
import axiosAuth from "../../Helpers/axiosAuth";
const CSVInput = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [uniqueValues, setUniqueValues] = useState([]);
  const [multiDocuments, setMultipleDocuments] = useState([]);
  const [errorDoc, setErrorDoc] = useState([]);

  const [loader, setLoader] = useState(false);

  const handleFileChange1 = (event) => {
    const selectedFile = event.target.files[0];
    setFile1(selectedFile);
  };

  const handleFileChange2 = (event) => {
    const selectedFile = event.target.files[0];
    setFile2(selectedFile);
  };

  const handleUpload = () => {
    if (file1 && file2) {
      const formData = new FormData();
      formData.append("csvFile1", file1);
      formData.append("csvFile2", file2);

      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      formData.append("token", token);
      formData.append("email", email);
      setLoader(true);
      axiosAuth
        .post("/dashboard/csvCompare/insertCSVData/", formData, config)
        .then((response) => {
          // Handle success response

          // Extract uniqueValues from the response
          if (Array.isArray(response.data) && response.data.length >= 3) {
            const uniqueValuesObject = response.data[2];
            const multipleDocuments = response.data[3];
            const documentWithErrors = response.data[4];
            if (multipleDocuments.multipleDoc) {
              const multiDocuments = multipleDocuments.multipleDoc;
              setMultipleDocuments(multiDocuments);
            }
            if (uniqueValuesObject.uniqueValues) {
              const uniqueValues = uniqueValuesObject.uniqueValues;
              setUniqueValues(uniqueValues);
            }
            if (documentWithErrors.errorDoc) {
              const errorDoc = documentWithErrors.errorDoc;
              setErrorDoc(errorDoc);
            }
          }
          setLoader(false);
        })
        .catch((error) => {
          // Handle error response
          console.error(error);
        });
    } else {
      console.log("Please select both files.");
    }
  };

  return (
    <>
      <div className="box">
        <div class="boxHeader">
          <h2>Compare Files</h2>
        </div>
        <div class="boxContent">
          <Box sx={{ display: "flex" }}>
            <Box
              htmlFor="file-input1"
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                backgroundColor: "#5e636a52",
                justifyContent: "center",
                cursor: "pointer",
                position: "relative",
                padding: "20px",
                borderRadius: "4px",
              }}
            >
              <p style={{ color: "white" }}>Cristal File</p>
              <Box
                sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
              >
                <FileUploadIcon
                  sx={{
                    ml: "10px",
                    color: "white",
                    fontSize: 60,
                    margin: "0 auto",
                  }}
                />
                <h4 style={{ margin: "0 auto" }}>
                  Drag & Drop / Click to Upload File
                </h4>
              </Box>
              <input
                id="file-input1"
                type="file"
                onChange={handleFileChange1}
                style={{
                  opacity: 0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
              {file1 && (
                <p
                  style={{
                    marginTop: "0.5rem",
                    fontWeight: 800,
                  }}
                >
                  {file1.name}
                </p>
              )}
            </Box>
            <Box
              htmlFor="file-input2"
              sx={{
                marginLeft: "5px",
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                backgroundColor: "#5e636a52",
                justifyContent: "center",
                cursor: "pointer",
                position: "relative",
                padding: "20px",
                borderRadius: "4px",
              }}
            >
              <p style={{ color: "white" }}>Getex File</p>
              <Box
                sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
              >
                <FileUploadIcon
                  sx={{
                    ml: "10px",
                    color: "white",
                    fontSize: 60,
                    margin: "0 auto",
                  }}
                />
                <h4 style={{ margin: "0 auto" }}>
                  Drag & Drop / Click to Upload File
                </h4>
              </Box>
              <input
                id="file-input2"
                type="file"
                onChange={handleFileChange2}
                style={{
                  opacity: 0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
              {file2 && (
                <p
                  style={{
                    color: "#rgb(0, 255, 200)",
                    marginTop: "0.5rem",
                    fontWeight: 800,
                  }}
                >
                  {file2.name}
                </p>
              )}
            </Box>
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            <Button
              sx={{ marginBottom: "1rem" }}
              onClick={handleUpload}
              variant="contained"
            >
              Compare
            </Button>
            <TableContainer component={Paper}>
              <Table className="optionControlTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Nr Document</TableCell>
                    <TableCell>Suma</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uniqueValues !== null &&
                    uniqueValues.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.column1}</TableCell>
                        <TableCell>{item.column2}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>

                <TableFooter></TableFooter>
              </Table>
            </TableContainer>
            <h3>Documente Dublate</h3>
            <TableContainer component={Paper}>
              <Table className="optionControlTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Nr Document</TableCell>
                    <TableCell>Duplicare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {multiDocuments !== null &&
                    multiDocuments.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.column1}</TableCell>
                        <TableCell>{item.count}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>

                <TableFooter></TableFooter>
              </Table>
            </TableContainer>
            <h3>Documente Eronate</h3>
            <TableContainer component={Paper}>
              <Table className="optionControlTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Nr Document</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorDoc !== null &&
                    errorDoc.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.column1}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>

                <TableFooter></TableFooter>
              </Table>
            </TableContainer>
            <Loader loading={loader} />
          </Box>
        </div>
      </div>
    </>
  );
};

export default CSVInput;
