import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Button } from "@mui/material";
import { Dialog, DialogTitle, DialogActions } from "@mui/material";
import EmailStructureDialog from "../../../Components/EmailStructure/EmailStructurePage";
import axiosAuth from "../../../Helpers/axiosAuth";
const dummyData = {
  dontNeedToVote: [],
  needToVote: [],

  voted: [],
  uncompletedVoted: [
    { id: "uncompleted-1", content: "Email 5 (Uncompleted Voted)" },
    { id: "uncompleted-2", content: "Email 6 (Uncompleted Voted)" },
  ],
};

const EmailDND = ({ payload }) => {
  const [data, setData] = useState(dummyData);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogKey, setDialogKey] = useState(null);
  const [isEmailEditDialogOpen, setIsEmailEditDialogOpen] = useState(false);

  const closeEditEmailDialog = () => {
    setIsEmailEditDialogOpen(false);
  };

  useEffect(() => {
    axiosAuth
      .post("dashboard/surveys/getEmailListsForSurvey", payload)
      .then((response) => {
        const updatedData = {
          ...data,
          voted: response.data.fully_voted.map((email, index) => ({
            id: `voted-${index}`,
            content: email,
          })),
          needToVote: response.data.not_voted.map((email, index) => ({
            id: `needToVote-${index}`,
            content: email,
          })),
          uncompletedVoted: response.data.partially_voted.map(
            (email, index) => ({
              id: `uncompleted-${index}`,
              content: email,
            })
          ),
        };

        setData(updatedData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      const items = [...data[source.droppableId]];
      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);

      const updatedData = {
        ...data,
        [source.droppableId]: items,
      };

      setData(updatedData);
    } else {
      const sourceItems = [...data[source.droppableId]];
      const destinationItems = [...data[destination.droppableId]];
      const [movedItem] = sourceItems.splice(source.index, 1);
      destinationItems.splice(destination.index, 0, movedItem);

      const updatedData = {
        ...data,
        [source.droppableId]: sourceItems,
        [destination.droppableId]: destinationItems,
      };

      setData(updatedData);
    }
  };
  const sendEmail = (emailAddresses, emailType) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    const postData = {
      token,
      email,
      emailType,
      //   emailMessage,
      //   emailSubject,
      emailAddresses,
      surveyNumber: payload.surveyNumber,
    };

    axiosAuth
      .post("dashboard/surveys/sendEmailCustomList", postData)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };
  const handleLogNeedToVote = () => {
    const emailType = "needToVote";

    const needToVoteContents = data.needToVote.map((item) => item.content);
    console.log(needToVoteContents);
    sendEmail(needToVoteContents, emailType);
  };

  const handleUncompletedVote = () => {
    const emailType = "uncompleted";
    const uncompletedVoteContents = data.uncompletedVoted.map(
      (item) => item.content
    );
    sendEmail(uncompletedVoteContents, emailType);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <h1>Email Drag and Drop</h1>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="zones-container">
          {Object.keys(data).map((key) => {
            let label = "";
            switch (key) {
              case "voted":
                label = "Votat";
                break;
              case "needToVote":
                label = "Trebuie sa voteze";
                break;
              case "uncompletedVoted":
                label = "Voturi incomplete";
                break;
              case "dontNeedToVote":
                label = "Nu trebuie sa voteze";
                break;
              default:
                break;
            }

            const isDragDisabled =
              key !== "needToVote" && key !== "dontNeedToVote";

            return (
              <div key={key}>
                <h2>{label}</h2>

                <Droppable
                  isDropDisabled={isDragDisabled}
                  key={key}
                  droppableId={key}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={`zone ${
                        snapshot.isDraggingOver ? "dragging-over" : ""
                      }`}
                    >
                      {data[key].map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`email-item ${
                                snapshot.isDragging ? "dragging" : ""
                              }`}
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <>
                  <EmailStructureDialog
                    open={isEmailEditDialogOpen}
                    onClose={closeEditEmailDialog}
                  />
                  <div className="sendEmailButtons">
                    {key === "needToVote" && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setDialogKey(key);
                          handleOpenDialog();
                        }}
                      >
                        TRIMITE EMAIL
                      </Button>
                    )}
                    {key === "uncompletedVoted" && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setDialogKey(key);
                          handleOpenDialog();
                          // setIsEmailEditDialogOpen(true);
                        }}
                      >
                        TRIMITE EMAIL
                      </Button>
                    )}

                    <Dialog
                      open={dialogOpen}
                      onClose={handleCloseDialog}
                      fullWidth={true}
                    >
                      <DialogTitle>Confirma trimitea de emailuri !</DialogTitle>

                      <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            handleCloseDialog();
                            if (dialogKey === "needToVote") {
                              handleLogNeedToVote();
                            } else if (dialogKey === "uncompletedVoted") {
                              handleUncompletedVote();
                            }
                          }}
                          color="primary"
                        >
                          Send Email
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </>
              </div>
            );
          })}
        </div>
      </DragDropContext>
    </div>
  );
};

export default EmailDND;
