import React, { useEffect, useState } from "react";

import MuiXDataGrid from "../../Components/Table/DataGrid";
import axiosAuth from "../../Helpers/axiosAuth";

const Praguri2 = () => {
  const [praguri, setPraguri] = useState([]);

  const getData = () => {
    axiosAuth
      .post("/dashboard/salarizare/getAllFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "SALARIZARE_PRAGURI_BETA",
      })
      .then(function (response) {
        setPraguri(response.data.SALARIZARE_PRAGURI_BETA);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <MuiXDataGrid
        columns={[
          {
            field: "prag",
            headerName: "Prag",
            flex: 2,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "baza",
            headerName: "Baza",
            flex: 2,
          },
          {
            field: "comision",
            headerName: "Comision",
            flex: 1,
          },
          {
            field: "total",
            headerName: "Total",
            flex: 1,
          },
        ]}
        rows={praguri}
      />
    </>
  );
};
export default Praguri2;
