import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExportToCsv from "../Penalizari/ExportToCsv";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import axiosAuth from "../../Helpers/axiosAuth";

const Centralizare = () => {
  const [centralizareSalarii, setCentralizareSalarii] = useState([]);
  const [datePicked, setDatePicked] = useState(dayjs().format("YYYY-MM-DD"));
  const [checkboxState, setCheckboxState] = useState(() => {
    const storedState = localStorage.getItem("checkboxState");
    return storedState ? JSON.parse(storedState) : {};
  });

  const handleCheckboxChange = (name) => {
    setCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: !prevCheckboxState[name],
    }));

    localStorage.setItem(
      "checkboxState",
      JSON.stringify({
        ...checkboxState,
        [name]: !checkboxState[name],
      })
    );
  };
  const getCentralizareSalarii = () => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");

    axiosAuth
      .post("/dashboard/salarizare/getCentralizareSalarii/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
      })
      .then(function (response) {
        setCentralizareSalarii(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const updateCorectie = (newValue, user_id, fullname, index, name) => {
    const value = newValue;
    const updatedCorectie = [...centralizareSalarii];
    // var total_net = updatedCorectie[index].salariu_net;

    // if (name == 'corectie') {
    //     total_net = value != '' ? parseFloat(updatedCorectie[index].total_net) + parseFloat(value) : updatedCorectie[index].total_net - updatedCorectie[index].corectie;
    // }

    // if (name == 'bonus') {
    //     total_net = value != '' ? parseFloat(updatedCorectie[index].total_net) + parseFloat(value) : updatedCorectie[index].total_net - updatedCorectie[index].bonus;
    // }

    // if (name == 'avans') {
    //     total_net = value != '' ? parseFloat(updatedCorectie[index].total_net) + parseFloat(value) : updatedCorectie[index].total_net - updatedCorectie[index].avans;
    // }

    // if (name == 'incasari_sistem_vechi') {
    //     total_net = value != '' ? parseFloat(updatedCorectie[index].total_net) + parseFloat(value) : updatedCorectie[index].total_net - updatedCorectie[index].incasari_sistem_vechi;
    // }

    updatedCorectie[index] = {
      ...updatedCorectie[index],
      [name]: value !== "" ? value : null,
    };

    setCentralizareSalarii(updatedCorectie);

    axiosAuth
      .post("/dashboard/salarizare/updateCorectie/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: datePicked,
        user_id: user_id,
        fullname: fullname,
        corectie: updatedCorectie[index].corectie,
        avans: updatedCorectie[index].avans,
        bonus: updatedCorectie[index].bonus,
        incasari_sistem_vechi: updatedCorectie[index].incasari_sistem_vechi,
        mentiuni: updatedCorectie[index].mentiuni,
      })
      .then(function () {})
      .catch(function () {
        console.log("Request failed.");
      });
  };

  // const handleInput = (event, index) => {
  //     const target = event.target;
  //     const value = target.textContent;
  //     const upCor = [...centralizareSalarii];
  //     var total_net = upCor[index].total_net;
  //     total_net = value ? parseFloat(value) + parseFloat(total_net) : total_net;
  //     console.log(value, total_net, value ? parseFloat(value) + parseFloat(total_net) : total_net)

  //     var total_net = (parseFloat(upCor[index].salariu_net) || 0) + (parseFloat(upCor[index].incasari_sistem_vechi) || 0) + (parseFloat(upCor[index].bonus) || 0) + (parseFloat(upCor[index].avans) || 0) + (parseFloat(upCor[index].corectie) || 0);

  //     upCor[index] = {
  //         ...upCor[index],
  //         "total_net": total_net
  //     };

  //     setCentralizareSalarii(upCor);
  // }

  const handleChangeValues = (index, newValue, field) => {
    if (field !== "mentiuni") {
      const value = newValue === "-" ? newValue : parseFloat(newValue) || 0;

      setCentralizareSalarii((prevState) => {
        const newState = [...prevState];
        newState[index][field] = value;
        if (value !== "-") {
          newState[index].total_net = calculateTotal(
            index,
            field,
            value,
            newState
          );
        }
        return newState;
      });
    } else {
      const value = newValue || "";

      setCentralizareSalarii((prevState) => {
        const newState = [...prevState];
        newState[index][field] = value;
        return newState;
      });
    }
  };

  const calculateTotal = (index, currentField, currentValue, state) => {
    return [
      "salariu_net",
      "corectie",
      "avans",
      "bonus",
      "incasari_sistem_vechi",
    ].reduce((sum, key) => {
      let value;

      // If this is the field we're currently editing, use the new value
      if (key === currentField) {
        value = currentValue;
      } else {
        value = parseFloat(state[index][key]) || 0;
      }

      return sum + value;
    }, 0);
  };

  useEffect(() => {
    getCentralizareSalarii();
  }, [datePicked]);

  return (
    <>
      <div className="containerInputs">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Luna / anul"
            views={["year", "month"]}
            value={datePicked}
            onChange={(newValue) => {
              localStorage.removeItem("checkboxState");
              setCheckboxState({});

              setDatePicked(newValue);
            }}
            format="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField size="small" {...params} required />
            )}
          />
        </LocalizationProvider>
        <ExportToCsv
          data={centralizareSalarii}
          headers={[
            "Nume",
            "User ID",
            "Salariu net",
            "Tip",
            "Corectie",
            "Mentiuni",
            "Avans",
            "Bonus",
            "Incasari s.vechi",
            "Total",
          ]}
          filename={"centralizare" + datePicked}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="Centralizare salarii">
          <TableHead>
            <TableRow>
              <TableCell>Nume</TableCell>
              <TableCell>Salariu net</TableCell>
              <TableCell>Corectie</TableCell>
              <TableCell>Avans</TableCell>
              <TableCell>Bonus</TableCell>
              <TableCell>Incasari s.vechi</TableCell>
              <TableCell>Mentiuni</TableCell>
              <TableCell>Total net</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {centralizareSalarii.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>
                  <span
                    className={
                      "circleSpan " +
                      (row.Type === 0 ? "agentCircle" : "tesaCircle")
                    }
                  >
                    <input
                      type="checkbox"
                      checked={checkboxState[row.name] || ""}
                      onChange={() => handleCheckboxChange(row.name)}
                    />
                    <span></span>
                    {row.name}
                  </span>
                </TableCell>
                <TableCell>{row.salariu_net}</TableCell>
                <TableCell>
                  <input
                    className="simpleInputStyle"
                    onChange={(e) =>
                      handleChangeValues(index, e.target.value, "corectie")
                    }
                    onBlur={(e) =>
                      updateCorectie(
                        e.target.value,
                        row.user_id,
                        row.name,
                        index,
                        "corectie"
                      )
                    }
                    type="text"
                    value={row.corectie ? row.corectie : ""}
                  ></input>
                </TableCell>
                <TableCell>
                  <input
                    className="simpleInputStyle"
                    onChange={(e) =>
                      handleChangeValues(index, e.target.value, "avans")
                    }
                    onBlur={(e) =>
                      updateCorectie(
                        e.target.value,
                        row.user_id,
                        row.name,
                        index,
                        "avans"
                      )
                    }
                    type="text"
                    value={row.avans ? row.avans : ""}
                  ></input>
                </TableCell>
                <TableCell>
                  <input
                    className="simpleInputStyle"
                    onChange={(e) =>
                      handleChangeValues(index, e.target.value, "bonus")
                    }
                    onBlur={(e) =>
                      updateCorectie(
                        e.target.value,
                        row.user_id,
                        row.name,
                        index,
                        "bonus"
                      )
                    }
                    type="text"
                    value={row.bonus ? row.bonus : ""}
                  ></input>
                </TableCell>
                <TableCell>
                  <input
                    className="simpleInputStyle"
                    onChange={(e) =>
                      handleChangeValues(
                        index,
                        e.target.value,
                        "incasari_sistem_vechi"
                      )
                    }
                    onBlur={(e) =>
                      updateCorectie(
                        e.target.value,
                        row.user_id,
                        row.name,
                        index,
                        "incasari_sistem_vechi"
                      )
                    }
                    type="text"
                    value={
                      row.incasari_sistem_vechi ? row.incasari_sistem_vechi : ""
                    }
                  ></input>
                </TableCell>
                <TableCell>
                  <textarea
                    className="simpleInputStyle simpleTextArea"
                    rows="1"
                    onChange={(e) =>
                      handleChangeValues(index, e.target.value, "mentiuni")
                    }
                    onBlur={(e) =>
                      updateCorectie(
                        e.target.value,
                        row.user_id,
                        row.name,
                        index,
                        "mentiuni"
                      )
                    }
                    type="text"
                    value={row.mentiuni ? row.mentiuni : ""}
                  ></textarea>
                </TableCell>
                <TableCell>{row.total_net}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="infoTable">
        <span>Rezultate: {centralizareSalarii.length}</span>
      </div>
    </>
  );
};
export default Centralizare;
