import React, { useState, useEffect } from "react";
import { TextField, Button, TableFooter, Divider } from "@mui/material";
import "./../Ticketing/Ticketing.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import axiosAuth from "../../Helpers/axiosAuth";
const PenalizariOptiuni = () => {
  const [dovezi, setDovezi] = useState([]);
  const [type, setType] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);

  const initialFormData = {
    doveziInsert: null,
    driverUpdate: null,
    tipInsert: null,
    tipUpdate: null,

    slaInsert: null,
    subjectsInsert: null,
    urgencyInsert: null,
    notif_TypeInsert: null,
    slaUpdate: null,
    subjectsUpdate: null,
    urgencyUpdate: null,
    notif_TypeUpdate: null,
    userInsert: null,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });

    console.log(formData);
  };

  const getAllUsers = () => {
    axiosAuth
      .post("/dashboard/ticketing/getUsersFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function () {})
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const dialogContent = (id, name, tableName) => {
    if (id && name) {
      setSelectedOption({ id: id, name: name, tableName: tableName });
    } else {
      setSelectedOption(null);
    }
    setFormData(initialFormData);
  };

  const insertNewValue = (tableName) => {
    if (formData[tableName + "Insert"]) {
      axiosAuth
        .post("/dashboard/penalizari/basicInsert/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          tableName: tableName.toUpperCase(),
          valueName: formData[tableName + "Insert"],
        })
        .then(function () {
          getControlDetails();
          setFormData({
            ...formData,
            doveziInsert: null,
            tipInsert: null,
          });
        })
        .catch(function () {});
    }
  };

  const updateValue = (tableName) => {
    if (formData[tableName + "Update"]) {
      setSelectedOption(null);
      axiosAuth
        .post("/dashboard/penalizari/basicUpdate/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          tableName: tableName.toUpperCase(),
          valueId: selectedOption.id,
          valueName: formData[tableName + "Update"],
        })
        .then(function () {
          getControlDetails();
        })
        .catch(function () {});
    }
  };

  const deleteValue = () => {
    setSelectedOption(null);
    setConfirmDialog(null);
    axiosAuth
      .post("/dashboard/penalizari/basicDelete/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableName: selectedOption.tableName.toUpperCase(),
        valueId: selectedOption.id,
      })
      .then(function () {
        getControlDetails();
      })
      .catch(function () {});
  };

  const getControlDetails = () => {
    axiosAuth
      .post("/dashboard/penalizari/getAllOptions/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "DOVEZI,TIP",
      })
      .then(function (response) {
        setDovezi(response.data.DOVEZI);
        setType(response.data.TIP);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    getControlDetails();
    getAllUsers();
  }, []);

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <TableContainer component={Paper}>
            <Table className="optionControlTable">
              <TableHead>
                <TableRow>
                  <TableCell>Dovezi</TableCell>
                  <TableCell>Editează</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dovezi !== null &&
                  dovezi.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            dialogContent(row.id, row.name, "dovezi")
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <TextField
                      size="small"
                      name="doveziInsert"
                      label="Valoare nouă"
                      value={formData.doveziInsert ?? ""}
                      onChange={handleInputChange}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => insertNewValue("dovezi")}
                      variant="contained"
                    >
                      Adaugă
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={4}>
          <TableContainer component={Paper}>
            <Table className="optionControlTable">
              <TableHead>
                <TableRow>
                  <TableCell>Tip penalizare</TableCell>
                  <TableCell>Editează</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {type !== null &&
                  type.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => dialogContent(row.id, row.name, "tip")}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <TextField
                      size="small"
                      name="tipInsert"
                      label="Valoare nouă"
                      value={formData.tipInsert ?? ""}
                      onChange={handleInputChange}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => insertNewValue("tip")}
                      variant="contained"
                    >
                      Adaugă
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog
        className="ticket-dialog"
        open={selectedOption !== null}
        onClose={() => dialogContent()}
      >
        {selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">{selectedOption.name}</p>
              <p className="ticket-number">{selectedOption.id}</p>
            </div>
            <div className="ticket-details">
              <TextField
                size="small"
                name={selectedOption.tableName + "Update"}
                className="input-dialog-control"
                label="Valoare nouă"
                value={
                  formData[selectedOption.tableName + "Update"] ??
                  selectedOption.name
                }
                onChange={handleInputChange}
              />
            </div>
            <div className="ticket-dialog-control">
              <Button
                onClick={() => updateValue(selectedOption.tableName)}
                variant="contained"
              >
                Salvează
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setConfirmDialog(selectedOption.name)}
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setSelectedOption(null)}
              >
                Anulează
              </Button>
            </div>
            <Divider />
          </div>
        )}
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={confirmDialog !== null}
        onClose={() => setConfirmDialog(null)}
      >
        {confirmDialog !== null && selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere valoare</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi valoarea: {confirmDialog}</p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteValue()}
              >
                Șterge
              </Button>
              <Button variant="outlined" onClick={() => setConfirmDialog(null)}>
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default PenalizariOptiuni;
