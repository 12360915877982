import {
  GET_ALL_TEAMS_SUCCESS,
  GET_ALL_TEAMS_FAILURE,
  GET_TEAM_BY_ID_SUCCESS,
  GET_TEAM_BY_ID_FAILURE,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILURE,
  ASSIGN_USER_TO_TEAM_SUCCESS,
  ASSIGN_USER_TO_TEAM_FAILURE,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  TEAMS_U_T_S,
  TEAMS_U_T_F,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  GET_ALL_DEPARTMENTS_SUCCESS,
  GET_ALL_DEPARTMENTS_FAILURE,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  GET_TEAM_BY_DEPARTMENT_SUCCESS,
  GET_TEAM_BY_DEPARTMENT_FAILURE,
} from "../../actionTypes";
import pathAPI from "../../../pathAPI";
import axiosInstance from "../../../Helpers/axiosInstance";

export const getTeamByIdSuccess = (team) => ({
  type: GET_TEAM_BY_ID_SUCCESS,
  payload: team,
});

export const getTeamByIdFailure = (error) => ({
  type: GET_TEAM_BY_ID_FAILURE,
  payload: error,
});

export const deleteMemberSuccess = () => ({
  type: DELETE_TEAM_MEMBER_SUCCESS,
});

export const deleteMemberFailure = (error) => ({
  type: DELETE_TEAM_MEMBER_FAILURE,
  payload: error,
});
export const assignUserToTeamSuccess = () => ({
  type: ASSIGN_USER_TO_TEAM_SUCCESS,
});

export const assignUserToTeamFailure = (error) => ({
  type: ASSIGN_USER_TO_TEAM_FAILURE,
  payload: error,
});
export const getAllRolesSuccess = (roles) => ({
  type: GET_ALL_ROLES_SUCCESS,
  payload: roles,
});

export const getAllRolesFailure = (error) => ({
  type: GET_ALL_ROLES_FAILURE,
  payload: error,
});

export const getAllTeamsSuccess = (teams) => ({
  type: GET_ALL_TEAMS_SUCCESS,
  payload: teams,
});

export const getAllTeamsFailure = (error) => ({
  type: GET_ALL_TEAMS_FAILURE,
  payload: error,
});
export const createTeamSuccess = () => ({
  type: CREATE_TEAM_SUCCESS,
});

export const createTeamFailure = (error) => ({
  type: CREATE_TEAM_FAILURE,
  payload: error,
});
export const updateTeamSuccess = () => ({
  type: TEAMS_U_T_S,
});

export const updateTeamFailure = (error) => ({
  type: TEAMS_U_T_F,
  payload: error,
});
export const deleteTeamSuccess = () => ({
  type: DELETE_TEAM_SUCCESS,
});

export const deleteTeamFailure = (error) => ({
  type: DELETE_TEAM_FAILURE,
  payload: error,
});
export const createRoleSuccess = () => ({
  type: CREATE_ROLE_SUCCESS,
});

export const createRoleFailure = (error) => ({
  type: CREATE_ROLE_FAILURE,
  payload: error,
});

export const updateRoleSuccess = () => ({
  type: UPDATE_ROLE_SUCCESS,
});

export const updateRoleFailure = (error) => ({
  type: UPDATE_ROLE_FAILURE,
  payload: error,
});

export const deleteRoleSuccess = () => ({
  type: DELETE_ROLE_SUCCESS,
});

export const deleteRoleFailure = (error) => ({
  type: DELETE_ROLE_FAILURE,
  payload: error,
});
// Actions for Departments
export const getAllDepartmentsSuccess = (departments) => ({
  type: GET_ALL_DEPARTMENTS_SUCCESS,
  payload: departments,
});

export const getAllDepartmentsFailure = (error) => ({
  type: GET_ALL_DEPARTMENTS_FAILURE,
  payload: error,
});

export const createDepartmentSuccess = () => ({
  type: CREATE_DEPARTMENT_SUCCESS,
});

export const createDepartmentFailure = (error) => ({
  type: CREATE_DEPARTMENT_FAILURE,
  payload: error,
});

export const updateDepartmentSuccess = () => ({
  type: UPDATE_DEPARTMENT_SUCCESS,
});

export const updateDepartmentFailure = (error) => ({
  type: UPDATE_DEPARTMENT_FAILURE,
  payload: error,
});

export const deleteDepartmentSuccess = () => ({
  type: DELETE_DEPARTMENT_SUCCESS,
});

export const deleteDepartmentFailure = (error) => ({
  type: DELETE_DEPARTMENT_FAILURE,
  payload: error,
});
export const getTeamsByDepartmentSuccess = (teams) => ({
  type: GET_TEAM_BY_DEPARTMENT_SUCCESS,
  payload: teams,
});

export const getTeamsByDepartmentFailure = (error) => ({
  type: GET_TEAM_BY_DEPARTMENT_FAILURE,
  payload: error,
});

export const getAllTeams = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/teams/getAllTeams`
      );
      dispatch(getAllTeamsSuccess(response.data));
    } catch (error) {
      dispatch(getAllTeamsFailure(error.message));
    }
  };
};
export const getTeamById = (teamId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`dashboard/teams/getTeamById`, {
        team_id: teamId,
      });
      dispatch(getTeamByIdSuccess(response.data));
    } catch (error) {
      dispatch(getTeamByIdFailure(error.message));
    }
  };
};

export const deleteMemberFromTeam = (teamId, userId) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`dashboard/teams/removeUserFromTeam`, {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        team_id: teamId,
        user_id: userId,
      });
      dispatch(deleteMemberSuccess());
    } catch (error) {
      dispatch(deleteMemberFailure(error.message));
    }
  };
};
export const assignUserToTeam = (teamId, userId, roleId) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`dashboard/teams/assignUserToTeam`, {
        team_id: teamId,
        user_id: userId,
        role_id: roleId,
      });
      dispatch(assignUserToTeamSuccess());
    } catch (error) {
      dispatch(assignUserToTeamFailure(error.message));
    }
  };
};
export const getAllRoles = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`dashboard/teams/getAllRoles`);
      dispatch(getAllRolesSuccess(response.data));
    } catch (error) {
      dispatch(getAllRolesFailure(error.message));
    }
  };
};
export const createTeam = (teamName, departmentId) => {
  return async (dispatch) => {
    try {
      console.log("teamname", teamName);
      await axiosInstance.post(`dashboard/teams/createTeam`, {
        team_name: teamName,
        department_id: departmentId,
      });
      dispatch(createTeamSuccess());
    } catch (error) {
      dispatch(createTeamFailure(error.message));
    }
  };
};
export const updateTeam = (teamId, new_team_name) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`dashboard/teams/updateTeam`, {
        team_id: teamId,
        new_team_name: new_team_name,
      });
      dispatch(updateTeamSuccess());
    } catch (error) {
      dispatch(updateTeamFailure(error.message));
    }
  };
};
export const deleteTeam = (teamId) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`dashboard/teams/deleteTeam`, {
        team_id: teamId,
      });
      dispatch(deleteTeamSuccess());
    } catch (error) {
      dispatch(deleteTeamFailure(error.message));
    }
  };
};
export const createRole = (roleName, roleLevel) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/teams/createRole`,
        {
          role_name: roleName,
          role_level: roleLevel,
        }
      );
      dispatch(createRoleSuccess(response.data));
    } catch (error) {
      dispatch(createRoleFailure(error.message));
    }
  };
};

export const updateRole = (roleId, newName, newLevel) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/teams/updateRole`,
        {
          role_id: roleId,
          new_name: newName,
          new_level: newLevel,
        }
      );
      dispatch(updateRoleSuccess(response.data));
    } catch (error) {
      dispatch(updateRoleFailure(error.message));
    }
  };
};

export const deleteRole = (roleId) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`${pathAPI}dashboard/teams/deleteRole`, {
        role_id: roleId,
      });
      dispatch(deleteRoleSuccess(roleId));
    } catch (error) {
      dispatch(deleteRoleFailure(error.message));
    }
  };
};
// Async Actions for Departments
export const getAllDepartments = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/teams/getAllDepartments`
      );
      dispatch(getAllDepartmentsSuccess(response.data));
    } catch (error) {
      dispatch(getAllDepartmentsFailure(error.message));
    }
  };
};

export const createDepartment = (departmentName) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`${pathAPI}dashboard/teams/createDepartment`, {
        department_name: departmentName,
      });
      dispatch(createDepartmentSuccess());
    } catch (error) {
      dispatch(createDepartmentFailure(error.message));
    }
  };
};

export const updateDepartment = (departmentId, newDepartmentName) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`${pathAPI}dashboard/teams/updateDepartment`, {
        department_id: departmentId,
        new_department_name: newDepartmentName,
      });
      dispatch(updateDepartmentSuccess());
    } catch (error) {
      dispatch(updateDepartmentFailure(error.message));
    }
  };
};

export const deleteDepartment = (departmentId) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`${pathAPI}dashboard/teams/deleteDepartment`, {
        department_id: departmentId,
      });
      dispatch(deleteDepartmentSuccess());
    } catch (error) {
      dispatch(deleteDepartmentFailure(error.message));
    }
  };
};
export const getTeamsByDepartment = (departmentId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/teams/getTeamsByDepartment`,
        {
          department_id: departmentId,
        }
      );
      dispatch(getTeamsByDepartmentSuccess(response.data));
    } catch (error) {
      dispatch(getTeamsByDepartmentFailure(error.message));
    }
  };
};
