import React, { useState } from "react";
import { TextField, IconButton, Button, Divider } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import EditIcon from "@mui/icons-material/Edit";
import GlobalAlert from "../../Helpers/Alert";
import axiosAuth from "../../Helpers/axiosAuth";

const filter = createFilterOptions();

const ClientAutocomplete = (props) => {
  const {
    clientiData,
    selectedClient,
    setSelectedClient,
    onSelectedOptionChange,
    getAllClients,
  } = props;
  const [open, toggleOpen] = React.useState(false);
  const [alertNok, setAlertNok] = React.useState(false);
  const [errorMessage, setErrorMessages] = React.useState("");
  const [succesMessage, setSuccesMessage] = React.useState("");
  const [openDeleteClient, setOpenDeleteClient] = React.useState(false);
  const [deleteOption, setDeleteOption] = React.useState(null);
  const initialFormData = {
    clientUpdate: null,
  };

  const [formData, setFormData] = useState(initialFormData);

  const [openEditClient, setOpenEditClient] = React.useState(false);
  const [editOption, setEditClient] = React.useState(null);
  const [dialogValue, setDialogValue] = React.useState({
    NAME: "",
  });
  const handleClose = () => {
    setDialogValue({
      NAME: "",
    });
    toggleOpen(false);
  };
  const handleDeleteOption = (option) => {
    setDeleteOption(option);
    setOpenDeleteClient(true);
  };
  const handleEditClient = (option) => {
    setEditClient(option);
    setOpenEditClient(true);
  };
  const DeleteCLient = (clientName) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const postData = {
      token: token,
      email: email,
      name: clientName,
    };
    axiosAuth
      .post("/dashboard/penalizari/clientDelete/", postData)
      .then((response) => {
        getAllClients();
        setOpenEditClient(false);
        setAlertNok(true);
        setSuccesMessage(response.data);
        setErrorMessages(null);
      })
      .catch((error) => {
        console.error(error);
      });

    setSelectedClient({
      NAME: "",
    });
    handleClose();
  };
  const handleClientSubmit = (event) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const postData = {
      token: token,
      email: email,
      name: dialogValue.NAME,
    };
    event.preventDefault();
    axiosAuth
      .post("/dashboard/penalizari/clientInsert/", postData)
      .then((response) => {
        //  getAllData();
        getAllClients();
      })
      .catch((error) => {
        console.error(error);
      });

    setSelectedClient({
      NAME: dialogValue.NAME,
    });
    handleClose();
  };
  const updateValue = (tableName) => {
    if (formData["clientUpdate"]) {
      //  setLoader(true);
      //  setSelectedOption(null);
      axiosAuth
        .post("/dashboard/penalizari/basicUpdate/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          tableName: tableName,
          valueId: editOption.ID,
          valueName: formData["clientUpdate"],
        })
        .then(function (response) {
          getAllClients();

          setAlertNok(true);
          setSuccesMessage(response.data);
          setErrorMessages(null);
        })
        .catch(function (error) {
          //  setLoader(false);
          //  setAlertNok(true);
        });
    }
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });

    console.log(formData);
  };
  return (
    <>
      <Autocomplete
        size="small"
        value={selectedClient}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              NAME: newValue.inputValue,
            });
          } else {
            setSelectedClient(newValue);
            onSelectedOptionChange(newValue.ID);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              NAME: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={clientiData}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.NAME;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props} style={{ display: "flex", alignItems: "center" }}>
            {option.NAME}
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
              }}
            >
              <IconButton
                onClick={() => handleEditClient(option)}
                edge="end"
                size="small"
              >
                <EditIcon />
              </IconButton>
            </div>
          </li>
        )}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Client" />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleClientSubmit}>
          <DialogTitle>Adauga un client Nou</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.NAME}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  NAME: event.target.value,
                })
              }
              label="Nume"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClientSubmit}>Add</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={openDeleteClient}
        onClose={() => setOpenDeleteClient(false)}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirma Stergerea</DialogTitle>
        <DialogContent>
          Esti sigur ca vrei sa stergi clientul : {deleteOption?.NAME}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteClient(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              DeleteCLient(deleteOption?.NAME);
              setOpenDeleteClient(false);
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={openEditClient}
        onClose={() => setOpenEditClient(false)}
      >
        {editOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">
                {"Editeaza clientul: " + editOption?.NAME}
              </p>
            </div>
            <div className="ticket-details">
              <TextField
                size="small"
                name="clientUpdate"
                className="input-dialog-control"
                label="Valoare nouă"
                value={formData["clientUpdate"] ?? editOption?.NAME}
                onChange={handleInputChange}
              />
            </div>
            <div className="ticket-dialog-control">
              <Button
                onClick={() => updateValue("clienti")}
                variant="contained"
              >
                Salvează
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleDeleteOption(editOption)}
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpenEditClient(false)}
              >
                Anulează
              </Button>
            </div>
            <Divider />
          </div>
        )}
      </Dialog>
      <GlobalAlert
        openAlert={alertNok}
        setOpenAlert={setAlertNok}
        color={succesMessage ? "success" : "error"}
        message={errorMessage || succesMessage}
      />
    </>
  );
};

export default ClientAutocomplete;
