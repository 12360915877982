import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import SurveyModal from "./SurveyModal"; // Import the SurveyModal component
import SurveyResultsModal from "./SurveyResultsModal"; // Import the SurveyModal component
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import OptiuniSurvey from "./OptiuniSurveys";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation } from "react-router-dom";
import axiosAuth from "../../../Helpers/axiosAuth";

function SurveysTable({ surveydata, handleFreshData }) {
  const location = useLocation();
  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles.includes(array[i])) {
        return true;
      }
    }
    return false;
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const surveyNumb = searchParams.get("survey");

    if (surveyNumb) {
      openModal(surveyNumb);
    }
  }, [location]);
  const [surveyNumber, setSurveyNumber] = useState(null);
  const [surveyResultsId, setSurveyResultsId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
  const [survesDetailsData, setSurveyDetailsData] = useState([]);
  const [selectedData, setSelectedData] = React.useState(null);

  const openModal = (surveyNumber) => {
    setSurveyNumber(surveyNumber.toString());
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSurveyNumber(null);
    setIsModalOpen(false);
  };

  const openResultsModal = (surveyResultsId) => {
    setSurveyResultsId(surveyResultsId);
    setIsResultsModalOpen(true);
  };

  const closeResultsModal = () => {
    setSurveyResultsId(null);
    setIsResultsModalOpen(false);
  };
  const getSurveyDetails = (surveyNumber) => {
    axiosAuth
      .post("dashboard/surveys/getSurveyDetails", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        surveyId: surveyNumber,
      })
      .then((res) => {
        setSurveyDetailsData(res.data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (surveyNumber !== null) {
      getSurveyDetails(surveyNumber);
    }
  }, [surveyNumber]);
  const handleEditSurvey = (data) => {
    setSelectedData(data);
  };
  const handleCloseEditSurvey = () => {
    setSelectedData(null);
    handleFreshData();
  };
  const isSurveyUpdated = () => {
    getSurveyDetails(surveyNumber);
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Titlu</TableCell>
              <TableCell>Descriere</TableCell>
              <TableCell align="center">Activ</TableCell>

              {checkRoles([999, 309]) ? (
                <>
                  <TableCell align="center">Raspunsuri</TableCell>
                  <TableCell align="center">Setari</TableCell>
                </>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {surveydata.map((survey, index) => (
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell
                  key={survey.id}
                  onClick={() => openModal(survey.survey_number)}
                  className="surveyTitleTable"
                >
                  {survey.title}
                </TableCell>
                <TableCell>
                  <span class="surveyDescriptionTable">
                    {survey.description}
                  </span>
                </TableCell>
                <TableCell>
                  {survey.is_active === "1" ? (
                    <div className="greenCircle">●</div>
                  ) : (
                    <div className="redCircle">●</div>
                  )}
                </TableCell>
                {checkRoles([999, 309]) ? (
                  <>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => openResultsModal(survey.survey_id)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleEditSurvey(survey)}>
                        <EditIcon />
                        {selectedData === survey && (
                          <OptiuniSurvey
                            data={selectedData}
                            onCloseDialog={handleCloseEditSurvey}
                          ></OptiuniSurvey>
                        )}
                      </IconButton>
                    </TableCell>
                  </>
                ) : (
                  ""
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isModalOpen && (
        <SurveyModal
          isOpen={isModalOpen}
          onClose={closeModal}
          surveyData={survesDetailsData}
          isSurveyUpdated={isSurveyUpdated}
          surveyNumber={surveyNumber}
        />
      )}
      {isResultsModalOpen && (
        <SurveyResultsModal
          isOpen={isResultsModalOpen}
          onClose={closeResultsModal}
          surveyId={surveyResultsId}
        />
      )}
    </div>
  );
}

export default SurveysTable;
