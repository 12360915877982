import React, { useState, useEffect } from "react";
import SurveysTable from "./components/SurveysTable";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CreateSurveyForm from "./components/CreateSurveyForm";
import Session from "./../../Components/Session.js";
import "./Survey.css";
import axiosAuth from "../../Helpers/axiosAuth.jsx";

const Survey = () => {
  var auth = false;
  if (Session([1])) {
    auth = true;
  }
  const [surveysData, setSurveysData] = useState([]);
  const [value, setValue] = React.useState("1");

  const getSurveys = () => {
    axiosAuth
      .post("dashboard/surveys/getSurveys", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        setSurveysData(res.data);
      })
      .catch((err) => console.error(err));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles.includes(array[i])) {
        return true;
      }
    }
    return false;
  }
  useEffect(() => {
    getSurveys();
  }, []);
  return (
    <>
      {auth ? (
        <div className="box">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Chestionare" value="1" />

                {checkRoles([999, 309]) ? (
                  <Tab label="Creeaza sondaj" value="2" />
                ) : (
                  ""
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              <SurveysTable
                surveydata={surveysData}
                handleFreshData={getSurveys}
              />
            </TabPanel>
            <TabPanel value="2">
              <CreateSurveyForm />
            </TabPanel>
          </TabContext>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Survey;
