import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  TextField,
  Button,
  TableFooter,
  Autocomplete,
  TablePagination,
} from "@mui/material";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ExportToCsv from "./ExportToCsv";
import Graph from "./Graph";
import GlobalAlert from "../../Helpers/Alert";
import ClientAutocomplete from "./ClientAutocomplete";
import EditIcon from "@mui/icons-material/Edit";
import MainOptiuni from "./MainOptiuni";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import axiosAuth from "../../Helpers/axiosAuth";

const Penalizari = (props) => {
  const {
    penalizariData,
    getAllData,
    agentiData,
    clientiData,
    dovezi,
    moneda,
    type,
    ticketsData,
    getAllClients,
  } = props;

  const [formData, setFormData] = useState(new FormData());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedType, setselectedType] = useState(null);
  const [selectedDovezi, setselectedDovezi] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [selectedTicket, setselectedTicket] = useState(null);
  const [selectedMoneda, setSelectedMoneda] = useState(null);
  const [selectedData, setSelectedData] = React.useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alertNok, setAlertNok] = useState(false);
  const [errorMessage, setErrorMessages] = useState("");
  const [succesMessage, setSuccesMessage] = useState("");

  const resetForm = () => {
    setselectedType(null);
    setSelectedClient(null);
    setselectedDovezi(null);
    setselectedTicket(null);
    setSelectedMoneda(null);
    setSelectedAgent(null);
    setSelectedDate(null);
    setFormData(new FormData());
    const textFields = document.querySelectorAll('input[type="text"]');
    textFields.forEach((textField) => {
      textField.value = "";
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleEditClient = (data) => {
    setSelectedData(data);
  };
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData =
    penalizariData !== null ? penalizariData.slice(startIndex, endIndex) : [];

  React.useEffect(() => {
    // getAgenti();
  }, []);
  const handleTipChange = (event, value) => {
    setselectedType(value);
    formData.set("tipPenalizare", value);
  };
  const handleDoveziChange = (event, value) => {
    setselectedDovezi(value);
    formData.set("dovezi", value);
  };
  const handleAgentChange = (event, value) => {
    setSelectedAgent(value);
    formData.set("agent", value.name);
  };
  const handleMonedaChange = (event, value) => {
    setSelectedMoneda(value);
    formData.set("moneda", value);
  };
  const handleTicketChange = (event, value) => {
    setselectedTicket(value);
    formData.set("tichetNr", value);
  };
  const handleSelectedOption = (option) => {
    formData.set("client", option);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "dataDosar") {
      setSelectedDate(value);
    }
    formData.set(name, value);
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    formData.set("dataDosar", formattedDate);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    const formDataObject = Object.fromEntries(formData);

    const postData = {
      token: token,
      email: email,
      formData: formDataObject,
    };

    axiosAuth
      .post("/dashboard/penalizari/insertPenalizari/", postData)
      .then((response) => {
        getAllData();
        setAlertNok(true);
        resetForm();
        setSuccesMessage(response.data);
        setErrorMessages(null);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setAlertNok(true);
          setErrorMessages(error.response.data);
          setSuccesMessage(null);
        } else {
          console.error(error);
        }
      });
  };

  return (
    <>
      {" "}
      <TableContainer component={Paper}>
        <form onSubmit={handleSubmit}>
          <Table className="optionControlTable">
            <TableHead>
              <TableRow>
                <TableCell>Tip Penalizare</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Furnizor</TableCell>
                <TableCell>Dosar</TableCell>
                <TableCell>Data Dosar</TableCell>
                <TableCell>Suma Penalizare</TableCell>
                <TableCell>Moneda</TableCell>
                <TableCell>Dovezi</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell>Rezolvare</TableCell>
                <TableCell>Add Date</TableCell>
                <TableCell>Ticket #</TableCell>
                <TableCell>Editeaza</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {penalizariData !== null &&
                paginatedData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.tipPenalizare}</TableCell>
                    <TableCell>{row.client}</TableCell>
                    <TableCell>{row.furnizor}</TableCell>
                    <TableCell>{row.dosar}</TableCell>
                    <TableCell>{row.dataDosar}</TableCell>
                    <TableCell>{row.sumaPenalizare}</TableCell>
                    <TableCell>{row.moneda}</TableCell>
                    <TableCell>{row.dovezi}</TableCell>
                    <TableCell>{row.agent}</TableCell>
                    <TableCell>{row.rezolvare}</TableCell>
                    <TableCell>{row.addDate}</TableCell>
                    <TableCell>
                      {row.tichetNr !== "N/A" ? (
                        <a
                          style={{ color: "black", fontWeight: "700" }}
                          href={
                            window.location.origin +
                            "/ticketing/?ticket=" +
                            row.tichetNr
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row.tichetNr}
                        </a>
                      ) : (
                        <p>{row.tichetNr}</p>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditClient(row)}>
                        <EditIcon />
                      </IconButton>
                      {selectedData === row && (
                        <MainOptiuni
                          data={selectedData}
                          tableName={"PENALIZARI"}
                          agentiData={agentiData}
                          clientiData={clientiData}
                          moneda={moneda}
                          onCloseDialog={handleEditClient}
                          ticketsData={ticketsData}
                          getAllData={getAllData}
                          tipPenalizare={type}
                          dovezi={dovezi}
                        ></MainOptiuni>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Autocomplete
                    size="small"
                    options={type.map((tip) => tip.name)}
                    value={selectedType}
                    onChange={handleTipChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Tip Penalizare"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <ClientAutocomplete
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    getAllData={getAllData}
                    clientiData={clientiData}
                    onSelectedOptionChange={handleSelectedOption}
                    getAllClients={getAllClients}
                  ></ClientAutocomplete>
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    size="small"
                    label="Furnizor"
                    name="furnizor"
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    size="small"
                    label="Dosar"
                    name="dosar"
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Data Dosar"
                      value={selectedDate}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    size="small"
                    label="Suma Penalizare"
                    name="sumaPenalizare"
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Autocomplete
                    size="small"
                    options={moneda.map((moneda) => moneda.name)}
                    value={selectedMoneda}
                    onChange={handleMonedaChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Moneda"
                        variant="outlined"
                        required
                      />
                    )}
                  />{" "}
                </TableCell>
                <TableCell colSpan={2}>
                  <Autocomplete
                    size="small"
                    options={dovezi.map((dovezi) => dovezi.name)}
                    value={selectedDovezi}
                    onChange={handleDoveziChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Dovezi"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  {
                    <Autocomplete
                      size="small"
                      options={_.uniqBy(agentiData, "name")}
                      groupBy={(option) => option.type}
                      getOptionLabel={(option) => option.name}
                      value={selectedAgent}
                      onChange={handleAgentChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Agent"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  }
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    size="small"
                    label="Rezolvare"
                    name="rezolvare"
                    onChange={handleChange}
                  />{" "}
                </TableCell>
                <TableCell colSpan={2}>
                  <Autocomplete
                    size="small"
                    options={[
                      "N/A",
                      ...ticketsData.map((ticket) => ticket.ticket_number),
                    ]}
                    value={selectedTicket}
                    onChange={handleTicketChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Ticket#"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <Button type="submit" variant="contained">
                    Adaugă
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <GlobalAlert
            openAlert={alertNok}
            setOpenAlert={setAlertNok}
            color={succesMessage ? "success" : "error"}
            message={errorMessage || succesMessage}
          />
        </form>
        <ExportToCsv
          data={paginatedData}
          headers={[
            "id'",
            "Tip Penalizare",
            "Client",
            "Furnizor",
            "Dosar",
            "Data Dosar",
            "Suma Penalizare",
            "Moneda",
            "Dovezi",
            "Agent",
            "Rezolvare",
            "Tichet#",
            "Add Date",
          ]}
          filename="penalizari"
        />

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, { label: "All", value: -1 }]}
          colSpan={12}
          count={penalizariData !== null ? penalizariData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Graph penalizariData={paginatedData} />
    </>
  );
};

export default Penalizari;
