import {
  GET_TESA_NEW_SUCCES,
  GET_TESA_NEW_FAILURE,
  UPDATE_TESA_NEW_SUCCESS,
  UPDATE_TESA_NEW_FAILURE,
  DELETE_TESA_NEW_SUCCESS,
  DELETE_TESA_NEW_FAILURE,
  GET_TESA_DATA_SUCCESS,
  GET_TESA_DATA_FAILURE,
  GET_TESA_HISTORY_SUCCESS,
  GET_TESA_HISTORY_FAILURE,
} from "../../Actions/actionTypes";

const initialState = {
  tesaData: null,
  error: null,
  userTesaDetails: null,
  userTesaHistoryDetails: null,
};

const tesaNewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TESA_NEW_SUCCES:
      return {
        ...state,
        tesaData: action.payload,
        error: null,
      };
    case GET_TESA_NEW_FAILURE:
      return {
        ...state,
        tesaData: null,
        error: action.payload,
      };
    case UPDATE_TESA_NEW_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case UPDATE_TESA_NEW_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_TESA_NEW_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case DELETE_TESA_NEW_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_TESA_DATA_SUCCESS:
      return {
        ...state,
        userTesaDetails: action.payload,
        error: null,
      };
    case GET_TESA_DATA_FAILURE:
      return {
        ...state,
        userTesaDetails: [],
        error: action.payload,
      };
    case GET_TESA_HISTORY_SUCCESS:
      return {
        ...state,
        userTesaHistoryDetails: action.payload,
        error: null,
      };
    case GET_TESA_HISTORY_FAILURE:
      return {
        ...state,
        userTesaHistoryDetails: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default tesaNewReducer;
