import React from "react";
import Chart from "react-apexcharts";

const Graph = ({ penalizariData }) => {
  // Check if penalizariData is defined
  if (!penalizariData) {
    return null;
  }

  // Calculate total Suma Penalizare by month and currency
  const data = penalizariData.reduce((acc, row) => {
    const month = new Date(row.dataDosar).getMonth();
    const currency = row.moneda;
    const sum = acc[month] || {};
    const currencySum = sum[currency] || 0;
    sum[currency] = currencySum + parseFloat(row.sumaPenalizare);
    acc[month] = sum;
    return acc;
  }, {});

  // Create an array of sorted month names
  const uniqueMonths = new Set(
    penalizariData.map((row) => new Date(row.dataDosar).getMonth())
  );
  const sortedMonths = Array.from(uniqueMonths).sort((a, b) => a - b);
  const monthNames = sortedMonths.map((monthIndex) => {
    const date = new Date();
    date.setMonth(monthIndex);
    return date.toLocaleString("en-US", { month: "long", year: "numeric" });
  });

  // Convert data object to arrays for chart series and categories
  const series = Object.keys(data).reduce(
    (result, monthIndex) => {
      const monthData = data[monthIndex];
      const totalRON = monthData.RON || 0;
      const totalEUR = monthData.EUR || 0;
      result.RON.push(totalRON);
      result.EUR.push(totalEUR);
      return result;
    },
    { RON: [], EUR: [] }
  );

  // Chart options
  const options = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: monthNames,
    },
    series: [
      {
        name: "Total Suma RON",
        data: series.RON,
      },
      {
        name: "Total Suma EUR",
        data: series.EUR,
      },
    ],
  };

  return (
    <div>
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={400}
      />
    </div>
  );
};

export default Graph;
