import AdaugaCurs from "./AdaugaCurs/AdaugaCurs";
import ControlCurs from "./ControlCurs/ControlCurs";
import React from "react";

export default function ControlOptiuni() {
  return (
    <div className="gridIsh">
      <AdaugaCurs />
      <ControlCurs />
    </div>
  );
}
