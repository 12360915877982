import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import "./Ticketing.css";
import "./../../App.css";
import DropZone from "./DropZone";
import Loader from "../../Helpers/Loader";
import GlobalAlert from "../../Helpers/Alert";
import MyEditor from "../../Components/DraftJsEditor/EditorDraft";

import { additionalFieldsConfig } from "./aditionalFieldsConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axiosAuth from "../../Helpers/axiosAuth";

const OpenTicket = ({ setSelectedTicketNumber, setValue }) => {
  const [users, setUsers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [teams, setTeams] = useState([]);
  const [usersToTeam, setUsersToTeam] = useState([]);
  const [urgency, setUrgency] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  // const fileInputRefResult = React.createRef();
  // const [fileResult,setFileResult] = useState([]);
  const fileInputRefNI = React.createRef();
  const [fileNI, setFileNI] = useState([]);
  const fileInputRefTicket = React.createRef();
  const [fileTicket, setFileTicket] = useState([]);
  const [alertUpload, setAlertUpload] = useState(false);
  const [loader, setLoader] = useState(false);
  const [alertNok, setAlertNok] = useState(false);

  const handleTextChange = (newText) => {
    setFormData({
      ...formData,
      ["reason"]: newText,
    });
  };
  const initialFormData = {
    userReported: null,
    cc: [],
    notificationType: null,
    source: null,
    subject: null,
    team: null,
    sla: null,
    // deadline: null,
    assignedUser: null,
    urgency: null,
    summary: null,
    reason: null,
    answear: null,
    status: null,
    note: null,
    numeFirma: null,
    emailFirma: null,
    nrComanda: null,
    DataDeInregistrare: null,
    NumarulDeInregistrare: null,
  };
  const [formData, setFormData] = useState(initialFormData);

  // For team rank not used

  // For team rank not used

  const handleSubmit = (event) => {
    setLoader(true);
    let params = new FormData();
    if (fileNI !== "" && fileNI !== null) {
      for (var i = 0; i < fileNI.length; i++) {
        for (var j = 0; j < fileNI[0].length; j++) {
          params.append(`Nota_interna-${i}`, fileNI[i][j]);
        }
      }
    }

    if (fileTicket !== "" && fileTicket !== null) {
      for (i = 0; i < fileTicket.length; i++) {
        for (j = 0; j < fileTicket[0].length; j++) {
          params.append(`Ticket-${i}`, fileTicket[i][j]);
        }
      }
    }
    params.append("token", localStorage.getItem("token"));
    params.append("email", localStorage.getItem("email"));
    params.append("userReported", formData.userReported.user_id);
    params.append("cc", formData.cc.map((item) => item.id).join(","));
    // params.append("notificationType", formData.notificationType.ID)
    // params.append("source", formData.source.ID)
    params.append("subject", formData.subject.ID);
    params.append("team", formData.team.ID);
    // params.append("sla", formData.sla.ID)
    // params.append("deadline", formData.deadline.$d.toISOString().slice(0, 19).replace('T', ' '))
    // params.append("assignedUser", formData.assignedUser.user_id)
    params.append("urgency", formData.urgency.ID);
    params.append("summary", formData.summary);
    params.append("reason", formData.reason);
    // params.append("answear", formData.answear)
    // params.append("status", formData.status.ID)
    params.append("note", formData.note);
    if (formData.team && formData.team.ID === "12") {
      // params.append("numeFirma", formData.numeFirma);
      params.append("EMAIL_FIRMA", formData.emailFirma);
      params.append("nrComanda", formData.nrComanda);
      params.append("DataDeInregistrare", formData.DataDeInregistrare);
      params.append("NumarulDeInregistrare", formData.NumarulDeInregistrare);
    }
    const additionalFields = additionalFieldsConfig[formData.subject.NAME];
    if (additionalFields) {
      additionalFields.forEach((field) => {
        params.append(field.name, formData[field.name]);
      });
    }
    axiosAuth
      .post("/dashboard/ticketing/openTicket/", params)
      .then(function (response) {
        let res = response.data.ticketResponse;
        if (res.length <= 15) {
          if (response.data.includes("Possible file upload attack") !== true) {
            setValue("1");
            setSelectedTicketNumber(response.data);
          } else {
            setAlertUpload(true);
          }
        } else {
          console.log("first else", response.data);
          if (response?.data.responseUpload) {
            const lastInsertedIds =
              response?.data?.responseUpload?.last_inserted_ids;
            const ticket_id = response.data?.ticketResponse?.ticket_id;
            let ticketFileType = "ticket";

            if (lastInsertedIds && lastInsertedIds.length > 0) {
              lastInsertedIds.forEach((fileId) => {
                const type = "file";
                handleSubmitInsertComment(
                  event,
                  type,
                  fileId,
                  ticket_id,
                  ticketFileType
                );
              });
            }
          }
          setValue("1");
          setSelectedTicketNumber(response.data?.ticketResponse?.ticket_number);
        }
        setLoader(false);
        // setFormData(initialFormData);
        // alert('Tichetul ' + response.data + ' a fost deschis cu succes!');
      })
      .catch(function () {
        setAlertNok(true);
        setLoader(false);
        console.log("Request failed.");
      });
  };

  // const handleDateChange = (date) => {
  //     setFormData({
  //         ...formData,
  //         deadline: date
  //     });
  // };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(target.value);

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDateChangeDetaliiJuridic = (newValue, name) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");

    setFormData({
      ...formData,
      [name]: formattedDate,
    });
  };

  const handleAutocompleteChange = (event, newValue, name) => {
    setFormData({
      ...formData,
      [name]: newValue,
    });
    if (name === "team") {
      setLoader(true);
      let params = new FormData();
      params.append("team", newValue.ID);
      axiosAuth
        .post("/dashboard/ticketing/getSubjects/", params)
        .then(function (response) {
          setSubjects(response.data);
          setLoader(false);
          // setFormData(initialFormData);
          // alert('Tichetul ' + response.data + ' a fost deschis cu succes!');
        })
        .catch(function () {
          setLoader(false);
          console.log("Request failed.");
        });
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const validateFile = (files) => {
    var ok = 1;
    for (var i = 0; i < files.length; i++) {
      if (
        [
          "image/jpeg",
          "image/png",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "application/pdf",
          "text/csv",
          "application/vnd.ms-excel",
          "message/rfc822",
        ].indexOf(files[i].type) === -1 &&
        files[i].name.substring(files[i].name.indexOf(".") + 1) !== "msg"
      ) {
        ok = 0;
      }
    }
    return ok === 1;
  };

  const filesSelected = (fileInputRef, file, setFile) => {
    if (fileInputRef.current.files.length) {
      if (validateFile(fileInputRef.current.files)) {
        setFile((file) => [...file, fileInputRef.current.files]);
      }
    }
  };
  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setFormData({
      ...formData,
      ["DataDeInregistrare"]: formattedDate, // Wrap property key in quotes
    });
  };

  useEffect(() => {
    axiosAuth
      .post("dashboard/ticketing/getAllFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "NOTIF_TYPE,SLA,SOURCE,STATUS,TEAMS,URGENCY",
      })
      .then(function (response) {
        setTeams(response.data.TEAMS);
        setUrgency(response.data.URGENCY);
      })
      .catch(function () {
        console.log("Request failed.");
      });
    axiosAuth
      .post("dashboard/ticketing/getUsersFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setUsers(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
    axiosAuth
      .post("dashboard/ticketing/getUsersToTeam/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setUsersToTeam(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  }, []);
  const handleSubmitInsertComment = (
    event,
    type,
    fileId,
    ticket_id,
    ticketFileType
  ) => {
    if (event) {
      event.preventDefault();
    }
    setLoader(true);

    let commentType = type;
    if (type !== "file") {
      commentType = "comment";
    }
    let commentToSend = formData.comment;
    if (type === "file") {
      commentToSend = fileId;
    }
    console.log(ticketFileType);
    if (ticketFileType === "ticket") {
      axiosAuth
        .post("/dashboard/ticketing/addComment/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          comment: commentToSend,
          ticketId: ticket_id,
          status: 1,
          type: commentType,
        })
        .then(function () {
          // writeUserData(ticketData.ticket_number);
          // setFormData(initialFormData);
          // getTicketDetails();
          // setLoader(false);
          // setAlertSucces(true);
          // getTickets();
        })
        .catch(function () {
          setLoader(false);
          console.log("Request failed.");
        });
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderAdditionalFields = (subject) => {
    return (
      <>
        <div className="dateFirmaContainer">
          {additionalFieldsConfig[subject].map((field, index) => (
            <React.Fragment key={index}>
              {field.type === "date" ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={field.label}
                    value={formData[field.name] ?? null}
                    onChange={(newValue) =>
                      handleDateChangeDetaliiJuridic(newValue, field.name)
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        size="small"
                        onInvalid={(F) =>
                          F.target.setCustomValidity("Câmpul este obligatoriu")
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              ) : (
                <TextField
                  key={index}
                  size="small"
                  name={field.name}
                  label={field.label}
                  placeholder={field.placeholder}
                  value={formData[field.name] ?? ""}
                  onChange={handleInputChange}
                  required
                  onInvalid={(F) =>
                    F.target.setCustomValidity("Câmpul este obligatoriu")
                  }
                  onInput={(F) => F.target.setCustomValidity("")}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </>
    );
  };
  return (
    <form
      className="ticketForm"
      onSubmit={(event) => {
        event.preventDefault(); // prevent page refresh
        setOpenConfirmation(true);
      }}
    >
      <h2>Utilizator și colaboratori</h2>
      <Autocomplete
        size="small"
        name="userReported"
        options={usersToTeam}
        value={formData.userReported}
        groupBy={(option) => option.team_name}
        getOptionLabel={(option) => option.email + " | " + option.user_name}
        onChange={(event, newValue) =>
          handleAutocompleteChange(event, newValue, "userReported")
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Utilizator raportat"
            variant="outlined"
            required
          />
        )}
      />
      {/* <Autocomplete
                size="small"
                name="userReported"
                id="userReported"
                options={users}
                value={formData.userReported}
                getOptionLabel={(user) => user.email}
                renderInput={(params) => <TextField {...params} label="Utilizator" />}
                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'userReported')}
            /> */}
      <Autocomplete
        size="small"
        multiple
        name="cc"
        id="tags-outlined"
        value={formData.cc}
        options={users}
        getOptionLabel={(user) => user.email + " | " + user.name}
        filterSelectedOptions
        required
        renderInput={(params) => (
          <TextField {...params} label="CC" placeholder="CC" />
        )}
        onChange={(event, newValue) =>
          handleAutocompleteChange(event, newValue, "cc")
        }
      />
      <Autocomplete
        size="small"
        name="team"
        id="team"
        options={teams}
        value={formData.team}
        getOptionLabel={(option) => option.NAME}
        renderInput={(params) => (
          <TextField {...params} label="Departament" required />
        )}
        onChange={(event, newValue) =>
          handleAutocompleteChange(event, newValue, "team")
        }
      />
      {/* nume firma, adresa firma, mail firma, nr comanda, data comanda */}
      {formData.team && formData.team.ID === "12" && (
        <>
          <div className="dateFirmaContainer">
            <TextField
              size="small"
              name="NumarulDeInregistrare"
              label="Nr. Comanda "
              placeholder="Nr. Comanda "
              value={formData.NumarulDeInregistrare ?? ""}
              onChange={handleInputChange}
              required
            />
            {/* <TextField
              size="small"
              name="DataDeInregistrare"
              label="Data comanda"
              placeholder="Data comanda"
              value={formData.DataDeInregistrare ?? ""}
              onChange={handleInputChange}
              required
            /> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data Dosar"
                value={formData.DataDeInregistrare}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    size="small"
                    onInvalid={(F) =>
                      F.target.setCustomValidity("Câmpul este obligatoriu")
                    }
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              size="small"
              name="emailFirma"
              label="E-mail Firma"
              placeholder="E-mail Firma"
              value={formData.emailFirma ?? ""}
              onChange={handleInputChange}
              required
            />
          </div>
        </>
      )}{" "}
      {/* <Autocomplete
                size="small"
                name="notificationType"
                id="notificationType"
                value={formData.notificationType}
                options={notifyType}
                getOptionLabel={(option) => option.NAME}
                renderInput={(params) => <TextField {...params} label="Notificare tichet" />}
                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'notificationType')}
            /> */}
      <h2>Informații și opțiuni tichet</h2>
      {/* <Autocomplete
                size="small"
                name="source"
                id="source"
                options={source}
                value={formData.source}
                getOptionLabel={(option) => option.NAME}
                renderInput={(params) => <TextField {...params} label="Sursa tichetului" />}
                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'source')}
            /> */}
      <Autocomplete
        size="small"
        name="subject"
        id="subject"
        options={subjects.filter((option) => option.visibility === "1")}
        value={formData.subject}
        getOptionLabel={(option) => option.NAME}
        renderInput={(params) => (
          <TextField {...params} label="Subiect" required />
        )}
        onChange={(event, newValue) => {
          handleAutocompleteChange(event, newValue, "subject");
          console.log("fuyfuf", formData);
        }}
      />
      {formData.subject &&
        formData.subject.NAME &&
        additionalFieldsConfig[formData.subject.NAME] &&
        renderAdditionalFields(formData.subject.NAME)}
      <div className="dropDiv">
        <Typography variant="h6">Ataseaza un fisier la tichet</Typography>
        <div className="dropZone">
          <DropZone
            setFile={setFileTicket}
            file={fileTicket}
            validateFile={validateFile}
            fileInputRef={fileInputRefTicket}
            filesSelected={filesSelected}
          />
        </div>
      </div>
      {/* <Autocomplete
                size="small"
                name="sla"
                id="sla"
                value={formData.sla}
                options={sla}
                getOptionLabel={(option) => option.NAME}
                renderInput={(params) => <TextField {...params} label="SLA" />}
                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'sla')}
            /> */}
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label="Dată scadentă"
                    value={formData.deadline}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                        <TextField size="small" {...params} />
                    )}
                />
            </LocalizationProvider> */}
      {/* <Autocomplete
                size="small"
                name="team"
                options={usersToTeam}
                value={formData.assignedUser}
                groupBy={(option) => option.team_name}
                getOptionLabel={(option) => option.user_name}
                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'assignedUser')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Alocă lui"
                        variant="outlined"
                    />
                )}
            /> */}
      <h2>Detalii tichet</h2>
      <TextField
        size="small"
        name="summary"
        label="Titlu problemă"
        placeholder="Nr. factura/Comanda + Titlu, Ex: 27546 Adresa incorecta"
        value={formData.summary ?? ""}
        onChange={handleInputChange}
        required
      />
      {/* <TextField
                size="small"
                name="reason"
                label="Mesaj"
                placeholder="Mesaj"
                multiline
                value={formData.reason ?? ''}
                onChange={handleInputChange}
                required
            /> */}
      <MyEditor showToolbar={true} onContentChange={handleTextChange} />
      {/* <DefaultQuillEditor onTextChange={handleTextChange} ref={quillRef} /> */}
      {/* <ReactQuill
        className="messageContainer"
        value={formData.reason ?? ""}
        onChange={(e) => {
          handleInputChangeMessage(e);
        }}
        placeholder="Mesaj"
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"],
            [{ color: [] }],
            ["emoji"], // Add the emoji toolbar option
          ],
          "emoji-toolbar": true, // Enable the emoji toolbar option
          "emoji-shortname": true, // Enable shortname to emoji conversion
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "color",

          "bullet",
          "emoji", // Add the emoji format
        ]}
      /> */}
      {/* upload zone pentru motiv cu drag and drop api.euroteamgb.com/uploads/ticketing*/}
      <Autocomplete
        size="small"
        name="urgency"
        id="urgency"
        options={urgency}
        value={formData.urgency}
        getOptionLabel={(option) => option.NAME}
        renderInput={(params) => (
          <TextField {...params} label="Nivel problemă" required />
        )}
        onChange={(event, newValue) =>
          handleAutocompleteChange(event, newValue, "urgency")
        }
      />
      {/* <div className='dropDiv'>
                <TextField
                    size="small"
                    name="answear"
                    label="Răspuns"
                    multiline
                    placeholder="Răspuns opțional pentru problema indicată mai sus"
                    value={formData.answear ?? ''}
                    onChange={handleInputChange}
                />
                <div className='dropZone'>
                    <DropZone setFile={setFileResult} file={fileResult} validateFile={validateFile} fileInputRef={fileInputRefResult}  filesSelected={filesSelected}/>
                </div>
            </div> */}
      {/* upload zone pentru answear cu drag and drop api.euroteamgb.com/uploads/ticketing*/}
      {/* <Autocomplete
                size="small"
                name="status"
                id="status"
                options={status}
                value={formData.status}
                getOptionLabel={(option) => option.NAME}
                renderInput={(params) => <TextField {...params} label="Stare tichet" />}
                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'status')}
            /> */}
      <div className="dropDiv">
        <TextField
          size="small"
          name="note"
          label="Notă internă"
          value={formData.note ?? ""}
          onChange={handleInputChange}
          multiline
        />
        <div className="dropZone">
          <DropZone
            setFile={setFileNI}
            file={fileNI}
            validateFile={validateFile}
            fileInputRef={fileInputRefNI}
            filesSelected={filesSelected}
          />
        </div>
      </div>
      <Button variant="contained" type="submit">
        Deschide
      </Button>
      {/* test notif SLA */}
      {/* <Button onClick = {()=>{
                axios.post(pathAPI + '/dashboard/ticketing/notificationSLA/', {
                    token: localStorage.getItem('token'),
                    email: localStorage.getItem('email')
                }).then(()=>{
                    console.log("ok")
                })
            }}>aaaa</Button> */}
      <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
        <DialogTitle>Confirmare</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirmati deschiderea tichetului?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseConfirmation}
          >
            Nu
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleCloseConfirmation();
              handleSubmit();
            }}
          >
            Da
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Snackbar
                open={alertUpload}
                autoHideDuration={6000}
                onClose={handleCloseAlertUpload}
                message="Note archived"
            >
                <Alert severity="warning">
                    Datorita numarului mare de fisiere incarcate, acesta a fost limitat la 10.
                </Alert>
            </Snackbar> */}
      <GlobalAlert
        openAlert={alertUpload}
        setOpenAlert={setAlertUpload}
        color={"warning"}
        message={
          "Datorita numarului mare de fisiere incarcate, acesta a fost limitat la 10."
        }
      />
      <GlobalAlert
        openAlert={alertNok}
        setOpenAlert={setAlertNok}
        color={"error"}
        message={
          "Ceva nu a functionat. Varugam sa incercati din nou sa sa contactati echipa IT."
        }
      />
      <Loader loading={loader} />
    </form>
  );
};

export default OpenTicket;
