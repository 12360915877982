import * as React from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

const Root = styled("div")(
  ({ theme }) => `
    color: ${
      localStorage.getItem("themeMode") === "darkMode" ? "#fff" : "#141414"
    };
    font-size: 14px;
    `
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `

    width: 100%;
    border: 1px solid ${
      localStorage.getItem("themeMode") === "darkMode" ? "#434343" : "#d9d9d9"
    };
    background-color: ${
      localStorage.getItem("themeMode") === "darkMode"
        ? "transparent"
        : "transparent"
    };
    border-radius: 4px;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;

    &:hover {
      border-color: ${
        localStorage.getItem("themeMode") === "darkMode"
          ? "rgb(0, 255, 200)"
          : "#0a1b35"
      };
    }

    &.focused {
      border-color: ${
        localStorage.getItem("themeMode") === "darkMode"
          ? "rgb(0, 255, 200)"
          : "#141414"
      };
      box-shadow: 0 0 0 2px rgb(219 223 227 / 20%);
    }

    & input {
      background-color: ${
        localStorage.getItem("themeMode") === "darkMode"
          ? "transparent"
          : "transparent"
      };
      color: ${
        localStorage.getItem("themeMode") === "darkMode"
          ? "rgba(255,255,255,0.65)"
          : "rgba(0,0,0,.85)"
      };
      
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0;
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;

    }
  `
);
function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: ${
      localStorage.getItem("themeMode") === "darkMode"
        ? "rgba(255,255,255,0.08)"
        : "rgba(0, 0, 0, 0.08);"
    };
    border: 1px solid ${
      localStorage.getItem("themeMode") === "darkMode" ? "#303030" : "#e8e8e8"
    };

    border-radius: 14px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;

    &:focus {
      border-color: ${
        localStorage.getItem("themeMode") === "darkMode" ? "#177ddc" : "#40a9ff"
      };
      background-color: ${
        localStorage.getItem("themeMode") === "darkMode"
          ? "#rgb(0, 255, 200)"
          : "#e6f7ff"
      };
    }

    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & svg {
      font-size: 24px;
      cursor: pointer;
      padding: 4px;
    }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    z-index:9999;
    background-color: ${
      localStorage.getItem("themeMode") === "darkMode" ? "#363636" : "#fff"
    };
    overflow: auto;
    max-height: 100px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    & li {
      padding: 5px 12px;
      display: flex;

      & span {
        flex-grow: 1;
      }

      & svg {
        color: transparent;
      }
    }

    & li[aria-selected='true'] {
      display:none;
      background-color: ${
        localStorage.getItem("themeMode") === "darkMode"
          ? "#2b2b2b"
          : "transparent"
      };
      font-weight: 600;

      & svg {
        color: #1890ff;
      }
    }

    & li.${autocompleteClasses.focused} {
      background-color: ${
        localStorage.getItem("themeMode") === "darkMode" ? "#2b2b2b" : "#e7e7e7"
      };
      cursor: pointer;

      & svg {
        color: currentColor;
      }
    }
  `
);

export default function SelectAndRemove({
  options,
  selectedOptions,
  selectedId,
  selectedData,
  onAdd,
  onDelete,
}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [tagToDeleteIndex, setTagToDeleteIndex] = React.useState(null);

  const [value, setValue] = React.useState(selectedOptions);
  const openModal = (value) => {
    setTagToDeleteIndex(value);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setTagToDeleteIndex(null);
  };
  React.useEffect(() => {
    setValue(selectedOptions);
  }, [selectedOptions, selectedId]);
  const handleDelete = () => {
    onDelete(tagToDeleteIndex?.id, selectedData);
    closeModal();
    setTagToDeleteIndex("");
  };

  const handleOptionChange = (newValues) => {
    onAdd(newValues, selectedData);
  };
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
    getOptionSelected,
  } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: selectedOptions,
    value: value.name,
    multiple: true,
    options: options,
    getOptionLabel: (option) => option.name,
    onChange: (_, newValues) => {
      handleOptionChange(newValues);
    },
    getOptionSelected: (option, value) => option.id === value.id,
    isOptionEqualToValue: (option, value) =>
      option.id === value.id && option.name === value.name,
  });
  return (
    <Root>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}></Label>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {value.map((option, index) => (
            <StyledTag label={option.name} onDelete={() => openModal(option)} />
          ))}
          <input {...getInputProps()} placeholder="Cauta..." />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <span>{option.name}</span>
            </li>
          ))}
        </Listbox>
      ) : null}
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Confirma stergerea</DialogTitle>
        <DialogContent>Esti sigur/a ca vrei sa faci asta ?</DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
}
