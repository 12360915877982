import React, { useEffect } from "react";
import Session from "./../../Components/Session.js";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Penalizari from "./Penalizari.js";
import PenalizariOptiuni from "./PenalizariOptiuni.js";
import { Button } from "@mui/material";
import pathAPI from "../../pathAPI";
import FilterModal from "./FilterModal";
import DataGridComponent from "./DataGridTest.jsx";
import DataGridReusableCrud, {
  renderCellExpand,
} from "../../Components/DataGridCrud/DataGridCrud.jsx";
import SnackbarComponent from "../../Components/Alerts/SnackbarComponent.jsx";
import CustomFilterModal from "./CustomFilterModal.jsx";
import SettingsIcon from "@mui/icons-material/Settings";
import axiosAuth from "../../Helpers/axiosAuth.jsx";
const MainPenalizari = () => {
  var auth = false;
  if (Session([999, 400, 709])) {
    auth = true;
  }

  const [value, setValue] = React.useState("1");
  const [tepe, setTepe] = React.useState([]);
  const [penalizari, setpenalizari] = React.useState([]);

  const [agentiData, setAgentiData] = React.useState([]);
  const [clientiData, setClientiData] = React.useState([]);
  const [dovezi, setDovezi] = React.useState([]);
  const [moneda, setMoneda] = React.useState([]);
  const [ticketsData, setTicketsData] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [type, setType] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [customFilterModalOpen, setCustomFilterModalOpen] =
    React.useState(false);

  const handleOpenFilterModal = () => {
    setFilterModalOpen(true);
  };
  const handleCustomFilterModal = () => {
    setCustomFilterModalOpen(true);
  };
  const handleCloseCustomFilterModal = () => {
    setCustomFilterModalOpen(false);
  };
  const handleCloseFilterModal = () => {
    setFilterModalOpen(false);
  };

  const handleApplyFilter = (
    filterAgent,
    filterClient,
    filterDosar,
    datePicked,
    datePickedTo,
    fromDataDosar,
    toDataDosar
  ) => {
    getALlData(
      filterAgent,
      filterClient,
      filterDosar,
      datePicked,
      datePickedTo,
      fromDataDosar,
      toDataDosar
    );
  };
  const getALlData = (
    filterAgent,
    filterClient,
    filterDosar,
    datePicked,
    datePickedTo,
    fromDataDosar,
    toDataDosar
  ) => {
    axiosAuth
      .post("/dashboard/penalizari/getAllFromINFO/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "TEPE,PENALIZARI",
        filterAgent: filterAgent,
        filterClient: filterClient,
        filterDosar: filterDosar,
        fromDate: datePicked,
        toDate: datePickedTo,
        fromDataDosar: fromDataDosar,
        toDataDosar: toDataDosar,
      })
      .then(function (response) {
        console.log("moha", response.data.TEPE);
        setTepe(response.data.TEPE);
        setpenalizari(response.data.PENALIZARI);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };
  const getControlDetails = () => {
    const postData = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      tableNames: "DOVEZI,TIP,MONEDA",
    };

    axiosAuth
      .post("/dashboard/penalizari/getAllOptions/", postData)
      .then(function (response) {
        setDovezi(response.data.DOVEZI);
        setType(response.data.TIP);
        setMoneda(response.data.MONEDA);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };
  const getAgenti = () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const postData = {
      token: token,
      email: email,
    };
    axiosAuth
      .post("/dashboard/penalizari/getAgenti/", postData)
      .then((response) => {
        setAgentiData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getClienti = () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const postData = {
      token: token,
      email: email,
    };
    axiosAuth
      .post("/dashboard/penalizari/getClienti/", postData)
      .then((response) => {
        console.log("saluiuuut", response.data);
        setClientiData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getTickets = () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const postData = {
      token: token,
      email: email,
    };
    axiosAuth
      .post("/dashboard/penalizari/getTickets/", postData)
      .then((response) => {
        setTicketsData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getALlData();
    getAgenti();
    getClienti();
    getControlDetails();
    getTickets();
  }, [value]);
  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles.includes(array[i])) {
        return true;
      }
    }
    return false;
  }
  const shouldRefresh = () => {
    getALlData();
  };

  const parentColumns = [
    {
      field: "agent",
      headerName: "Agent",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: agentiData.map((item) => item.name),
      renderCell: renderCellExpand,
    },

    {
      field: "client",
      headerName: "Client",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: clientiData.map((item) => item.NAME),
      renderCell: renderCellExpand,
    },

    {
      field: "facturi",
      headerName: "Factura",
      flex: 2,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "totalPrejudiciu",
      headerName: "Total Prejudiciu",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "moneda",
      headerName: "Moneda",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: moneda.map((item) => item.name),
    },
    {
      field: "nrDosar",
      headerName: "Nr. Dosar",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "dataDosar",
      headerName: "Data Dosar",
      flex: 2,
      editable: true,
      type: "date",
      filterable: true,
      valueGetter: (params) => {
        return new Date(params.value);
      },
    },

    {
      field: "observatii",
      headerName: "Observatii",
      flex: 2,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "tichetNr",
      headerName: "Tichet",
      flex: 2,
      editable: true,

      renderCell: renderCellExpand,
    },
    {
      field: "addDate",
      headerName: "Data adaugare",
      flex: 2,
      editable: false,
    },
  ];

  const handleUpdateTepe = (requestData) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const tableName = "tepe";
    const postData = {
      token: token,
      email: email,
      formData: requestData,
      tableName: tableName.toUpperCase(),
    };
    axiosAuth
      .post("/dashboard/penalizari/updateTepe/", postData)
      .then((response) => {
        setSnackbarSeverity("success");
        setSnackbarMessage("Actualizare reușită!");
        setSnackbarOpen(true);
      })
      .then(function (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Actualizare reușită!");
        setSnackbarOpen(true);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };
  const handleDeleteTepe = (id) => {
    axiosAuth
      .post("/dashboard/penalizari/basicDelete/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableName: "TEPE",
        valueId: id,
      })
      .then(function (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Actualizare reușită!");
        setSnackbarOpen(true);
      })
      .catch(function (error) {});
  };
  return (
    <>
      {auth ? (
        <div className="box">
          <div class="boxHeader">
            <Button onClick={handleOpenFilterModal}>Open Filter Modal</Button>
          </div>

          <FilterModal
            open={filterModalOpen}
            onClose={handleCloseFilterModal}
            onApplyFilter={handleApplyFilter}
            agentiData={agentiData}
            clientiData={clientiData}
          />
          <CustomFilterModal
            open={customFilterModalOpen}
            onClose={handleCloseCustomFilterModal}
            onApplyFilter={handleApplyFilter}
            agentiData={agentiData}
            clientiData={clientiData}
          />
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Penalizari Primite" value="1" />
                <Tab label="Penalizari Trimise" value="4" />
                <Tab label="TEPE " value="8" />
                {/* <Tab label="Tepe" value="2" /> */}
                {checkRoles([999, 709]) ? (
                  <Tab label="Control Optiuni" value="3" />
                ) : (
                  ""
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              <Penalizari
                penalizariData={penalizari}
                getAllData={getALlData}
                agentiData={agentiData}
                clientiData={clientiData}
                moneda={moneda}
                dovezi={dovezi}
                type={type}
                ticketsData={ticketsData}
                getAllClients={getClienti}
              ></Penalizari>
            </TabPanel>
            <TabPanel value="4">
              <DataGridComponent
                agentiData={agentiData}
                clientiData={clientiData}
                moneda={moneda}
                dovezi={dovezi}
                type={type}
                ticketsData={ticketsData}
                getAllClients={getClienti}
              ></DataGridComponent>
            </TabPanel>
            {/* <TabPanel value="2">
              <Tepe
                tepeData={tepe}
                agentiData={agentiData}
                clientiData={clientiData}
                getAllData={getALlData}
                getAllClients={getClienti}
                moneda={moneda}
                ticketsData={ticketsData}
              ></Tepe>
            </TabPanel> */}
            <TabPanel value="8">
              <div class="filterButtonEnd">
                <SettingsIcon
                  className="cursor-pointer flex "
                  onClick={handleCustomFilterModal}
                ></SettingsIcon>
              </div>
              <DataGridReusableCrud
                initialRows={tepe?.data}
                getAllClients={getClienti}
                parentColumns={parentColumns}
                updateDataCallBack={handleUpdateTepe}
                deleteDataCallBack={handleDeleteTepe}
                insertDataAxios={
                  pathAPI + "/dashboard/penalizari/insertTepeGrid/"
                }
                shouldRefresh={shouldRefresh}
              ></DataGridReusableCrud>
              <p className="pt-4">
                Suma Total Prejudiciu {tepe?.totalPrejudiciuSum}
              </p>
            </TabPanel>
            {checkRoles([999, 709]) ? (
              <TabPanel value="3">
                <PenalizariOptiuni></PenalizariOptiuni>
              </TabPanel>
            ) : null}
          </TabContext>
          <SnackbarComponent
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={() => setSnackbarOpen(false)}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MainPenalizari;
