import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import SnackbarComponent from "../../Components/Alerts/SnackbarComponent";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  roRO,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React, { useState, useEffect } from "react";
import pathAPI from "../../pathAPI";
import DeleteDialog from "../../Components/Dialogs/DeleteDialog";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Button } from "@mui/material";
import "./Salarizare.css";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axiosAuth from "../../Helpers/axiosAuth";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      className="custom-box"
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

export default function FullFeaturedCrudGrid({ clientiData }) {
  const initialVisibilityModel = {
    mentiuni: false,
    incasari_sistem_vechi: false,
    bonus_fidelitate: false,
    bonus: false,
    avans: false,
    corectie: false,
    salariu_net: false,
    type_name: false,
    actions: false,
  };
  const editModeVisibilityModel = {
    mentiuni: true,
    incasari_sistem_vechi: true,
    bonus_fidelitate: true,
    bonus: true,
    avans: true,
    corectie: true,
    salariu_net: true,
    type_name: true,
  };

  const [rows, setRows] = useState([]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [datePicked, setDatePicked] = useState(dayjs().format("YYYY-MM-DD"));
  const [datePickedEnd, setDatePickedEnd] = useState(null);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialVisibilityModel
  );
  const [isEditMode, setIsEditMode] = useState(false);

  const [checkboxState, setCheckboxState] = useState(() => {
    const storedState = localStorage.getItem("checkboxState");
    return storedState ? JSON.parse(storedState) : {};
  });
  const [totalNetSum, setTotalNetSum] = useState(0);
  const [totalAvansSum, setAvansSum] = useState(0);
  function EditToolbar() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleExport = () => {
      const currentMonth = dayjs(datePicked).format("MMMM");
      const currentYear = dayjs(datePicked).format("YYYY");
      const tsvContent = rows
        .map(
          (row) =>
            `${row.name}\t${row.iban}\t${row.total_net_cu_fidelitate}\tSalariu ${currentMonth} ${currentYear}`
        )
        .join("\n");
      const tsvData = new Blob([tsvContent], {
        type: "text/plain",
      });
      const tsvUrl = URL.createObjectURL(tsvData);
      const link = document.createElement("a");
      link.href = tsvUrl;
      link.download = "export.txt";
      link.click();
    };
    return (
      <>
        <div className="editToolbarSa">
          {" "}
          <GridToolbar></GridToolbar>
          <Button onClick={handleExport} className="exportBancaBtn">
            <AccountBalanceIcon sx={{ height: "14px" }}></AccountBalanceIcon>
            Export Banca
          </Button>
          <Button onClick={handleToggleMode} className="exportBancaBtn">
            {isEditMode ? (
              <>
                <ModeEditIcon sx={{ height: "14px" }}> </ModeEditIcon>
                <p>Editare</p>
              </>
            ) : (
              <>
                <VisibilityIcon sx={{ height: "14px" }}> </VisibilityIcon>
                <p>Vizualizare</p>
              </>
            )}
          </Button>
        </div>
        <SnackbarComponent
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </>
    );
  }
  const handleToggleMode = () => {
    if (isEditMode) {
      setColumnVisibilityModel(initialVisibilityModel);
    } else {
      setColumnVisibilityModel(editModeVisibilityModel);
    }
    setIsEditMode(!isEditMode);
  };

  const handleCheckboxChange = (name) => {
    setCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: !prevCheckboxState[name],
    }));

    localStorage.setItem(
      "checkboxState",
      JSON.stringify({
        ...checkboxState,
        [name]: !checkboxState[name],
      })
    );
  };
  const getCentralizareSalarii = () => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");
    const adjustedDateEbd = dayjs(datePickedEnd)
      .startOf("month")
      .format("YYYY-MM-DD");
    axiosAuth
      .post("/dashboard/salarizareNew/getCentralizareSalariiNEW/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: adjustedDate,
        yearMonthEnd: adjustedDateEbd,
      })
      .then(function (response) {
        setRows(response.data);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  const deletePenalizariTrimise = (id) => {
    const postData = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: id,
    };

    axiosAuth
      .post(
        pathAPI + "/dashboard/penalizari/deletePenalizariTrimise/",
        postData
      )
      .then(function () {
        setSnackbarSeverity("success");
        setSnackbarMessage("Ștergerea a fost finalizată cu succes!");
        setSnackbarOpen(true);
      })
      .catch(function () {
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    getCentralizareSalarii();
  }, [datePicked, datePickedEnd]);

  const [rowModesModel, setRowModesModel] = useState({});

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "edit" } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "view" } });
  };

  const handleDeleteConfirm = () => {
    if (deleteItemId) {
      deletePenalizariTrimise(deleteItemId);
      setRows((prevRows) => prevRows.filter((row) => row.id !== deleteItemId));
    }
    setDeleteItemId(null);
    setDeleteDialogOpen(false);
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "view", ignoreModifications: true },
    });

    const editedRow = rows && rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      deletePenalizariTrimise(id);
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const updatePenalizariTrimise = (updatedRow) => {
    const adjustedDate = dayjs(datePicked)
      .startOf("month")
      .format("YYYY-MM-DD");

    const postData = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      yearMonth: adjustedDate,
      user_id: updatedRow.user_id,
      fullname: updatedRow.name,
      corectie: updatedRow.corectie,
      avans: updatedRow.avans,
      bonus: updatedRow.bonus,
      incasari_sistem_vechi: updatedRow.incasari_sistem_vechi,
      bonus_fidelitate: updatedRow.bonus_fidelitate,
      mentiuni: updatedRow.mentiuni,
    };

    axiosAuth
      .post("dashboard/salarizareNew/updateCorectieNEW/", postData)
      .then(function () {
        setSnackbarSeverity("success");
        setSnackbarMessage("Actualizare reușită!");
        setSnackbarOpen(true);
        getCentralizareSalarii();
      })
      .catch(function () {
        console.log("Request failed.");
      });

    const clientId = clientiData.find(
      (item) => item.NAME === updatedRow.client
    );
    updatedRow.client = clientId ? clientId.ID : null;
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    updatePenalizariTrimise(updatedRow);
    return updatedRow;
  };
  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const columns = [
    {
      field: "name",
      headerName: "Nume",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.id !== "totals" ? (
            <span
              className={
                "circleSpan " +
                (params?.row?.Type === "0" ? "agentCircle" : "tesaCircle")
              }
            >
              <input
                type="checkbox"
                checked={checkboxState[params.row.name] || ""}
                onChange={() => handleCheckboxChange(params.row.name)}
              />
              <span></span>
              {params.value}
            </span>
          ) : (
            <span>Totaluri</span>
          )}
        </div>
      ),
    },

    {
      field: "salariu_net",
      headerName: "Salariu Net",
      flex: 1,

      editable: false,
      renderCell: renderCellExpand,
    },
    { field: "type_name", headerName: "Tip", editable: false },
    {
      field: "iban",
      headerName: "Iban",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "corectie",
      headerName: "Corectie",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "avans",
      headerName: "Avans",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },

    {
      field: "bonus",
      headerName: "Bonus",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "bonus_fidelitate",
      headerName: "Fidelitate",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "incasari_sistem_vechi",
      headerName: "Incasari S.Vechi",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "mentiuni",
      headerName: "Mentiuni",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "total_net",
      headerName: "Subtotal",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },

    {
      field: "total_net_cu_fidelitate",
      headerName: "Total net",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actiuni",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === "edit";

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum += parseFloat(row.total_net_cu_fidelitate) || 0;
    });
    setTotalNetSum(sum);
    let diff = 0;
    rows.forEach((row) => {
      diff -= parseFloat(row.avans) || 0;
    });
    setAvansSum(diff);
  }, [rows]);
  return (
    <Box
      sx={{
        height: "full",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      {" "}
      <div className="fromToDate">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Luna / anul"
            views={["year", "month"]}
            value={datePicked}
            onChange={(newValue) => {
              localStorage.removeItem("checkboxState");
              setCheckboxState({});
              setDatePicked(newValue);
            }}
            format="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField size="small" {...params} required />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Luna / anul"
            views={["year", "month"]}
            value={datePickedEnd || null}
            onChange={(newValue) => {
              localStorage.removeItem("checkboxState");
              setCheckboxState({});
              setDatePickedEnd(newValue);
            }}
            format="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField size="small" {...params} required />
            )}
          />
        </LocalizationProvider>
      </div>
      <DataGrid
        rows={[
          ...rows,
          {
            id: "totals",
            fullname: "Totaluri*",
            total_net_cu_fidelitate: totalNetSum,
            avans: totalAvansSum,
          },
        ]}
        columns={columns}
        editMode="row"
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        onRowEditStart={() => {}}
        onRowEditStop={() => {}}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {
          console.error("Error occurred while updating a row:", error);
        }}
        localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          ...rows.initialState,
          pagination: { paginationModel: { pageSize: 100 } },
          // columns: columnsVisibility,
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        pageSizeOptions={[5, 10, 25, 100, rows.length]}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteDialogOpen(false)}
      />
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </Box>
  );
}
