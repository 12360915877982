// src/axiosInstance.js

import axios from "axios";
import pathAPI from "../pathAPI";

const axiosAuth = axios.create({
  baseURL: pathAPI,
});

axiosAuth.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log(token);
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (config.data instanceof FormData) {
      console.log("FormData detected");
      return config;
    }

    config.data = {
      ...config.data,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosAuth;
