import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import EditIcon from '@mui/icons-material/Edit';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import QuizIcon from '@mui/icons-material/Quiz';
import SaveIcon from '@mui/icons-material/Save';
import { Autocomplete, Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import axiosAuth from "../../../../Helpers/axiosAuth";

const ListaPagini = ({ onChangePagina, selectedCursPROPS, editModePROPS }) => {
  const [loading, setLoading] = useState(true);
  const [selectedCurs, setSelectedCurs] = useState(null);
  const [listaPagini, setListaPaigni] = useState(null);
  const [id_pagina, setId_pagina] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [flatData, setFlatData] = useState(null);
  const [selectedPageToDelete, setSelectedPageToDelete] = useState(null);
  const [editPageId, setEditPageId] = useState(null);
  const [formData, setFormData] = useState({ titlu_pagina: "", timp_necesar: "" });

  const initialFormAdaugaPagina = {
    titlu_pagina: null,
    pagina_parinte: { "titlu": "", "id": null },
    pageType: { "name": '', "value": 0 },
    timp_necesar: null,
    ordine: null,
  };

  const pageType = [
    { "name": 'Invatat', "value": 0 },
    { "name": 'Activitate', "value": 1 },
    { "name": 'Quiz', "value": 2 }
  ];

  const [formAdaugaPagina, setFormAdaugaPagina] = useState(initialFormAdaugaPagina);

  const insertNewPage = () => {
    setLoading(true);
    axiosAuth
      .post("dashboard/cursuri/insertNewPage", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_curs: selectedCurs?.id,
        titlu_pagina: formAdaugaPagina.titlu_pagina,
        pagina_parinte: formAdaugaPagina.pagina_parinte.id,
        timp_necesar: formAdaugaPagina.timp_necesar,
        ordine: formAdaugaPagina.ordine,
        pageType: formAdaugaPagina.pageType.value
      })
      .then(function (response) {
        setFormAdaugaPagina(initialFormAdaugaPagina);
        setLoading(false);
        getListaPagini();
      })
      .catch(function (error) {
        console.error("Request failed:", error);
        setLoading(false);
      });
  }

  const deletePage = (id_pg) => {
    setLoading(true);
    axiosAuth
      .post("dashboard/cursuri/deletePage", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_pagina: id_pg
      })
      .then(function (response) {
        setSelectedPageToDelete(null);
        setLoading(false);
        getListaPagini();
      })
      .catch(function (error) {
        console.error("Request failed:", error);
        setLoading(false);
      });
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormAdaugaPagina({
      ...formAdaugaPagina,
      [name]: value,
    });
  };

  const handleAutocompleteChange = (event, newValue, name) => {
    setFormAdaugaPagina({
      ...formAdaugaPagina,
      [name]: newValue,
    });
  }

  function flattenData(data) {
    let flatData = [];

    function traverse(node, sub = "") {
      flatData.push({ "titlu": sub + " " + node.child_titlu_pagina, "id": node.child_id });

      if (node.copii) {
        node.copii.forEach(child => traverse(child, sub + "--"));
      }
    }

    data.forEach(node => traverse(node, ""));
    return flatData;
  }

  const getListaPagini = () => {
    setLoading(true);
    axiosAuth
      .post("dashboard/cursuri/getListaPagini", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_curs: selectedCurs?.id
      })
      .then(function (response) {
        setLoading(false);
        setListaPaigni(response.data.listaPagini);
      })
      .catch(function (error) {
        console.error("Request failed:", error);
        setLoading(false);
      });
  };

  const handleChangePage = (id_pagina) => {
    onChangePagina(id_pagina);
    setId_pagina(id_pagina);
    if (!paginiExpandate[id_pagina]) {
      toggleExpandarePagina(id_pagina)
    }
  };

  const [paginiExpandate, setPaginiExpandate] = useState({});
  const intervalRef = useRef(null);
  const [timpPetrecut, setTimpPetrecut] = useState({});

  const toggleExpandarePagina = (id) => {
    setPaginiExpandate(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  useEffect(() => {
    if (listaPagini) {
      setFlatData(flattenData(listaPagini));
      const timpInitial = listaPagini.reduce((acc, pagina) => {
        acc[pagina.child_id] = pagina.timp_petrecut;
        return acc;
      }, {});
      setTimpPetrecut(timpInitial);
    }
  }, [listaPagini]);

  const initializeTimpPetrecut = (pagini, timpPetrecut = {}) => {
    pagini.forEach(pagina => {
      timpPetrecut[pagina.child_id] = parseInt(pagina.timp_petrecut);
      if (pagina.copii && pagina.copii.length > 0) {
        initializeTimpPetrecut(pagina.copii, timpPetrecut);
      }
    });
    return timpPetrecut;
  };

  const insertTimpPetrecut = () => {
    axiosAuth
      .post("dashboard/cursuri/insertTimpPetrecut", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_curs: selectedCurs?.id,
        id_pagina: id_pagina
      })
      .then(function (response) { })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  useEffect(() => {
    if (listaPagini) {
      const timpInitial = initializeTimpPetrecut(listaPagini);
      setTimpPetrecut(timpInitial);
    }
  }, [listaPagini]);

  function findChildById(data, childId) {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        const result = findChildById(data[i], childId);
        if (result) return result;
      }
    } else if (data && typeof data === 'object') {
      if (data.child_id === childId) {
        return data;
      } else if (data.copii) {
        return findChildById(data.copii, childId);
      }
    }
    return null;
  }

  const updateTimpPetrecut = () => {
    setTimpPetrecut(prevTimpPetrecut => {
      const timpCurent = (prevTimpPetrecut[id_pagina] || 0) + 1;
      return { ...prevTimpPetrecut, [id_pagina]: timpCurent };
    });
    insertTimpPetrecut();
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (findChildById(listaPagini, id_pagina).type == 0) {
        if (document.visibilityState === 'visible' && id_pagina) {
          if (!intervalRef.current) {
            intervalRef.current = setInterval(updateTimpPetrecut, 60000);
          }
        } else {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalRef.current);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [id_pagina]);

  useEffect(() => {
    if (id_pagina && document.visibilityState === 'visible') {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(updateTimpPetrecut, 60000);
    }
    return () => clearInterval(intervalRef.current);
  }, [id_pagina]);

  const renderListaPagini = (pagini, ierarhie = 12) => {
    const renderPaginaInfo = (pagina, ierarhie, procent, timpCurentPetrecut) => (
      <>
        {editPageId === pagina.child_id ? (
          <div className="edit-fields">
            <TextField
              size="small"
              name="titlu_pagina"
              label="Editare titlu"
              placeholder="Editare titlu"
              value={formData.titlu_pagina}
              onChange={(e) => setFormData({ ...formData, titlu_pagina: e.target.value })}
              required
              style={{ width: '80%' }}
            />
            <TextField
              size="small"
              name="timp_necesar"
              label="Editare timp necesar"
              placeholder="Editare timp necesar"
              value={formData.timp_necesar}
              onChange={(e) => setFormData({ ...formData, timp_necesar: e.target.value })}
              required
              style={{ width: '80%', marginTop: '8px' }}
            />
            <div className="save-button-container">
              <IconButton onClick={saveEditPage}>
                <SaveIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <>
            <span
              className="buttonPaginaAction"
              style={{ paddingLeft: `${ierarhie}px`, paddingRight: pagina.copii && pagina.copii.length > 0 ? '42px' : '12px' }}
              onClick={() => handleChangePage(pagina.child_id)}
            >
              <div className="paginaInfoTab">
                <span>{pagina.ordine}) {pagina.child_titlu_pagina}</span>
              </div>
              <div className="paginaInfoTab">
                <span className="paginaInfoTabIconTime">
                  <span>
                    {pagina.type == "0" && <QuizIcon />}
                    {pagina.type == "1" && <DownhillSkiingIcon />}
                    {pagina.type == "2" && <LightbulbIcon />}
                  </span>
                  {timpCurentPetrecut}/{pagina.timp_necesar} minute
                </span>
                <span>{procent}%</span>
                {editMode && (
                  <span className="edit-delete-icons">
                    <IconButton onClick={() => handleEditPage(pagina)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => setSelectedPageToDelete(pagina)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </span>
                )}
              </div>
            </span>
            {pagina.copii && pagina.copii.length > 0 && (
              <span className="buttonExpanderPagini" onClick={() => toggleExpandarePagina(pagina.child_id)}>
                {paginiExpandate[pagina.child_id] ? '-' : '+'}
              </span>
            )}
            <span className="progressLoader" style={{ right: pagina.copii && pagina.copii.length > 0 ? "30px" : "0" }}>
              <span style={{ width: procent + "%" }}></span>
            </span>
          </>
        )}
      </>
    );

    return (
      <>
        {pagini.map(pagina => {
          const timpCurentPetrecut = timpPetrecut[pagina.child_id] || pagina.timp_petrecut;
          let procent = parseInt(pagina.timp_necesar > 0 ? Math.min(100, (timpCurentPetrecut / pagina.timp_necesar) * 100) : 100);

          return (
            <div className="listaPagini" key={pagina.child_id}>
              <div className={`buttonPagina ${id_pagina === pagina.child_id ? "active" : ""}`}>
                {renderPaginaInfo(pagina, ierarhie, procent, timpCurentPetrecut)}
              </div>
              {pagina.copii && pagina.copii.length > 0 && paginiExpandate[pagina.child_id] && renderListaPagini(pagina.copii, ierarhie + 12)}
            </div>
          );
        })}
      </>
    );
  };

  const handleEditPage = (pagina) => {
    setFormData({ titlu_pagina: pagina.child_titlu_pagina, timp_necesar: pagina.timp_necesar });
    setEditPageId(pagina.child_id);
  };

  const saveEditPage = () => {
    axiosAuth
      .post("dashboard/cursuri/updatePage", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_pagina: editPageId,
        titlu_pagina: formData.titlu_pagina,
        timp_necesar: formData.timp_necesar,
      })
      .then(function (response) {
        setEditPageId(null);
        getListaPagini();
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  useEffect(() => {
    setEditMode(editModePROPS);
  }, [editModePROPS]);

  useEffect(() => {
    setSelectedCurs(selectedCursPROPS);
  }, [selectedCursPROPS]);

  useEffect(() => {
    if (selectedCurs) {
      getListaPagini();
    }
  }, [selectedCurs])

  return (
    <div className="paginiCurs info-ticket-details">
      <div className="box-pagini-curs">
        <div className="listaPagini">
          <div className={`buttonPagina ${!id_pagina ? "active" : ""}`}>
            <span className="buttonPaginaAction" onClick={() => handleChangePage(null)}>
              <span>Prima pagina</span>
            </span>
          </div>
        </div>
        {listaPagini ? renderListaPagini(listaPagini) : null}
        {editMode && (
          <>
            <div className="adaugaPagina">
              <div className="boxAdaugaPagina">
                <Autocomplete
                  size="small"
                  name="pagina_parinte"
                  autoComplete
                  options={flatData ? flatData : ''}
                  getOptionLabel={(pagina) => pagina.titlu}
                  value={formAdaugaPagina.pagina_parinte ?? ""}
                  renderInput={(params) => (
                    <TextField {...params} label="Pagina parinte" placeholder="Pagina parinte..." required />
                  )}
                  onChange={(event, newValue) =>
                    handleAutocompleteChange(event, newValue, "pagina_parinte")
                  }
                />
                <TextField
                  size="small"
                  name="titlu_pagina"
                  label="Titlu pagina"
                  placeholder="Titlu pagina..."
                  value={formAdaugaPagina.titlu_pagina ?? ""}
                  onChange={handleInputChange}
                  required
                />
                <Autocomplete
                  size="small"
                  name="pageType"
                  autoComplete
                  options={pageType ? pageType : ''}
                  getOptionLabel={(type) => type.name}
                  value={formAdaugaPagina.pageType ?? ""}
                  renderInput={(params) => (
                    <TextField {...params} label="Tipul paginii" placeholder="Tipul paginii..." required />
                  )}
                  onChange={(event, newValue) =>
                    handleAutocompleteChange(event, newValue, "pageType")
                  }
                />
                <TextField
                  size="small"
                  name="timp_necesar"
                  label="Timp necesar"
                  placeholder="Timp necesar..."
                  value={formAdaugaPagina.timp_necesar ?? ""}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  size="small"
                  name="ordine"
                  label="Ordine"
                  placeholder="Ordine..."
                  value={formAdaugaPagina.ordine ?? ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                style={{ width: "100%" }}
                onClick={insertNewPage}
              >
                Adauga pagina
              </Button>
            </div>
            <Dialog
              className="ticket-dialog"
              open={selectedPageToDelete}
              onClose={() => setSelectedPageToDelete(null)}
            >
              {selectedPageToDelete && (
                <div className="ticket-container">
                  <div className="ticket-header">
                    <p className="ticket-subject">Ștergere valoare</p>
                  </div>
                  <div className="ticket-details">
                    <p>Urmează să ștergi valoarea: {selectedPageToDelete.child_titlu_pagina}</p>
                  </div>
                  <div className="ticket-dialog-control">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => deletePage(selectedPageToDelete.child_id)}
                    >
                      Șterge
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setSelectedPageToDelete(null)}
                    >
                      Anulează
                    </Button>
                  </div>
                </div>
              )}
            </Dialog>
          </>
        )}
      </div>
    </div>
  );
}

export default ListaPagini;