import {
  GET_TRANSPORTATORI_SUCCES,
  GET_TRANSPORTATORI_FAILURE,
  UPDATE_TRANSPORTATOR_FAILURE,
  UPDATE_TRANSPORTATOR_SUCCESS,
  DELETE_TRANSPORTATOR_SUCCESS,
  DELETE_TRANSPORTATOR_FAILURE,
  GET_COUNTRY_CODES_FAILURE,
  GET_COUNTRY_CODES_SUCCESS,
} from "../actionTypes";
import pathAPI from "../../pathAPI";
import axiosInstance from "../../Helpers/axiosInstance";

export const getTransportatoriSucces = (transportatori) => ({
  type: GET_TRANSPORTATORI_SUCCES,
  payload: transportatori,
});

export const getTransportatoriFailure = (error) => ({
  type: GET_TRANSPORTATORI_FAILURE,
  payload: error,
});

export const updateTransportatorSuccess = (updatedTransportator) => ({
  type: UPDATE_TRANSPORTATOR_SUCCESS,
  payload: updatedTransportator,
});

export const updateTransportatorFailure = (error) => ({
  type: UPDATE_TRANSPORTATOR_FAILURE,
  payload: error,
});
export const deleteTransportatorSuccess = () => ({
  type: DELETE_TRANSPORTATOR_SUCCESS,
});

export const deleteTransportatorFailure = (error) => ({
  type: DELETE_TRANSPORTATOR_FAILURE,
  payload: error,
});
export const getCountryCodesSuccess = (countryCodes) => ({
  type: GET_COUNTRY_CODES_SUCCESS,
  payload: countryCodes,
});

export const getCountryCodesFailure = (error) => ({
  type: GET_COUNTRY_CODES_FAILURE,
  payload: error,
});

export const updateTransportator = (updatedData, selectedCountries) => {
  console.log("selectedcontries", selectedCountries);
  return async (dispatch) => {
    console.log("qqqq", updatedData);
    try {
      await axiosInstance.post(
        `dashboard/newsletter_comenzi/updateTransportator`,
        {
          id: updatedData.id,
          transportator_email: updatedData.email,

          frigo: updatedData.frigo,
          id_bursa_timo: updatedData.id_bursa_timo,
          nume_firma: updatedData.nume_firma,
          sprinter: updatedData.sprinter,
          standard: updatedData.standard,
          box: updatedData.box,
          autobasculanta: updatedData.autobasculanta,
          mega: updatedData.mega,

          cod_tara: selectedCountries,
        }
      );
      dispatch(updateTransportatorSuccess());
    } catch (error) {
      dispatch(updateTransportatorFailure(error.message));
    }
  };
};
export const getTransportatori = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${pathAPI}dashboard/newsletter_comenzi/getTransportatori`
      );
      console.log(response);
      dispatch(getTransportatoriSucces(response.data));
    } catch (error) {
      dispatch(getTransportatoriFailure(error.message));
    }
  };
};

export const deleteTransportator = (transportatorId) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(
        `dashboard/newsletter_comenzi/deleteTransportator/`,
        {
          id: transportatorId,
        }
      );

      dispatch(deleteTransportatorSuccess());
    } catch (error) {
      dispatch(deleteTransportatorFailure(error.message));
    }
  };
};
export const getCountryCodes = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/newsletter_comenzi/getCountryCodes`
      );
      console.log(response);
      dispatch(getCountryCodesSuccess(response.data));
    } catch (error) {
      dispatch(getCountryCodesFailure(error.message));
    }
  };
};
