import {
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
} from "../actionTypes";
import pathAPI from "../../pathAPI";
import axiosInstance from "../../Helpers/axiosInstance";

export const getAllUsersSuccess = (users) => ({
  type: GET_ALL_USERS_SUCCESS.type,
  payload: users,
});

export const getAllUsersFailure = (error) => ({
  type: GET_ALL_USERS_FAILURE,
  payload: error,
});
export const getUserByIdSuccess = (user) => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: user,
});

export const getUserByIdFailure = (error) => ({
  type: GET_USER_BY_ID_FAILURE,
  payload: error,
});
export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/teams/getAllUsers`
      );
      dispatch(getAllUsersSuccess(response.data));
    } catch (error) {
      dispatch(getAllUsersFailure(error.message));
    }
  };
};

export const getUserById = (id) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/users/getUserById`,
        { id }
      );
      dispatch(getUserByIdSuccess(response.data));
    } catch (error) {
      dispatch(getUserByIdFailure(error.message));
    }
  };
};
