import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import IconButton from "@mui/material/IconButton";

const SnackbarComponent = ({ open, message, severity, onClose }) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
    onClose();
  };

  const snackbarBackground = {
    backgroundColor:
      severity === "success" ? "rgba(0, 128, 0, 0.8)" : "rgba(255, 0, 0, 0.8)",
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isOpen}
      autoHideDuration={1000}
      onClose={handleClose}
    >
      <SnackbarContent
        style={snackbarBackground}
        message={
          <span style={{ display: "flex", alignItems: "center" }}>
            {severity === "success" ? (
              <CheckCircleIcon style={{ marginRight: "8px" }} />
            ) : (
              <ErrorIcon style={{ marginRight: "8px" }} />
            )}
            {message}
          </span>
        }
        action={[
          <IconButton key="close" color="inherit" onClick={handleClose}>
            X
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default SnackbarComponent;
