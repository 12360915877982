import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Autocomplete, Button, Dialog, IconButton, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axiosAuth from "../../../Helpers/axiosAuth";
import './Echipamente.css';

const echipamentTypes = [
  { label: 'Monitor', value: 1 },
  { label: 'Unitate', value: 2 },
  { label: 'Laptop', value: 3 },
  { label: 'Tastatură', value: 4 },
  { label: 'Mouse', value: 5 },
  { label: 'Telefon', value: 6 },
];

export default function ControlSetup() {
  const [listaSetups, setListaSetups] = useState([]);
  const [users, setUsers] = useState([]);
  const [echipamente, setEchipamente] = useState([]);
  const [setupSelectat, setSetupSelectat] = useState(null);
  const [useriSetup, setUseriSetup] = useState([]);
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState(null);
  const [selectedUserToEdit, setSelectedUserToEdit] = useState(null);
  const [selectedEchipamentToAdd, setSelectedEchipamentToAdd] = useState(null);
  const [selectedEchipamentToEdit, setSelectedEchipamentToEdit] = useState(null);
  const [echipamenteSetup, setEchipamenteSetup] = useState([]);
  const [formDataSetup, setFormDataSetup] = useState({ setup: null, data_primire: "" });
  const [formDataEchipament, setFormDataEchipament] = useState({
    marca: "",
    model: "",
    serie: "",
    data_achizitie: "",
    pret_achizitie_net: "",
    furnizor: "",
    extra_details: "",
    type: null
  });

  const getAllSetups = useCallback(() => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/getAllSetups", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        console.log("Setups:", response.data.listaSetups);
        setListaSetups(response.data.listaSetups || []);
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  }, []);

  const getAllUsers = useCallback(() => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/getAllUsers", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        console.log("Users:", response.data.users);
        setUsers(response.data.users || []);
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  }, []);

  const getAllEchipamente = useCallback(() => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/getAllEchipamente", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        console.log("Echipamente:", response.data.echipamente);
        setEchipamente(response.data.echipamente || []);
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  }, []);

  const handleSelectSetup = (event, newValue) => {
    console.log("Selected setup:", newValue);
    setSetupSelectat(newValue);
  };

  const handleSelectUsersToAdd = (event, newValue) => {
    console.log("Selected user to add:", newValue);
    setSelectedUsersToAdd(newValue);
  };

  const handleSelectEchipamentToAdd = (event, newValue) => {
    console.log("Selected echipament to add:", newValue);
    setSelectedEchipamentToAdd(newValue);
  };

  const insertUserToSetup = () => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/insertUserToSetup", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_setup: setupSelectat.setup,
        id_user: selectedUsersToAdd.id,
      })
      .then(function (response) {
        console.log("Insert user to setup response:", response.data);
        setUseriSetup(response.data.useriSetup || []);
        setSelectedUsersToAdd(null);
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  const insertEchipamentToSetup = () => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/insertEchipamentToSetup", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_setup: setupSelectat.setup,
        id_echipament: selectedEchipamentToAdd.id,
      })
      .then(function (response) {
        console.log("Insert echipament to setup response:", response.data);
        setEchipamenteSetup(sortEchipamenteByType(response.data.echipamenteSetup || []));
        setSelectedEchipamentToAdd(null);
        updateEchipamenteState();
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  const updateEchipamenteState = () => {
    selectAllEchipamenteFromSetup();
    getAllEchipamente();
  };

  const deleteUserFromSetup = (id_user) => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/deleteUserFromSetup", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_setup: setupSelectat.setup,
        id_user: id_user,
      })
      .then(function (response) {
        console.log("Delete user from setup response:", response.data);
        setSelectedUserToEdit(null);
        selectAllUsersFromSetup();
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  const deleteEchipamentFromSetup = (id_echipament) => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/deleteEchipamentFromSetup", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_setup: setupSelectat.setup,
        id_echipament: id_echipament,
      })
      .then(function (response) {
        console.log("Delete echipament from setup response:", response.data);
        setSelectedEchipamentToEdit(null);
        updateEchipamenteState();
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  const selectAllUsersFromSetup = useCallback(() => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/selectAllUsersFromSetup", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_setup: setupSelectat.setup,
      })
      .then(function (response) {
        console.log("Users in setup:", response.data.useriSetup);
        const uniqueUsers = response.data.useriSetup.filter((user, index, self) =>
          index === self.findIndex((u) => (
            u.id === user.id
          ))
        );
        setUseriSetup(uniqueUsers);
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  }, [setupSelectat]);

  const selectAllEchipamenteFromSetup = useCallback(() => {
    axiosAuth
      .post("dashboard/evidenta_echipamente/selectAllEchipamenteFromSetup", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_setup: setupSelectat.setup,
      })
      .then(function (response) {
        console.log("Echipamente in setup:", response.data.echipamenteSetup);
        const uniqueEchipamente = response.data.echipamenteSetup.filter((echipament, index, self) =>
          index === self.findIndex((e) => (
            e.id === echipament.id
          ))
        );
        setEchipamenteSetup(sortEchipamenteByType(uniqueEchipamente));
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  }, [setupSelectat]);

  const sortEchipamenteByType = (echipamente) => {
    return echipamente.sort((a, b) => a.type - b.type);
  };

  const getEchipamentType = (type) => {
    const types = {
      1: 'Monitor',
      2: 'Unitate',
      3: 'Laptop',
      4: 'Tastatură',
      5: 'Mouse',
      6: 'Telefon'
    };
    return types[type] || 'Echipament';
  };

  const getEchipamentDisplay = (echipament) => {
    const type = getEchipamentType(echipament.type);
    const extraDetails = echipament.extra_details ? ` | ${echipament.extra_details}` : '';
    return `${type}: ${echipament.marca} | ${echipament.model} | ${echipament.serie}${extraDetails}`;
  };

  const handleInputChangeSetup = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormDataSetup({
      ...formDataSetup,
      [name]: value,
    });
  };

  const handleInputChangeEchipament = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormDataEchipament({
      ...formDataEchipament,
      [name]: value,
    });
  };

  const handleSelectEchipamentType = (event, newValue) => {
    setFormDataEchipament({
      ...formDataEchipament,
      type: newValue ? newValue.value : null,
    });
  };

  const adaugaSetup = () => {
    if (formDataSetup.setup && formDataSetup.data_primire) {
      axiosAuth
        .post("dashboard/evidenta_echipamente/adaugaSetup", {
          setup: formDataSetup.setup,
          data_primire: formDataSetup.data_primire,
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
        })
        .then(function (response) {
          if (response.data.success === "true") {
            setFormDataSetup({ setup: null, data_primire: "" });
            getAllSetups();
          } else {
            console.error("Error:", response.data.message);
          }
        })
        .catch(function (error) {
          console.error("Request failed:", error);
        });
    }
  };

  const adaugaEchipament = () => {
    if (formDataEchipament.marca && formDataEchipament.model && formDataEchipament.serie && formDataEchipament.data_achizitie && formDataEchipament.pret_achizitie_net && formDataEchipament.furnizor && formDataEchipament.extra_details && formDataEchipament.type) {
      axiosAuth
        .post("dashboard/evidenta_echipamente/adaugaEchipament", {
          ...formDataEchipament,
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
        })
        .then(function (response) {
          if (response.data.success === "true") {
            setFormDataEchipament({
              marca: "",
              model: "",
              serie: "",
              data_achizitie: "",
              pret_achizitie_net: "",
              furnizor: "",
              extra_details: "",
              type: null
            });
            getAllEchipamente();
          } else {
            console.error("Error:", response.data.message);
          }
        })
        .catch(function (error) {
          console.error("Request failed:", error);
        });
    }
  };

  useEffect(() => {
    if (setupSelectat) {
      selectAllUsersFromSetup();
      selectAllEchipamenteFromSetup();
    }
  }, [setupSelectat, selectAllUsersFromSetup, selectAllEchipamenteFromSetup]);

  useEffect(() => {
    getAllSetups();
    getAllUsers();
    getAllEchipamente();
  }, [getAllSetups, getAllUsers, getAllEchipamente]);

  return (
    <div className="containerForControl">
      <div className="headerForControl">Control Setup</div>
      <div className="bodyForControl">
        <Autocomplete
          size="small"
          name="selectedSetup"
          id="selectedSetup"
          autoComplete
          options={listaSetups}
          getOptionLabel={(option) => option.setup}
          includeInputInList
          renderInput={(params) => (
            <TextField {...params} label="Alege setup-ul" required />
          )}
          onChange={handleSelectSetup}
        />

        {setupSelectat && (
          <>
            <div className="tableCrudForContainerControl">
              <div className="tcfcc-header">
                <div className="tcfcc-cell">Utilizatori</div>
                <div className="tcfcc-cell">Editează</div>
              </div>
              <div className="tcfcc-body">
                {useriSetup.map((userSetup) => {
                  return (
                    <div className="tcfcc-body-row" key={userSetup.id}>
                      <div className="tcfcc-cell">
                        {userSetup.email} | {userSetup.name}
                      </div>
                      <div className="tcfcc-cell">
                        <IconButton
                          onClick={() => setSelectedUserToEdit(userSetup)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
                <div className="tcfcc-body-row">
                  <div className="tcfcc-cell">
                    <Autocomplete
                      size="small"
                      name="cc"
                      id="tags-outlined"
                      autoComplete
                      getOptionLabel={(user) => user.email + " | " + user.name}
                      filterSelectedOptions
                      options={Array.isArray(users) && Array.isArray(useriSetup) ? users.filter(
                        (option) =>
                          !useriSetup.some(
                            (userSetup) => userSetup.id === option.id
                          )
                      ) : []}
                      value={selectedUsersToAdd}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Alege utilizatorul"
                          placeholder="Alege utilizatorul"
                          required
                        />
                      )}
                      onChange={handleSelectUsersToAdd}
                    />
                  </div>
                  <div className="tcfcc-cell">
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={insertUserToSetup}
                    >
                      Adaugă utilizator
                    </Button>
                  </div>
                </div>
              </div>

              <div className="tableCrudForContainerControl">
                <div className="tcfcc-header">
                  <div className="tcfcc-cell">Echipamente</div>
                  <div className="tcfcc-cell">Editează</div>
                </div>
                <div className="tcfcc-body">
                  {echipamenteSetup.map((echipament) => {
                    return (
                      <div className="tcfcc-body-row" key={echipament.id}>
                        <div className="tcfcc-cell">
                          {getEchipamentDisplay(echipament)}
                        </div>
                        <div className="tcfcc-cell">
                          <IconButton
                            onClick={() => setSelectedEchipamentToEdit(echipament)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                  <div className="tcfcc-body-row">
                    <div className="tcfcc-cell">
                      <Autocomplete
                        size="small"
                        name="cc"
                        id="tags-outlined"
                        autoComplete
                        getOptionLabel={(echipament) => getEchipamentDisplay(echipament)}
                        filterSelectedOptions
                        options={Array.isArray(echipamente) && Array.isArray(echipamenteSetup) ? echipamente.filter(
                          (option) =>
                            !echipamenteSetup.some(
                              (echipamentSetup) => echipamentSetup.id === option.id
                            )
                        ) : []}
                        value={selectedEchipamentToAdd}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Alege echipamentul"
                            placeholder="Alege echipamentul"
                            required
                          />
                        )}
                        onChange={handleSelectEchipamentToAdd}
                      />
                    </div>
                    <div className="tcfcc-cell">
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={insertEchipamentToSetup}
                      >
                        Adaugă echipament
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        className="ticket-dialog"
        open={selectedUserToEdit !== null}
        onClose={() => setSelectedUserToEdit(null)}
      >
        {selectedUserToEdit && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere utilizator</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi utilizatorul: {selectedUserToEdit.email}</p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteUserFromSetup(selectedUserToEdit.id)}
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setSelectedUserToEdit(null)}
              >
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={selectedEchipamentToEdit !== null}
        onClose={() => setSelectedEchipamentToEdit(null)}
      >
        {selectedEchipamentToEdit && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere echipament</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi echipamentul: {getEchipamentType(selectedEchipamentToEdit.type)}: {selectedEchipamentToEdit.marca} | {selectedEchipamentToEdit.model} | {selectedEchipamentToEdit.serie}</p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteEchipamentFromSetup(selectedEchipamentToEdit.id)}
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setSelectedEchipamentToEdit(null)}
              >
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      <div className="headerForControl">
        Adauga Setup
      </div>
      <div className="bodyForControl bodyForControlEchipamente">
        <TextField
          size="small"
          name="setup"
          label="Setup"
          placeholder="Setup"
          value={formDataSetup.setup ?? ""}
          onChange={handleInputChangeSetup}
          required
        />
        <TextField
          size="small"
          name="data_primire"
          label="Data Primire"
          type="datetime-local"
          value={formDataSetup.data_primire ?? ""}
          onChange={handleInputChangeSetup}
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          type="submit"
          onClick={adaugaSetup}
        >
          Adauga Setup
        </Button>
      </div>
      <div className="headerForControl">
        Adauga Echipament
      </div>
      <div className="bodyForControl bodyForControlAdaugaEchipament">
        <TextField className="TextField"
          size="small"
          name="marca"
          label="Marca"
          placeholder="Marca"
          value={formDataEchipament.marca}
          onChange={handleInputChangeEchipament}
          required
        />
        <TextField className="TextField"
          size="small"
          name="model"
          label="Model"
          placeholder="Model"
          value={formDataEchipament.model}
          onChange={handleInputChangeEchipament}
          required
        />
        <TextField className="TextField"
          size="small"
          name="serie"
          label="Serie"
          placeholder="Serie"
          value={formDataEchipament.serie}
          onChange={handleInputChangeEchipament}
          required
        />
        <TextField className="TextField"
          size="small"
          name="data_achizitie"
          label="Data Achizitie"
          type="datetime-local"
          value={formDataEchipament.data_achizitie}
          onChange={handleInputChangeEchipament}
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField className="TextField"
          size="small"
          name="pret_achizitie_net"
          label="Pret Achizitie Net"
          type="number"
          placeholder="Pret Achizitie Net"
          value={formDataEchipament.pret_achizitie_net}
          onChange={handleInputChangeEchipament}
          required
        />
        <TextField className="TextField"
          size="small"
          name="furnizor"
          label="Furnizor"
          placeholder="Furnizor"
          value={formDataEchipament.furnizor}
          onChange={handleInputChangeEchipament}
          required
        />
        <TextField className="TextField"
          size="small"
          name="extra_details"
          label="Detalii Extra"
          placeholder="Detalii Extra"
          value={formDataEchipament.extra_details}
          onChange={handleInputChangeEchipament}
          required
        />
        <Autocomplete
          size="small"
          options={echipamentTypes}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tip"
              placeholder="Tip"
              required
            />
          )}
          value={echipamentTypes.find(type => type.value === formDataEchipament.type) || null}
          onChange={handleSelectEchipamentType}
        />
        <Button
          variant="contained"
          type="submit"
          onClick={adaugaEchipament}
        >
          Adauga Echipament
        </Button>
      </div>
    </div>
  );
}