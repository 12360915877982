import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import SnackbarComponent from "../../Components/Alerts/SnackbarComponent";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  roRO,
} from "@mui/x-data-grid";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React, { useState } from "react";
import DeleteDialog from "../../Components/Dialogs/DeleteDialog";
import axiosAuth from "../../Helpers/axiosAuth";

function randomInteger() {
  var retval = Math.floor(Math.random() * 100000);
  return retval;
}
function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      className="custom-box"
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

function EditToolbar({
  setRows,
  setRowModesModel,
  insertDataAxios,
  shouldRefresh,
  dataToInsert,
}) {
  console.log("datatoinsert", dataToInsert);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleClick = () => {
    const key = randomInteger();
    setRows((prevRows) => [{ id: key, isNew: true }, ...prevRows]);

    const postData = {
      email: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
      id: key,
      ...dataToInsert,
    };

    axiosAuth
      .post(insertDataAxios, postData)
      .then(() => {
        setSnackbarSeverity("success");
        setSnackbarMessage("Elementul a fost adăugat cu succes!");
        setSnackbarOpen(true);
        shouldRefresh();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // Handle error response
        } else {
          console.error(error);
        }
      });

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [key]: { mode: "edit", fieldToFocus: "name" },
    }));
  };

  return (
    <>
      <div>
        {" "}
        <Button
          color="primary"
          className="flex"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Insereaza
        </Button>
        <GridToolbar></GridToolbar>
      </div>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
export function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}
export default function DataGridReusableCrud({
  initialRows,
  parentColumns,
  updateDataCallBack,
  deleteDataCallBack,
  insertDataAxios,
  shouldRefresh,
  dataToInsert,
}) {
  const [rows, setRows] = useState(initialRows);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  React.useEffect(() => {
    setRows(initialRows);
  }, [initialRows]);
  const deleteData = (id) => {
    deleteDataCallBack(id);
  };

  const [rowModesModel, setRowModesModel] = useState({});

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "edit" } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "view" } });
  };

  const handleDeleteClick = (id) => () => {
    setDeleteItemId(id);
    setDeleteDialogOpen(true);
  };
  const handleDeleteConfirm = () => {
    if (deleteItemId) {
      deleteData(deleteItemId);
      setRows((prevRows) => prevRows.filter((row) => row.id !== deleteItemId));
    }
    setDeleteItemId(null);
    setDeleteDialogOpen(false);
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "view", ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      deleteData(id);
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const updateData = (updatedRow) => {
    const postData = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
    };

    const requestData = {
      ...postData,
      ...updatedRow,
    };

    updateDataCallBack(requestData);
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log("fufufufu", updatedRow);
    setSnackbarSeverity("success");
    setSnackbarMessage("success");
    updateData(updatedRow);
    return updatedRow;
  };

  const actionsColumn = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === "edit";

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const columns = [...parentColumns, ...actionsColumn];

  return (
    <Box
      sx={{
        height: "full",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        onRowEditStart={(params) => {}}
        onRowEditStop={(params) => {}}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {
          console.error("Error occurred while updating a row:", error);
        }}
        localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: {
            setRows,
            setRowModesModel,
            insertDataAxios,
            shouldRefresh,
            dataToInsert,
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          ...rows.initialState,
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        pageSizeOptions={[5, 10, 25, 100]}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteDialogOpen(false)}
      />
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </Box>
  );
}
