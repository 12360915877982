import React from 'react';
import Session from "./../../Components/Session.js";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './Salarizare.css';
import Praguri from './Praguri.jsx';
import ImportGetex from './ImportGetex.jsx';
import Salarii from './SalariiAgenti.jsx';
import Tesa from './Tesa.jsx';
import Centralizare from './Centralizare.jsx';


const Salarizare = () => {
    var auth = false;
    if (Session([999, 500, 509])) {
        auth = true;
    }

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function checkRoles(array) {
        let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
        for (let i = 0; i < array.length; i++) {
            if (activeRoles.includes(array[i])) {
                return true;
            }
        }
        return false;
    }

    return (
        <>
            {auth ?
                <div className="box">
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Tab salarizare">
                                {checkRoles([999, 509]) ? <Tab label="Centralizare" value="1" /> : null}
                                {checkRoles([999, 509]) ? <Tab label="Tesa" value="2" /> : null}
                                <Tab label="Salarii Agenti" value="3" />
                                <Tab label="Praguri" value="4" />
                                {checkRoles([999, 509, 505]) ? <Tab label="Import getex" value="5" /> : null}
                            </TabList>
                        </Box>

                        {checkRoles([999, 509]) ?
                            <TabPanel value="1">
                                <Centralizare />
                            </TabPanel>
                            : null}
                        {checkRoles([999, 509]) ?
                            <TabPanel value="2">
                                <Tesa />
                            </TabPanel>
                            : null}
                        <TabPanel value="3">
                            <Salarii />
                        </TabPanel>
                        <TabPanel value="4">
                            <Praguri />
                        </TabPanel>
                        {checkRoles([999, 509, 505]) ?
                            <TabPanel value="5">
                                <ImportGetex />
                            </TabPanel>
                            : null}
                    </TabContext>
                </div>
                :
                <></>
            }
        </>
    )
}

export default Salarizare;
