import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import axiosAuth from "../../../Helpers/axiosAuth";

function SurveyModal({
  isOpen,
  onClose,
  surveyData,
  isSurveyUpdated,
  surveyNumber,
}) {
  const [userResponses, setUserResponses] = useState({});
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [justifications, setJustifications] = useState({});
  const [validateError, setvalidateError] = useState(false);
  const [confirmAddResponse, setConfirmAddResponse] = useState(null);

  useEffect(() => {
    if (isOpen) {
      const newResponses = {};
      const newJustifications = {};
      surveyData.forEach((survey) => {
        survey.questions.forEach((question) => {
          if (question.user_response) {
            newResponses[question.question_id] = question.chosenResponse;
            newJustifications[question.question_id] =
              question.chosenJustification || "";
          }
        });
      });
      setUserResponses(newResponses);
      setJustifications(newJustifications);

      const areAllQuestionsAnswered = surveyData.every((survey) => {
        return survey.questions.every(
          (question) => question.user_response === true
        );
      });

      setAllQuestionsAnswered(areAllQuestionsAnswered);
    }
  }, [isOpen, surveyData]);

  const handleResponseChange = (questionId, choiceId, isSingleChoice) => {
    setUserResponses((prevResponses) => {
      const existingResponses = prevResponses[questionId] || [];
      let updatedResponses;

      if (isSingleChoice) {
        updatedResponses = [choiceId];
      } else {
        if (existingResponses.includes(choiceId)) {
          updatedResponses = existingResponses.filter((id) => id !== choiceId);
        } else {
          updatedResponses = [...existingResponses, choiceId];
        }
      }

      return {
        ...prevResponses,
        [questionId]: updatedResponses,
      };
    });
  };

  const handleJustificationChange = (event, questionId) => {
    setJustifications((prevJustifications) => {
      const updatedJustifications = {
        ...prevJustifications,
        [questionId]: event.target.value,
      };
      if (validateError) {
        setvalidateError(false);
      }
      return updatedJustifications;
    });
  };

  const handleConfirm = () => {
    setConfirmAddResponse(null);
    const responseArray = [];
    let hasValidationError = false;

    surveyData.forEach((survey) => {
      survey.questions.forEach((question) => {
        if (!question.user_response) {
          const response = {
            question_id: question.question_id,
            choice_id: userResponses[question.question_id] || null,
            open_ended_response: question.open_ended_response,
            justification: justifications[question.question_id] || "",
          };

          if (
            question.justificationRequired &&
            !response.justification.trim()
          ) {
            hasValidationError = true;
          }

          responseArray.push(response);
        }
      });
    });

    if (hasValidationError) {
      setvalidateError(true);
    }

    axiosAuth
      .post("dashboard/surveys/insertResponse", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        responseArray,
      })
      .then((res) => {
        isSurveyUpdated();
      })
      .catch((err) => console.error(err));

    console.log("Response Array:", responseArray);
  };
  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={"md"}>
        {surveyData.map((survey) => (
          <div className="container-modal-surveys" key={survey.survey_id}>
            <div className="header-modal-surveys">
              <h2>{survey.title}</h2>
              <div className="header-title-desc">
                <h4>Expira la: {survey.expiry_date}</h4>

                <h4>Nr. Sondaj: {surveyNumber}</h4>
              </div>
            </div>
            <p>{survey.description}</p>
            <div className="questions-modal-surveys">
              {survey.questions.map((question) => (
                <div key={question.question_id}>
                  <h3>{question.question_text}</h3>
                  {question.choices.map((choice) => (
                    <div
                      className="choice-modal-surveys"
                      key={choice.choice_id}
                    >
                      <label style={{ display: "flex" }}>
                        <input
                          type={
                            question.question_type === "Single choice"
                              ? "radio"
                              : "checkbox"
                          }
                          name={`question_${question.question_id}`}
                          value={choice.choice_id}
                          checked={
                            userResponses[question.question_id] &&
                            userResponses[question.question_id].includes(
                              choice.choice_id
                            )
                          }
                          onChange={() =>
                            handleResponseChange(
                              question.question_id,
                              choice.choice_id,
                              question.question_type === "Single choice"
                            )
                          }
                          disabled={question.user_response}
                        />
                        <p>{choice.choice_text}</p>
                      </label>
                    </div>
                  ))}
                  <div className="justification-modal-surveys">
                    <TextField
                      error={validateError}
                      type="text"
                      value={justifications[question.question_id] || ""}
                      placeholder={`Motiv${
                        question.justificationRequired ? " * obligatoriu" : ""
                      }`}
                      onChange={(event) =>
                        handleJustificationChange(event, question.question_id)
                      }
                      disabled={question.user_response}
                      className={
                        question.user_response ? "disabled-justification" : ""
                      }
                      required
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="buttons-modal-surveys">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={setConfirmAddResponse}
            disabled={allQuestionsAnswered}
            className={allQuestionsAnswered ? "disabled-button" : ""}
          >
            Confirm
          </Button>
        </div>
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={confirmAddResponse !== null}
        onClose={() => setConfirmAddResponse(null)}
      >
        {confirmAddResponse && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Trimitere raspuns</p>
            </div>
            <div className="ticket-details">
              <p>Urmeaza sa trimiti raspunsul chestionarului</p>
              <p>Atenție: Această acțiune nu mai poate fi repetată!</p>
            </div>
            <div className="ticket-dialog-control">
              <Button variant="contained" onClick={handleConfirm}>
                TRIMITE
              </Button>
              <Button
                variant="outlined"
                onClick={() => setConfirmAddResponse(null)}
              >
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
}

export default SurveyModal;
