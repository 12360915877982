// reducers/index.js

import { combineReducers } from "redux";
import commentReducer from "./Ticketing/commentReducer";
import teamReducer from "./Users/Teams/teamReducer";
import usersReducer from "./Users/usersReducer";
import newsletterReducer from "./Newsletters/NewslettersReducer";
import tesaReducer from "./Tesa/TesaReducer";
import tesaNewReducer from "./TesaNew/TesaNewReducer";

const rootReducer = combineReducers({
  users: usersReducer,
  comment: commentReducer,
  teamDetails: teamReducer,
  newsletter: newsletterReducer,
  tesa: tesaReducer,
  tesaNew: tesaNewReducer,
});

export default rootReducer;
